import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box
} from "@material-ui/core";
import classnames from "classnames";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slider from '@material-ui/core/Slider';

// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

//
// function SimpleQuestion(props) {
//   var classes = useStyles();
//   const { children, value, index, ...other } = props;
//
//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       className={classes.tab}
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }
//
// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const PrettoSlider = withStyles({
  root: {
    color: 'primary',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  mark: {
  backgroundColor: 'primary',
  height: 8,
  width: 3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

class SimpleQuestion extends React.Component {
  constructor(props) {
    super(props);
    var marks = [];
    if (props.minLabel) {
      marks.push({
        value: props.options ? parseFloat(props.options[0]) : null,
        label: props.minLabel
      })
    }
    if (props.maxLabel) {
      marks.push({
        value: props.options ? parseFloat(props.options[props.options.length-1]) : null,
        label: props.maxLabel
      })
    }
    this.state = {
      questions: [],
      title: props.title,
      size: props.options!=null ? props.options.length : 5,
      min: props.options!=null ? parseFloat(props.options[0]) : 1,
      max: props.options!=null ? parseFloat(props.options[props.options.length - 1]) : 5,
      minLabel: props.minLabel ? props.minLabel : '',
      maxLabel: props.maxLabel ? props.maxLabel : '',
      marks: marks,
      step: props.step ? props.step : 1,
    };
  }
  //
  // var classes = useStyles();
  //
  // const [title, setTitle] = useState(0);
  // const [options, setOptions] = useState(null);
  //
  //
  // const handleChange = (event, newValue) => {
  //   // setValue(newValue);
  // };

  updateAnswer(value) {
    if (!this.props.answer) {
      return
    }
    const {title, size, min, max, minLabel, maxLabel} = this.state;
    this.props.onDataChange({
      value: [min, value, max]
    })
  }

  updateQuestionData() {
    const {title, size, min, max, minLabel, maxLabel} = this.state;
    let step = (size!=1) ? (max-min)/(size-1) : 1;
    let options = [];
    for (var v = min; v <= max; v+=step) {
        options.push(v);
    }

    this.props.onDataChange({
      'title': title,
      'size': size,
      'min': min,
      'max': max,
      'options': options,
      'min_label': minLabel,
      'max_label': maxLabel
    })
  }

  changeTitle(val) {
    this.setState({title: val}, () => {
      this.updateQuestionData()
    })
  }

  changeScale() {
    const min = this.state.min;
    const max = this.state.max;
    const size = this.state.size;
    if (max > min && size > 1) {
      let step = (max-min)/(size-1);
      this.setState({step: step}, () => {
        this.updateQuestionData()
      })
    }
  }

  changeStep(value) {
    if(isNumeric(value)) {
      let val = parseFloat(value)
      this.setState({size: val}, () => {
        this.changeScale();
      })
    }
  }

  changeMin(value) {
    if(isNumeric(value)) {
      let val = parseFloat(value)
      if (val < this.state.max) {
        this.setState({min: val}, () => {
          this.changeScale();
        })
      }

      let m = [
        {
          value: val,
          label: this.state.minLabel
        },
        {
          value: this.state.max,
          label: this.state.maxLabel
        }
      ]
      this.setState({marks: m})
    }
  }

  changeMax(value) {
    if(isNumeric(value)) {
      let val = parseFloat(value)
      if (val > this.state.min) {
        this.setState({max: val}, () => {
          this.changeScale();
        })
      }

      let m = [
        {
          value: this.state.min,
          label: this.state.minLabel
        },
        {
          value: val,
          label: this.state.maxLabel
        }
      ]
      this.setState({marks: m})
    }
  }

  changeMinLabel(val) {
    this.setState({minLabel: val}, () => {
      this.updateQuestionData()
    })
  }

  changeMaxLabel(val) {
    this.setState({maxLabel: val}, () => {
      this.updateQuestionData()
    })
  }


  render () {
    const { classes, answer } = this.props;
    const {min, max, step, size, title, minLabel, maxLabel, marks} = this.state;
    return (
      <div className={classes.questionModuleContainer}>
       <fieldset disabled={this.props.disabled} style={{border: 0}}>
        <TextField
          required={!answer}
          disabled={answer}
          id="name"
          name="name"
          value={title}
          label="title"
          margin="dense"
          fullWidth
          multiline
          variant="outlined"
          maxrows={4}
          onChange={e => this.changeTitle(e.target.value)}
        />

        <div className={classes.formControl}>
          {!answer &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <TextField
                required
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={size}
                label="steps"
                margin="dense"
                variant="outlined"
                onChange={e => this.changeStep(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2} >
                <TextField
                required
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={min}
                label="min value"
                margin="dense"
                variant="outlined"
                onChange={e => this.changeMin(e.target.value)}
              />
                <TextField
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={minLabel}
                  label="min label"
                  margin="dense"
                  variant="outlined"
                  onChange={e => this.changeMinLabel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2} >
                <TextField
                required
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={max}
                label="max value"
                margin="dense"
                variant="outlined"
                onChange={e => this.changeMax(e.target.value)}
              />
                <TextField
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={maxLabel}
                  label="max label"
                  margin="dense"
                  variant="outlined"
                  onChange={e => this.changeMaxLabel(e.target.value)}
                />
              </Grid>
            </Grid>
          }
          <div className={classes.sliderDiv}>
            <PrettoSlider
              required={answer}
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              min={min}
              max={max}
              step={step}
              marks={marks}
              onChange={(e, val) => this.updateAnswer(val)}
              defaultValue={min} />
          </div>
        </div>
        </fieldset>
      </div>
    );
  }


}

export default withStyles(useStyles)(SimpleQuestion);
