import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box
} from "@material-ui/core";
import classnames from "classnames";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

// styles
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";

// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

const support_options = [
  {name: 'treatment'},
  {name: 'reports'},
  {name: 'billing and payment'},
  {name: 'account'},
  {name: 'other'}
]

const styles = theme => ({
  input: {
    height: 40
  },
  button: {
    height: 40
  },
  selectRoot: {
    height: 40,
    display: "table"
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    display: "table-cell",
    verticalAlign: "middle"
  }
});

export default function Support(props) {
  var classes = useStyles();

  const [title, setTitle] = useState("");
  const [orgInfo, setOrgInfo] = useState(null);
  const [body, setBody] = useState('');
  const [cat, setCat] = useState('');

  var {state, dispatch} = useUserState();

  const handleChange = (event, newValue) => {
  };

  async function submit() {
    window.location = `mailto:support@edenos.com?subject=${title}&body=${body}`;
  }

  function onChangeType() {

  }

  const disabled = !body || !cat;
  return (
      <div className={classes.root}>
        Click <a href="mailto:support@edenos.com">here</a> to send us an email. We will contact you as soon as possible.
        {/*
        <h3>Submit a request</h3>
        <form className={classes.infoForm}>

          <FormGroup>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="type-box"
                className={classes.shortinput}
                options={support_options}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setCat(newValue.name);
                }}
                renderInput={(params) => <TextField {...params} label="category" variant="outlined"/>}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.shortinput}
                id="subject-box"
                label="title"
                variant="outlined"
                value={title}
                onChange={(event, newValue) => {
                  setTitle(newValue);
                }}/>
            </FormControl>
            <div className="input-group-prepend">
  <span className="input-group-text" id="basic-addon">
  <i className="fas fa-pencil-alt prefix"></i>
  </span>
</div>
            <FormControl className={classes.formControl}>

                <textarea
                  className={classes.description}
                  label="description"
                  value={body}
                  placeholder="tell us more ..."
                  onChange={(event) => {
                    setBody(event.target.value);
                  }}>
                </textarea>
            </FormControl>
          </FormGroup>

          <div className={classes.saveBtn} >
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={() => submit()}>
              Submit
            </Button>
          </div>
        </form>*/}
      </div>
  );

}
