import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  HelpOutline as FAQIcon,
} from "@material-ui/icons";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const useStyles = makeStyles(theme => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
  },
  block: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    top: '0px',
    boxShadow: "0px 0px 8px 8px white inset"
  }
}))

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MoreInfo(props) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FAQIcon style={{fontSize: 14, color: "grey"}} onClick={handleClickOpen}/>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {(props.title) && <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title}
        </DialogTitle>
        }
        <DialogContent dividers>
          <Typography gutterBottom>
            {props.body}
          </Typography>
          {props.image &&
            <div className={classes.imageContainer}>
              <img
                src={props.image}
                width="100%"
              />
              <div className={classes.block}></div>
            </div>
          }
        </DialogContent>
        {/*<DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>*/}
      </Dialog>
    </div>
  );
}
