import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import useStyles from "./styles";
// import { Button } from "@material-ui/core";
import { Button } from "../../components/Wrappers";

// class CardSection extends React.Component {
//   render() {
//     return (
//       <label>
//         Card details
//         <CardElement style={{base: {fontSize: '18px'}}} />
//       </label>
//     );
//   }
// }

const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export default function CardSection(props) {
  // handleSubmit = (ev) => {
  //   ev.preventDefault();
  //   if (this.props.stripe) {
  //     this.props.stripe
  //       .createToken()
  //       .then((payload) => console.log('[token]', payload));
  //   } else {
  //     console.log("Stripe.js hasn't loaded yet.");
  //   }
  // };
  var classes = useStyles();

  return (
    <form onSubmit={props.onSubmit} className={classes.checkout}>
      <label className={classes.label}>
        Card details
        <CardElement
          className={classes.cardInput}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(props.fontSize)}
        />
      </label>
      {/*<Button
        className={classes.paymentButton}
        variant="contained"
        size="small"
        color="primary"
      >
        pay
      </Button> */}

    </form>
  );
}
