import { makeStyles } from "@material-ui/styles";
import bkgnd from "../../images/bkgnd.png";

const MAX_WIDTH = 1200;

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.customShadows.widget,
    padding: theme.spacing(5),
  },
  infoForm: {

  },
  formControl: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  shortinput: {
    width: '45ch',
    backgroundColor: 'white'
  },
  description: {
    height: 500,
    width: '100%',
    resize: 'vertical',
    padding: theme.spacing(2),
    fontSize: 16,
    borderColor: 'lightgrey',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    borderRadius: 5
  },
  saveBtn: {
    display: "flex",
    width: '100%',
    justifyContent: 'flex-end',
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  cardRoot: {
    boxShadow: theme.customShadows.widget,
  },
  cardContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  productGrid: {
    alignItems: 'stretch'
  },
  cardBody: {
    padding: theme.spacing(2)
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '5px 10px 5px 10px',
    paddingHorizontal: '10px'
  },
  premiumText: {
    marginLeft: theme.spacing(1),
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 7px 1px 7px',
    backgroundColor: "#deffe7",
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  pendingText: {
    marginLeft: theme.spacing(1),
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 7px 3px 7px',
    backgroundColor: "#fffaed",
    fontSize: 10,
    color: theme.palette.warning.main,
    border: '1px solid'
  },
  videoContainer: {
    height: 100,
    width: '100%',
    marginBottom: 5,
    position: 'relative',
  },
  videoDesc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  videoDuration: {
    marginTop: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: '1px 7px 3px 7px',
    borderRadius: 5,
    fontSize: 10,
    color: 'white'
  },
  dateContent: {
    color: 'grey',
    flexDirection: 'row',
    fontSize: 12,
  },
  videoTitle: {
    fontSize: 18,
    fontWeight: '600'
  },
  viewsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'grey',
  },
  viewCount: {
    margin: '0px 5px 0px 5px',
  },
  dropBox: {
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    fontSize: 10,
    textAlign: 'center',
    transition: 'border .24s ease-in-out'
  },
  dropBoxInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
  },
  imageCloseIcon: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'grey',
    top: 0,
    right: 0,
    zIndex: 10
  },
  youTubeContainer: {
    borderRadius: '3px',
    overflow: 'hidden'
  },
  productImage: {
    maxHeight: 120,
    maxWidth: "100%"
  },
  categories: {
    height: 40,
    padding: theme.spacing(1),
    flexDirection: 'row',
    display: 'flex',
  },
  cardFooter: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  cardDescription: {
    maxHeight: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));
