import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  TextField,
  CircularProgress,
  Paper,
  Link,
  Button,
  Tabs,
  Tab,
  Box,
  Fab
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

import classnames from "classnames";
// styles
import useStyles from "./styles";

// components
import Autocomplete from '@material-ui/lab/Autocomplete';
import mock from "../dashboard/mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import { renderActiveShape } from '../charts/Charts';
import { get_communities, get_treatments, get_treatment, get_patients, invite_patient } from '../../api/doctors';
import { handleNotification } from '../notifications/NotificationsClient';
import SendIcon from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinkIcon from '@material-ui/icons/Link';
import Info from '@material-ui/icons/Info';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function TreatmentPage(props) {
  var classes = useStyles();
  var theme = useTheme();

  var [isLoading, setIsLoading] = useState(true);
  var [treatment, setTreatment] = useState(null);
  var [activeIndex, setActiveIndexId] = useState(0);
  var [shareLink, setShareLink] = useState("");
  var [value, setValue] = useState(0);
  var [open, setOpen] = useState(false);
  var [privated, setPrivate] = useState(false);
  var [copySuccess, setCopySuccess] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const treatment_id = props.match.params.treatment_id;

  async function getTreatment(){
    setIsLoading(true)
    var trtmnt = await get_treatment(treatment_id);
    setTreatment(trtmnt)
    setIsLoading(false)
  }


  const handleAdd = async () => {
    setOpen(true);
  };

  const handleGenerate = async () => {
    const treatment_id = treatment ? treatment.id : null;
    var resp = await invite_patient(treatment_id);
    if(resp.data) {
      setShareLink(resp.data.invitation_url);
      handleNotification("patient invite success");
    } else {
      handleNotification("patient invite error");
    }
  }

  function copyClipboard() {
    if (shareLink=="") {
      return
    }
    navigator.clipboard.writeText(shareLink);
    setCopySuccess('Copied!');
  }

  const handleClose = () => {
    setShareLink("");
    setCopySuccess('');
    setOpen(false);
  };

  const handleClickOpen = () => {
    // const qrCodeCanvas = document.querySelector('.TreatmentQrcode > canvas');
    // const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
    setOpen(true);
  };

  const handleChangePrivate = (event) => {
    setPrivate(event.target.checked)
  };

  useEffect(() => {
      getTreatment()
  }, [])

  return (
    <div className={classes.productContainer}>
      { isLoading ?
        <div className={classes.center}>
          <CircularProgress color="primary" />
        </div>
        :
        treatment ?
        <div>
          <div className={classes.treatmentHeader}>
            <PageTitle title={treatment.name}  />
            <div>
              <img src={(treatment && treatment.images && treatment.images.length > 0) ? treatment.images[0] : ''} className={classes.treatmentImage}/>
              <div className={classes.addBtn}>
              </div>
            </div>
          </div>
          <Paper className={classes.tabsRoot}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Description" />
              <Tab label="How It Works" />
              <Tab label="Charts" />
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <Typography variant="body1" color="default" className={classes.textRow}>
              <div dangerouslySetInnerHTML={{__html: treatment.description}} />
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="body1" color="default" className={classes.textRow}>
              <div dangerouslySetInnerHTML={{__html: treatment.how_it_works_description}} />
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={2}>
            To be added ...
          </TabPanel>
          <Fab variant="extended" color="primary" onClick={handleAdd} className={classes.stickyButton}>
            Invite Patient
          </Fab>
        </div>
        :
        <Typography variant="h5" color="default" className={classes.textRow}>
          Oops. Looks like this treatment doesn't exist!
        </Typography>
      }

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            Get a one-time redeemable journey link and send it to your patient.
          </DialogContentText>

          <div className={classes.sharelinkContainer}>
            <TextField
              autoFocus
              margin="dense"
              id="link"
              label="Journey Link"
              type="name"
              disabled
              fullWidth
              size="small"
              value={shareLink}
            />
              <div className={classes.clipboardContainer}>
                <Button onClick={copyClipboard} disabled={!shareLink}>
                  <Tooltip title="Copy Link"><LinkIcon fontSize="small"/></Tooltip>
                </Button>
                {copySuccess}
              </div>
              {/*<div className={classes.clipboardContainer}>
                <Button onClick={handleOpenQr} disabled={!shareLink}>
                  <Tooltip title="QR Code"><CropFreeIcon fontSize="small"/></Tooltip>
                </Button>
              </div>*/}
          </div>

          <Grid container spacing={10} direction="row" alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={privated}
                    onChange={handleChangePrivate}
                    name="private"
                    color="primary"
                    size="small"
                  />
                }
                label="private journey"
                color="primary"
                size="small"
              />
              <Tooltip title="Private journeys will not be shared with the community publicly" size="small">
                <Info color="disabled" fontSize="small"/>
              </Tooltip>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: 'none'}}
            onClick={handleClose}
            size="small"
            color="primary">
            Cancel
          </Button>
          <Button
            style={{ textTransform: 'none'}}
            onClick={() => handleGenerate()}
            color="primary"
            variant="contained"
            size="small"
            disabled={!treatment}>
            Generate
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );

  // function selectProduct(product){
  //   dispatch({type: "SELECT_PRODUCT", data: product})
  //   let product_url = product ? "/dashboard/" + product.id : "/dashboard"
  //   props.history.push(product_url);
  // }
}
