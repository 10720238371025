import axios from "axios";
import * as urls from './urls';
import React from "react";
import { handleNotification } from '../pages/notifications/NotificationsClient';

// axios default configs
axios.defaults.baseURL = urls.BASE_URL || "";
axios.defaults.headers.common["Content-Type"]="application/json";
// axios.defaults.headers.common["Authorization"] = `JWT${token}`;

export function apiClient(
  url = "",
  method = "GET",
  data = null,
  headers = null,
  dispatch = null,
  onSuccess = () => {},
  onFailure = () => {}
) {

  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  const token = localStorage.getItem('dash_token');

  let full_header = headers;

  if (headers) {
    full_header['Authorization'] = full_header['Authorization'] ? 'JWT ' + full_header['Authorization'] : 'JWT ' + String(token);
  } else {
    full_header = { Authorization: 'JWT ' + String(token) };
  }
  console.warn(url, "full_header",full_header)
  return axios
    .request({
      url,
      method,
      headers: full_header,
      [dataOrParams]: data
    })
    .then((resp) => {
      return {
        data: resp.data,
        headers: resp.headers,
        error: null,
        msg_detail: resp.msg_detail || resp.message,
        status: resp.status
      }
    })
    .catch(error => {
        if (error.response && error.response.status == 403) {
          // ***** FOR HIPPA REASONS:
          if(dispatch) {
            dispatch({type: "LOGIN_FAILURE"})
          }
          return {
            data: null,
            error: 'unauthroized'
          }
          // return refresh().then((new_token) => {
          //   console.warn("got new token: ", new_token)
          //   if(new_token) {
          //     let new_header = headers
          //     if (headers) {
          //       new_header['Authorization'] = 'JWT ' + String(new_token);
          //     } else {
          //       new_header = { Authorization: 'JWT ' + String(new_token) };
          //     }
          //     new_header['Authorization'] = new_token;
          //     return apiClient(url, method, data, new_header)
          //   } else {
          //     // couldn't get new token so should log out
          //     return {
          //       data: null,
          //       error: 'unauthroized'
          //     }
          //   }
          //
          // })
        }
        if (error.response && error.response.status == 409) {
          handleNotification("subscription error");
        }
        return {
          data: null,
          error: (error.response) ? error.response.status : 400,
          msg_detail: error.response && error.response.data && (error.response.data.msg_detail || error.response.data.message)
        }
    })
}

function refresh() {
  const ref_token = localStorage.getItem('dash_refresh_token');
  return axios({
    method: 'post',
    url: urls.REFRESH(),
    headers: {'Refresh-Token': ref_token}
  }).then((resp) => {
    if (resp.headers.authorization) {
      localStorage.setItem('dash_token', resp.headers.authorization)
      return resp.headers.authorization
    } else {
      return null
    }
  })
  .catch(error => {
    return null
  })
}
