import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  selectBtn: {
    margin: theme.spacing(1),
    maxWidth: 170
  },
  productImage: {
    height: 225,
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 400,
    overflow: "hidden",
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.customShadows.widget,
  },
}));
