import React, {useState, useEffect, useCallback} from 'react';
import { Button, Switch, Divider, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from "@material-ui/icons/Close";
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircle';
import useStyles from "./styles";
import { withStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as UpChunk from '@mux/upchunk';
import MoreInfo from '../../components/Dialog';
import { get_upload_url, upload_video, get_video } from '../../api/video';
import { get_communities, get_community_categories } from '../../api/doctors';
import { useProductState } from "../../context/ProductContext";
import { DatePicker } from "@material-ui/pickers";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import mock from "./mock";

// const communities = [
//   { title: 'Hyperhidrosis' },
//   { title: 'GWI' },
//   { title: 'Carpal Tunnel'},
//   { title: 'The Dark Knight' }
// ]

const strings = {
  yt: {
    title: 'YouTube Embed URL',
    body: 'If you are uploading a video for this cohort please upload the video on YouTube and use the embed link. You can make your video unlisted on YouTube.'
  },
  type: {
    title: 'Product Type',
    body: 'Free treatment videos are accessible to all users and Premium videos will be unlocked for 30 days once the user subscirbes to the treatment.'
  },
  desc: {
    title: 'Treatment Description',
    body: 'Brief description of this treatment. Whome it is intended for, How effective it can be. This information will show up in treatment "Description" section.'
  },
  hiw: {
    title: 'How it works',
    body: 'Explain how users should approach this treatment, recommended frequency and intensity in applicable. This information will show up in treatment "How it works" section.'
  },
}
const MAX_VIDEO_DURATION_MIN = 1; // video time limitation in minutes

export default function BasicInfo(props) {
  const [prodType, setProdType] = useState(props.type);
  const [community, setCommunity] = useState(props.community);
  const [studyName, setStudyName] = useState(props.studyName);
  const [enabledMetrics, setEnabledMetrics] = useState(false);
  const [name, setName] = useState(props.name);
  const [thumbnailImage, setThumbnailImage] = useState(props.image);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
  const [videoPlaybackId, setVideoPlaybackId] = useState(null);
  const [uploadId, setUploadId] = useState('');
  const [progress, setProgress] = useState(0);
  const [isPreparing, setIsPreparing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [communities, setCommunities] = useState([]);
  const [product, setProduct] = useState(props.treatment);
  const [categories, setCategories] = useState([]);
  const [selCats, setSelCats] = useState(new Set(props.selectedCats));
  const [expected, setExpected] = useState(null);
  const [cohorts, setCohorts] = useState(props.cohorts);
  const [hasVideo, setHasVideo] = useState(false);
  const [size, setSize] = useState(0);
  const [trp, setTrp] = useState(null);

  const selectedCats = new Set(props.selectedCats);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({maxFiles:1, accept: 'image/*'})

  const classes = useStyles();

  var prodState = useProductState().state;

  const handleTherapySelected = (index, value) => {
    // props.onChangeYTURL(url);
    let temp_cohorts = cohorts;
    if (temp_cohorts && (temp_cohorts.length > 0)) {
      if (value) {
        temp_cohorts[index].therapy = value
        temp_cohorts[index].video_embed = ""
      } else {
        delete temp_cohorts[index].therapy
        temp_cohorts[index].video_embed = ""
      }
      setCohorts([...temp_cohorts])
    }
    props.onCohortChange(temp_cohorts)
  }

  const handleYTChanged = (index, url) => {
    // props.onChangeYTURL(url);
    let temp_cohorts = cohorts;
    if (temp_cohorts && (temp_cohorts.length > 0)) {
      if (url) {
        temp_cohorts[index].video_embed = url
      } else {
        delete temp_cohorts[index].video_embed
      }
      setCohorts([...temp_cohorts])
    }
    props.onCohortChange(temp_cohorts)
  }
  const handleImageChanged = (img) => {
    // props.onChangeProdImage(img);
    setThumbnailImage(img);
  }
  const handleCommunityChanged = (comm) => {
    setCategories([]);
    selectedCats.clear();
    setSelCats(selectedCats);
    // props.onChangeSelectedCats(selectedCats);
    //
    // props.onChangeCommunity(comm);
    getCommunityCategories(comm.id)
    setCommunity(comm);
  }

  const handleProductChanged = (prod) => {
    setProduct(prod);
    props.onTreatmentChanged(prod)
  }

  useEffect(() => {
      // getCommunities();
      if (props.studyType == 'RTM') {
        let coh = {
          'name' : 'RTM',
          'description': 'Default cohort for RTM',
          'notes': ' '
        }
        setCohorts([coh])
        props.onCohortChange([coh])
      }
      if (community && community.id) {
        getCommunityCategories(community.id)
      }
  }, [])

  useEffect(() => {
    if (isPreparing) {
      // props.onChangeVideoURL(uploadId)
    }
  }, [isPreparing])

  async function getCommunities(){
    var comms = await get_communities();
    if (comms && comms.length) {
      setCommunities(comms)
    } else {
    }
  }

  async function getCommunityCategories(id){
    var cats = await get_community_categories(id);
    if (cats && cats.length) {
      setCategories(cats)
    } else {
    }
  }

  async function getVideo() {
    const resp = await get_video(uploadId);
    if (resp) {
      setVideoPlaybackId(resp.playback_id);
    }
  }

  const createUpload = async () => {
    try {
      return fetch('http://127.0.0.1:4242/create-upload-url', {
        method: 'POST',
      })
        .then((res) => res.json())
        .then(({ id, url }) => {
          setUploadId(id);
          return url;
        });
    } catch (e) {
      console.error('Error in createUpload', e); // eslint-disable-line no-console
      setErrorMessage('Error creating upload');
      return Promise.reject(e);
    }
  };

  const startFileValidation = (file) => {
    return new Promise((resolve, reject) => {
      // Attempt to load the file as a video element and inspect its duration
      // metadata. This is not an authoritative check of video duration, but
      // rather intended to serve as just a simple and fast sanity check.
      if (!file.type.includes("video")) {
        console.warn(`file type (${file.type}) does not look like video!`);
        resolve();
      }

      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        URL.revokeObjectURL(video.src);
        if (video.duration !== Infinity && video.duration > MAX_VIDEO_DURATION_MIN*60) {
          const dur = Math.round(video.duration * 100) / 100;
          reject(`file duration (${dur.toString()}s) exceeds allowed maximum (${MAX_VIDEO_DURATION_MIN}min)!`);
        }
        resolve();
      };
      video.onerror = function() {
        // The file has a video MIME type, but we were unable to load its
        // metadata for some reason.
        console.warn("failed to load video file metadata for validation!");
        URL.revokeObjectURL(video.src);
        resolve();
      };
      video.src = URL.createObjectURL(file);
    });
  };

  async function startUpload(file) {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    setFile(file);

    try {
      const upChunk = UpChunk.createUpload({
        endpoint: createUpload,
        maxFileSize: 2**21, // 2GB
        chunkSize: 5120, // Uploads the file in ~5mb chunks
        file: file,
      });

      upChunk.on('error', (err) => {
        console.warn(err.detail);
      });

      upChunk.on('progress', (progressEvt) => {
        setProgress(Math.floor(progressEvt.detail));
      });

      upChunk.on('success', async () => {
        setIsPreparing(true);
      });
    } catch (err) {
      console.warn(err.toString());
    }
  };

  async function startUploadThumbnail(file) {
    if (isUploadingThumbnail) {
      return;
    }
    setIsUploadingThumbnail(true);

  };


  function handleUpload(files) {
    const file = files.length ? files[0] : null;
    startFileValidation(file).then(() => {
      startUpload(file);
    }).catch((error => {
      console.warn(error);
    }));
  }

  function handleUploadThumbnail(files) {
    const file = files.length ? files[0] : null;
    setThumbnailImage(file);
    // props.onChangeProdImage(file);  // TODO:
    startUploadThumbnail(file);
  }

  function handleRemoveImage() {
    setThumbnailImage(null);
    // props.onChangeProdImage(null);
  }

  function removeCohort(index) {
    let temp_cohorts = cohorts;
    if (index > -1) {
      temp_cohorts.splice(index, 1);
    }
    setCohorts([...temp_cohorts])
    props.onCohortChange([...temp_cohorts])
  }

  function addCohort() {
    setCohorts(cohorts.concat({
      'name': '',
      "image_url": "https://wwww.edenos.com/img.png"
    }))
    props.onCohortChange(cohorts.concat({'name': '',"image_url": "https://wwww.edenos.com/img.png"}))
  }

  function handleChangeHasVideo(e) {
    setHasVideo(e.target.checked);
  }

  function handleChangeName(name) {
    setStudyName(name)
    props.onStudyNameChange(name)
  }

  function handleCohortChangeName(index, name) {
    let temp_cohorts = cohorts;
    if (temp_cohorts && (temp_cohorts.length > 0)) {
      temp_cohorts[index].name = name
      setCohorts([...temp_cohorts])
    }
    props.onCohortChange(temp_cohorts)
  }

  function handleCohortChangeDesc(index, desc) {
    let temp_cohorts = cohorts;
    if (temp_cohorts && (temp_cohorts.length > 0)) {
      temp_cohorts[index].description = desc
      setCohorts([...temp_cohorts])
    }
    props.onCohortChange(temp_cohorts)
  }

  function handleCohortChangeHIW(index, note) {
    let temp_cohorts = cohorts;
    if (temp_cohorts && (temp_cohorts.length > 0)) {
      temp_cohorts[index].notes = note
      setCohorts([...temp_cohorts])
    }
    props.onCohortChange(temp_cohorts)
  }

  function changeExpected(val) {
    props.onExpectedChange(val)
    setExpected(val)
  }

  function handleMetricChanged(e) {
    e.stopPropagation()
    setEnabledMetrics(e.target.checked)
    props.onMetricChanged(e.target.checked)
  }

  function changeSizeValue(val) {
    setSize(val)
    props.onSizeChanged(val)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <React.Fragment>
      <div className={classes.defineCohort}>
        <div className={classes.studyInfoRow}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                required
                id="name"
                name="name"
                label={(props.studyType == 'RTM') ? "RTM name" : "study name"}
                margin="dense"
                variant="outlined"
                value={studyName}
                onChange={e => handleChangeName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Autocomplete
                id="product-box"
                options={prodState && prodState.products}
                value={product}
                getOptionLabel={option => option && option.name}
                onChange={(event, newValue) => {
                  if(newValue){
                    handleProductChanged(newValue);
                  }
                }}
                renderInput={params => (
                  <TextField {...params} required label="treatment" variant="outlined" margin="dense" />
                )}
              />
            </Grid>
          </Grid>
        </div>

        {props.studyType=='RTM' ?
          <div className={classes.studyBody}>

            {cohorts.map((c, i) => (
                <Grid key={i} item xs={12} lg={12}>
                <div className={classes.questionRow}>
                    <Grid item xs={12} sm={6} style={{marginBottom: 20}}>
                      <div className={classes.row}>
                        <FormLabel>Therapy</FormLabel>
                      </div>
                      <Autocomplete
                        id="therapy"
                        options={props.therapies}
                        value={c.therapy}
                        getOptionLabel={option => option && option.name}
                        onChange={(event, newValue) => {
                          handleTherapySelected(i, newValue);
                        }}
                        renderInput={params => (
                          <TextField {...params} variant="outlined" margin="dense" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <div className={classes.row}>
                        <FormLabel>YouTube embed URL</FormLabel>
                        <MoreInfo title={strings['yt'].title} body={strings['yt'].body} image="/img/INFO_YT.png"/>
                      </div>
                      <Box
                        component={FilledInput}
                        autoComplete="off"
                        type="text"
                        name="name"
                        disabled={c.therapy}
                        value={c && c.video_embed}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        placeholder="e.g. PdCWj6D1_vc"
                        onChange={e => handleYTChanged(i, e.target.value)}
                      />
                      {((c && c.therapy && c.therapy.video_embed) || (c && c.video_embed)) &&
                        <div className={classes.youTubeContainer}>
                          <FormLabel>Video Preview</FormLabel>
                          <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${(c && c.therapy && c.therapy.video_embed) || (c && c.video_embed)}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        </div>
                      }
                    </Grid>
                    {(hasVideo) ?
                      <Grid item xs={12} sm={6}>
                        <Dropzone accept="video/*" onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              {(!file) ?
                                <div {...getRootProps()} className={classes.dropBox}>
                                  <div className={classes.dropBoxInner}>
                                    <input {...getInputProps()} />
                                    <CloudUploadIcon fontSize="large"/>
                                    <p>Drag and drop the video file here, or click to select files</p>
                                  </div>
                                </div>
                                :
                                (!isPreparing) ?
                                <div className={classes.dropBox}>
                                  <p>{progress}/100</p>
                                  <LinearProgress color='primary' variant="determinate" value={progress} />
                                </div>
                                :
                                <video width="100%" controls>
                                    <source src={URL.createObjectURL(file)}/>
                                </video>
                              }
                            </section>
                          )}
                        </Dropzone>
                      </Grid>
                      :
                      null
                    }
                    </div>
                    </Grid>
                  ))}
          </div>
          :
          <div className={classes.studyBody}>

            {/*<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ color: 'white', textTransform: 'none'}}
                onClick={addCohort}
              >
                Add Cohort
              </Button>
            </div>*/}

            <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(e) => handleMetricChanged(e)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox name="Metrics" checked={enabledMetrics}/>}
                label="Enable onboarding metrics"
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <FormGroup>
              <Grid container direction="row" spacing={5}>
                <Grid justify="center" item xs={12} sm={6} md={4}>
                  <FormLabel required={enabledMetrics} disabled={!enabledMetrics}>When do you expect to finish your study onboarding phase?</FormLabel>
                    <DatePicker
                      disabled={!enabledMetrics}
                      autoOk
                      clearable
                      disableToolbar
                      variant="inline"
                      disablePast
                      value={expected}
                      onChange={changeExpected}
                    />
                </Grid>
                <Grid justify="center" item xs={12} sm={6} md={4}>
                  <FormLabel required={enabledMetrics} disabled={!enabledMetrics}>How many patients (all cohorts) do you need for this study?</FormLabel>
                    <TextField
                      value={size}
                      placeholder={'100'}
                      disabled={!enabledMetrics}
                      margin="dense"
                      variant="outlined"
                      onChange={e => changeSizeValue(e.target.value)}
                    />
                </Grid>
              </Grid>
            </FormGroup>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <Grid item md={12} lg={6}>

              {cohorts.map((c, i) => (
                  <Grid key={i} item xs={12} lg={12}>
                  <div className={classes.questionRow}>
                      <div className={classes.questionHeader}>
                          <div className={classes.questionRowItem}><CheckCircleIcon  color="disabled" style={{ fontSize: 16 }}/></div>
                          <div className={classes.questionRowItem}>Cohort {i + 1}</div>
                      </div>
                      <div className={classes.questionDelete} onClick={() => removeCohort(i)}><RemoveCircleOutlineIcon /></div>
                      {/*renderCohort(c)*/}
                      {/*<TextField
                        required
                        name="name"
                        label="cohort name"
                        margin="dense"
                        variant="outlined"
                        value={c.name}
                        onChange={e => handleCohortChangeName(i, e.target.value)}
                      />
                      <TextField
                        required
                        placeholder="Description"
                        name="Description"
                        label="Description"
                        margin="dense"
                        fullWidth
                        multiline
                        variant="outlined"
                        maxrows={8}
                        rows={3}
                        onChange={e => handleCohortChangeDesc(i, e.target.value)}
                      />
                      <TextField
                        required
                        name="How it works"
                        label="How it works"
                        margin="dense"
                        fullWidth
                        multiline
                        variant="outlined"
                        maxrows={8}
                        rows={3}
                        onChange={e => handleCohortChangeHIW(i, e.target.value)}
                      />*/}
                      <FormGroup required={true}>
                          <FormLabel required={true}>Cohort Name</FormLabel>
                        <FormControl
                          required={true}
                          variant="outlined"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            required={true}
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            value={c && c.name}
                            onChange={e => handleCohortChangeName(i, e.target.value)}
                            placeholder="name"
                          />
                        </FormControl>
                        <div className={classes.row}>
                          <FormLabel required={true}>Description</FormLabel>
                          <MoreInfo title={strings['desc'].title} body={strings['desc'].body} image="/img/INFO_DESC.png"/>
                        </div>
                        <FormControl
                          required={true}
                          variant="outlined"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            required={true}
                            paddingLeft="0.75rem!important"
                            paddingRight="0.75rem!important"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            value={c && c.description}
                            onChange={e => handleCohortChangeDesc(i, e.target.value)}
                            placeholder="name"
                            fullWidth
                            multiline
                            variant="outlined"
                            maxrows={8}
                            rows={4}
                          />
                        </FormControl>

                        <div className={classes.row}>
                          <FormLabel required={true}>How it works</FormLabel>
                          <MoreInfo title={strings['hiw'].title} body={strings['hiw'].body} image="/img/INFO_HIW.png"/>
                        </div>
                        <FormControl
                          required={true}
                          variant="outlined"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            required={true}
                            paddingLeft="0.75rem!important"
                            paddingRight="0.75rem!important"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            onChange={e => handleCohortChangeHIW(i, e.target.value)}
                            value={c && c.notes}
                            placeholder="name"
                            fullWidth
                            multiline
                            variant="outlined"
                            maxrows={8}
                            rows={4}
                          />
                        </FormControl>

                      </FormGroup>

                      <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.row}>
                            <FormLabel>Therapy</FormLabel>
                          </div>
                          <Autocomplete
                            id="therapy"
                            options={props.therapies}
                            value={c.therapy}
                            getOptionLabel={option => option && option.name}
                            onChange={(event, newValue) => {
                              handleTherapySelected(i, newValue);
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="outlined" margin="dense" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.row}>
                            <FormLabel>YouTube embed URL</FormLabel>
                            <MoreInfo title={strings['yt'].title} body={strings['yt'].body} image="/img/INFO_YT.png"/>
                          </div>
                          <Box
                            component={FilledInput}
                            autoComplete="off"
                            disabled={c.therapy}
                            type="text"
                            name="name"
                            value={c && c.video_embed}
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            placeholder="e.g. PdCWj6D1_vc"
                            onChange={e => handleYTChanged(i, e.target.value)}
                          />
                          {((c && c.therapy && c.therapy.video_embed)||(c && c.video_embed)) &&
                            <div className={classes.youTubeContainer}>
                              <FormLabel>Video Preview</FormLabel>
                              <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${(c && c.therapy && c.therapy.video_embed) || (c && c.video_embed)}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                              />
                            </div>
                          }
                        </Grid>
                      </Grid>

                      {/*<Grid item xs={6} sm={3}>
                        <Dropzone accept="image/*" onDrop={acceptedFiles => handleUploadThumbnail(acceptedFiles)}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div {...getRootProps()} className={classes.dropBox}>
                                <div className={classes.dropBoxInner}>
                                  <input {...getInputProps()} />
                                  <AddPhotoAlternateIcon size="large"/>
                                  <div>{thumbnailImage ? 'Change photo' : 'Add treatment photo'}</div>
                                </div>
                              </div>

                            </section>
                          )}
                        </Dropzone>

                      </Grid>*/}
                      {/*<Grid item xs={6} sm={3}>
                      {thumbnailImage &&
                        <div className={classes.thumbnailContainer}>
                          <img width="100%" src={URL.createObjectURL(thumbnailImage)}/>
                          <div className={classes.imageCloseIcon} onClick={() => handleRemoveImage()}>
                            <CloseIcon fontSize="small" style={{ borderRadius: 20, backgroundColor: 'rgba(0, 0, 0, 0.2)', margin: 0 }}/>
                          </div>
                        </div>
                      }
                      </Grid>*/}

                      {/*<div className={classes.cohortInfoRow}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={hasVideo}
                            onChange={handleChangeHasVideo}
                            name="video"
                            size="small"
                          />}
                        label="video" />

                      </div>*/}
                      {(hasVideo) ?
                        <Grid item xs={12} sm={6}>
                          <Dropzone accept="video/*" onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                              <section>
                                {(!file) ?
                                  <div {...getRootProps()} className={classes.dropBox}>
                                    <div className={classes.dropBoxInner}>
                                      <input {...getInputProps()} />
                                      <CloudUploadIcon fontSize="large"/>
                                      <p>Drag and drop the video file here, or click to select files</p>
                                    </div>
                                  </div>
                                  :
                                  (!isPreparing) ?
                                  <div className={classes.dropBox}>
                                    <p>{progress}/100</p>
                                    <LinearProgress color='primary' variant="determinate" value={progress} />
                                  </div>
                                  :
                                  <video width="100%" controls>
                                      <source src={URL.createObjectURL(file)}/>
                                  </video>
                                }
                                {/*<video
                                    id="my-player"
                                    class="video-js"
                                    controls
                                    width="100%"
                                    preload="none"
                                    data-setup='{}'>
                                  <source src={videoPreview} type="video/mp4"></source>
                                  <p class="vjs-no-js">
                                    To view this video please enable JavaScript, and consider upgrading to a
                                    web browser that
                                    <a href="https://videojs.com/html5-video-support/" target="_blank">
                                      supports HTML5 video
                                    </a>
                                  </p>
                                </video>*/}
                              </section>
                            )}
                          </Dropzone>
                        </Grid>
                        :
                        null
                      }

                    </div>

                  </Grid>
              ))}
            </Grid>

            <div className={classes.cohortInfoRow}>
              {/*<Button
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: 'none'}}
                onClick={() => props.onClickedAddSurvey()}
                >
                Survey Questions
              </Button>
              <CheckCircleIcon size="small" color="disabled" style={{ fontSize: 16 }}/>*/}
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ color: 'white', textTransform: 'none'}}
                onClick={addCohort}
                startIcon={<GroupAddIcon />}
              >
                Add Cohort
              </Button>
            </div>

          </div>
        }

      </div>
    </React.Fragment>
    </MuiPickersUtilsProvider>
  );
}

// export default withStyles(useStyles)(BasicInfo);
