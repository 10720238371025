import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
 } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Button } from "../../components/Wrappers";
import PromoTable from './PromoTable';
import { Typography } from "../../components/Wrappers";

import { get_products } from '../../api/stats';

import { useStatsState } from "../../context/StatsContext";
import { useProductState, gotoProduct } from "../../context/ProductContext";
import { get_promotions, get_promo_stats } from '../../api/stats';

import useStyles from "./styles";


export default function Tables(props) {

  var classes = useStyles();

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [selectedProd, setSelectedProd] = useState(false);
  var [promos, setPromos] = useState([]);
  var [activePromo, setActivePromo] = useState(null);
  var [promoUserStat, setPromoUserStat] = useState(null);

  var prodState = useProductState().state;
  var prodDispatch = useProductState().dispatch;
  var statState = useStatsState().state;
  var stats = statState.stats;
  var product_stats = stats && stats.product_stats;
  var community_stats = stats && stats.community_stats;


  async function getPromotions(prodID){
    var promos = await get_promotions(prodID);
    if (promos && promos.length) {
      setActivePromo(promos[0].id)
      changePromo(promos[0].id)
    } else {
      setPromoUserStat(null)
    }
    setPromos(promos)

  }

  useEffect(() => {
    if(prodState.selectedProduct && prodState.selectedProduct.id) {
      setSelectedProd(prodState.selectedProduct.id)
      getPromotions(prodState.selectedProduct.id)
    }
  }, [prodState])
  // local


  async function changePromo(id) {
    setActivePromo(id)
    var promoStat = await getPromoStats(id)
    if (promoStat)
      setPromoUserStat(promoStat.user_promos)
  }

  async function changeProd(id) {
    setSelectedProd(id)
    getPromotions(id)
  }

  return (
    <>
      <PageTitle title="Promotions" />

      {(prodState.products && prodState.products.length > 0) ?
        <div className={classes.promotSelectContainer}>
          <InputLabel id="prod">products</InputLabel>
          <Select
            value={selectedProd}
            className={classes.selectBtn}
            onChange={e => changeProd(e.target.value)}
            input={
              <OutlinedInput
                labelWidth={0}
                classes={{
                  notchedOutline: classes.mainChartSelectRoot,
                  input: classes.promoSelect,
                }}
              />
            }
            autoWidth
          >
            {prodState.products.map(prod => (
              <MenuItem key={prod.id} value={prod.id}>{prod.name}</MenuItem>
            ))}
          </Select>
        </div>
        :
        <div className={classes.noPromo}>
          <Typography className={classes.noPromoText}>
            You don't have any products added yet
          </Typography>
        </div>
      }


      <div>
        {(promos && promos.length > 0 && activePromo) ?
          <div className={classes.promotSelectContainer}>
            <InputLabel id="promos">promotions</InputLabel>
            <Select
              value={activePromo}
              className={classes.selectBtn}
              onChange={e => changePromo(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: classes.mainChartSelectRoot,
                    input: classes.promoSelect,
                  }}
                />
              }
              autoWidth
            >
              {promos.map(promo => (
                <MenuItem key={promo.id} value={promo.id}>{promo.name}</MenuItem>
              ))}
            </Select>
          </div>
          :
          <div className={classes.noPromo}>
            <Typography className={classes.noPromoText}>
              You don't have any promotions related to this product
            </Typography>
          </div>
        }
        {promoUserStat && <PromoTable promotions={promoUserStat} />}
      </div>
    </>
  );
}


async function getPromoStats(id) {
  var promoStats = await get_promo_stats(id)
  return promoStats
}
