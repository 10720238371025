import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip,
  FormControl,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleQuestion from './SimpleQuestion';
import CheckBoxQuestion from './CheckBox';
import RadioQuestion from './RadioQuestion';
import ImageQuestion from './ImageQuestion';
import NumberQuestion from './NumberQuestion';
import TextQuestion from './TextQuestion';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
import { create_survey, get_survey, get_surveys } from '../../api/study';
import { useUserState } from "../../context/UserContext";

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

import themeColors from "../../themes/colors.js";

const support_options = [
  {name: 'treatment'},
  {name: 'reports'},
  {name: 'billing and payment'},
  {name: 'account'},
  {name: 'other'}
]

const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const ITEMS = [
    {
        id: "1",
        type: 'scale'
    },
    {
        id: "2",
        type: 'multiselect'
    },
    {
        id: "3",
        type: 'image'
    },
    {
        id: "4",
        type: 'radio'
    },
    {
      id: "5",
      type: 'number'
    },
    {
      id: "6",
      type: 'text'
    }
];



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, id: `questions-${new Date().getTime()}` });
    return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

class SurveyDesigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'ITEMS': ITEMS,
      survey_name: props.surveyName,
      available_surveys: [],
      selectedSurvey: null,
      questions: props.questions
    };
  }

  async componentDidMount() {
    const available_surveys = await get_surveys() || [];
    available_surveys.sort((a, b) => {
        if (a.is_global) {
            return -1;
        } else {
          return 0
        }
    });
    this.setState({available_surveys}, () => {
      if (this.props.surveyID) {
        const found = available_surveys.find(item => {
           return item.id == this.props.surveyID
        })
        if (found) {
          this.handleSurveyPicked(found)
        }
      }
    })

  }

  onChangeType() {

  }

  handleClickAdd() {
  }

  updateQuestionData(index, data, type) {
    let questions = this.state.questions;
    if (questions && (questions.length > 0)) {
      let id = questions[index].id
      let optional = questions[index].optional
      questions[index] = data
      questions[index].id = id
      questions[index].optional = optional
      questions[index].type = type
      this.setState({questions: questions})
    }
  }

  updateQuestionOptional(index, optional) {
    let questions = this.state.questions;
    if (questions && (questions.length > 0)) {
      questions[index].optional = optional
      this.setState({questions: questions})
    }
  }

  renderQuestion(props, index) {
    switch (props.type) {
      case "scale":
        return <SimpleQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          min={props.min}
          max={props.max}
          size={props.size}
          options={props.options}
          minLabel={props.min_label}
          maxLabel={props.max_label}
          onDataChange={(data) => this.updateQuestionData(index, data, "scale")}/>
      case "multiselect":
        return <CheckBoxQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          options={props.options}
          onDataChange={(data) => this.updateQuestionData(index, data, "multiselect")}/>
      case "radio":
        return <RadioQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          options={props.options}
          onDataChange={(data) => this.updateQuestionData(index, data, "radio")}/>
      case "image":
        return <ImageQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          onDataChange={(data) => this.updateQuestionData(index, data, "image")}/>
      case "number":
        return <NumberQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          onDataChange={(data) => this.updateQuestionData(index, data, "number")}/>
      case "text":
        return <TextQuestion
          title={props.title}
          disabled={this.state.selectedSurvey}
          onDataChange={(data) => this.updateQuestionData(index, data, "text")}/>
      default:
        return null;
    }
  }

  onDragEnd = result => {
      const { source, destination } = result;
      // dropped outside the list
      if (!destination) {
          return;
      }

      switch (source.droppableId) {
          case destination.droppableId:
              this.setState({
                  [destination.droppableId]: reorder(
                      this.state[source.droppableId],
                      source.index,
                      destination.index
                  )
              }, () => {
                // this.props.onQuestionUpdate(this.state.questions)
              });
              break;
          case 'ITEMS':
              this.setState({
                  [destination.droppableId]: copy(
                      ITEMS,
                      this.state[destination.droppableId],
                      source,
                      destination
                  )
              }, () => {
                // this.props.onQuestionUpdate(this.state.questions)
              });
              break;
          default:
              this.setState(
                  move(
                      this.state[source.droppableId],
                      this.state[destination.droppableId],
                      source,
                      destination
                  )
              );
              break;
      }
  };

  addList = e => {
    const list_id = `list-${new Date().getTime()}`
    const old_questions = this.state.questions;
    const question = {
      'id': list_id,
      'type': "test"
    }
    this.setState({ questions: old_questions.concat(question) });
  };

  removeQuestion(index) {
    if(this.state.selectedSurvey!=null) {
      return
    }
    let qs = [...this.state.questions];
    if (index > -1) {
      qs.splice(index, 1);
    }
    this.setState({questions: qs})
    // this.props.onQuestionUpdate(questions)
  }

  onChangeSurveyName(name) {
    this.setState({survey_name: name})
    this.props.onSurveyNameUpdate(name)
  }

  handleSurveyPicked(value) {
    this.setState({selectedSurvey: value})
    this.props.onSurveySelected(value);
    if (value.questions) {
      this.setState({questions: value.questions})
      // this.props.onQuestionUpdate(value.questions);
    } else if (value.name == 'create new +') {
      this.setState({questions: []})
    }
  }

  onEdit() {
    this.props.onSurveySelected(null);
    this.setState({selectedSurvey: null})
  }

  submitForm(event) {
    event.preventDefault();
    this.props.onQuestionUpdate(this.state.questions);
    setTimeout(function() {
      this.props.onFinished(true)
    }.bind(this), 1000)
  }

  render(){
    const { classes } = this.props;
    const { selectedSurvey, survey_name } = this.state;
    return (
      <div className={classes.fullScreen}>
      <form className={classes.fullScreen} onSubmit={(event) => this.submitForm(event)}>
      <div className={classes.designContainer}>
        <div className={classes.designHeader}>
          <Grid item xs={6} sm={6} md={6}>
            <div className={classes.surveyNameContainer}>
            <TextField
              disabled={selectedSurvey && selectedSurvey.name != 'create new +'}
              required={!selectedSurvey || selectedSurvey.name == 'create new +'}
              id="name"
              name="name"
              label="survey name"
              variant="outlined"
              maxrows={1}
              margin="dense"
              value={survey_name}
              style={{ marginLeft: 10, marginRight: 10, width: "100%"}}
              size="small"
              InputProps={{
                shrink: "true",
                style: {
                  fontSize: 12
                }
              }}
              onChange={(e) => this.onChangeSurveyName(e.target.value)}/>
            </div>
          </Grid>

          {(this.state.available_surveys && !this.props.controls) &&
            <Grid item xs={6} sm={6} md={6}>
              <div className={classes.loadSurveyBtn}>
                <Autocomplete
                  id="surveys-box"
                  fullWidth
                  groupBy={(option) => {
                    return(option.new ? null : option.is_global ? 'Global Surveys' : 'My Surveys')
                  }}
                  value={this.state.selectedSurvey}
                  style={{maxWidth: '200px'}}
                  options={[{name: 'create new +', new: true}].concat(this.state.available_surveys)}
                  getOptionLabel={option => option && option.name}
                  renderOption={(option) => (
                    <div style={option.new && {color: themeColors.secondary.main}}>{option.name}</div>
                  )}
                  onChange={(event, newValue) => {
                    if(newValue){
                      let clone = { ...newValue }
                      this.handleSurveyPicked(newValue);
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} fullWidth label="load survey" variant="outlined" margin="dense" />
                  )}
                />
                <div style={{margin: '4px'}}>
                  <Button
                    disabled={!this.state.selectedSurvey}
                    variant="outlined"
                    size="small"
                    className={classes.doneButton}
                    onClick={() => this.onEdit()}
                    style={{textTransform: 'none', marginLeft: 10}}>Edit</Button>
                </div>
                <div style={{margin: '4px'}}>
                  <Button
                    variant="outlined"
                    size="small"
                    type="submit"
                    className={classes.doneButton}
                    style={{textTransform: 'none', marginLeft: 10}}>{this.props.edit ? 'Create' : 'Save'}</Button>
                </div>
              </div>
            </Grid>
          }

        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>

          <div className={classes.dropAreaContainer}>
            <Droppable droppableId="ITEMS" isDropDisabled={true}>
                {(provided, snapshot) => (
                    <div
                        className={classes.questionMenu}
                        ref={provided.innerRef}
                        isdraggingover={snapshot.isDraggingOver.toString()}>
                        {ITEMS.map((item, index) => (
                            <Draggable
                                key={String(index)}
                                draggableId={String(index)}
                                isDragDisabled={(this.state.selectedSurvey!=null) && (this.state.selectedSurvey.name!='create new +')}
                                index={index}>
                                {(provided, snapshot) => (
                                    <React.Fragment>
                                        <div
                                            className={(this.state.selectedSurvey!=null) && (this.state.selectedSurvey.name!='create new +') ? "item-disabled" : "item"}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            isdragging={snapshot.isDragging.toString()}
                                            style={
                                                provided.draggableProps
                                                    .style
                                            }>
                                            {item.type}
                                        </div>

                                    </React.Fragment>
                                )}
                            </Draggable>

                        ))}
                          {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div className={classes.questionContainer}>
                {/*<Button onClick={this.addList}>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                        />
                    </svg>
                    Add List
                </Button>*/}

                <Droppable key={"test"} droppableId={"questions"}>
                    {(provided, snapshot) => (
                        <div
                            className={snapshot.isDraggingOver ? classes.dashedDrop: classes.questionDropArea}
                            ref={provided.innerRef}
                            isdraggingover={snapshot.isDraggingOver.toString()}>
                            {this.state.questions && (this.state.questions.length > 0)
                                ? this.state.questions.map(
                                      (item, index) => {
                                        return (
                                          <Draggable
                                              key={'d'+String(index)}
                                              draggableId={'d'+String(index)}
                                              isDragDisabled={(this.state.selectedSurvey!=null) && (this.state.selectedSurvey.name!='create new +')}
                                              index={index}>
                                              {(provided, snapshot) => (
                                                  <div
                                                      key={index}
                                                      className={this.state.selectedSurvey ? classes.questionRowDisabled : classes.questionRow}
                                                      ref={
                                                          provided.innerRef
                                                      }
                                                      {...provided.draggableProps}
                                                      isdragging={
                                                          snapshot.isDragging.toString()
                                                      }
                                                      style={
                                                          provided
                                                              .draggableProps
                                                              .style
                                                      }>
                                                      <div
                                                          className={classes.questionHeader}
                                                          {...provided.dragHandleProps}>
                                                          <div className={classes.questionRowItem}><ZoomOutMapIcon style={{ fontSize: 18 }}/></div>
                                                          <div className={classes.questionRowItem}>Question {index + 1}</div>
                                                          <div className={classes.topRightControl}>
                                                            <div style={{overflow: 'hidden'}}>
                                                            <FormControl component="fieldset">
                                                              <FormControlLabel
                                                                value="optional"
                                                                className={classes.optionalSwitch}
                                                                control={
                                                                  <Switch
                                                                    checked={item.optional || false}
                                                                    onChange={(e) => this.updateQuestionOptional(index, e.target.checked)}
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                  />
                                                                }
                                                                label={<Typography className={classes.optionalSwitchLabel}>optional</Typography>}
                                                                labelPlacement="start"
                                                              />
                                                              </FormControl>
                                                              </div>
                                                            <div className={classes.questionDelete} onClick={() => this.removeQuestion(index)}><RemoveCircleOutlineIcon /></div>
                                                          </div>
                                                      </div>

                                                      {this.renderQuestion(item, index)}
                                                    </div>
                                              )}
                                          </Draggable>
                                      )}
                                  )
                                : (
                                      <div className={classes.dropBox}>Drop questions here</div>
                                  )}
                          {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
          </div>
        </DragDropContext>
        {(this.props.controls) && <div className={classes.designFooter}>
          <Button
            variant="contained"
            size="small"
            style={{textTransform: 'none', marginRight: 10}}
            onClick={() => this.props.onFinished(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{color: 'white', textTransform: 'none'}}
            disabled={!survey_name || !(this.state.questions && (this.state.questions.length > 0))}
            >Create</Button>
        </div>}
      </div>
      </form>
      </div>

    );
  }

}

export default withStyles(useStyles)(SurveyDesigner);
