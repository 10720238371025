import { makeStyles } from "@material-ui/styles";
import bkgnd from "../../images/bkgnd.png";

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  rowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dashicon: {
    color: "#016637",
    fontSize: 146,
    marginLeft: theme.spacing(2)
  },
  logotypeContainer: {
    // backgroundImage: `url(${bkgnd})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    background: "linear-gradient(to right bottom, #016637, #137344, #218050, #2d8d5e, #399b6b, #4aa779, #59b387, #69bf95, #80cda6, #96dab8, #ace8ca, #c2f6dc);",
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 125,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "#016637",
    textAlign: "center",
    fontWeight: 500,
    fontSize: 48,
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
  },
  opacityOverlay: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: theme.spacing(4),
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  formContainer: {
    backgroundImage: `url(${require("../../images/doctor_bg.jpg")})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  footer: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    position: "absolute",
    bottom: theme.spacing(2),
  },
  animationWrapper: {
  },
  sphereAnimation: {
    width: 125,
    height: 125,
  },
  spherePath: {
    strokeWidth: '0.5px',
    stroke: 'green',
    backfaceVisibility: 'hidden',
  },
  tos: {
    padding: theme.spacing(2)
  },
  center: {
    alignItems: "center",
    textAlign: "center"
  }
}));
