import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BasicProductInfo from './BasicProductInfo';
import Description from './Description';
import { useProductState, updateProducts } from "../../context/ProductContext";
import { handleNotification } from '../notifications/NotificationsClient';

import { create_treatment } from '../../api/treatment';

// import FDAinfo from './FDAinfo';
// import SEInfo from './SEInfo';
// import useStyles from "./styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function getSteps() {
  return ['General information', 'Description', 'Review'];
}


export default function AddProduct(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  var prodState = useProductState().state;
  var prodDispatch = useProductState().dispatch;

  // for step 1:
  const [community, setCommunity] = React.useState('');
  const [prodType, setProdType] = React.useState('');
  const [available, setAvailable] = React.useState('');
  const [prodName, setProdName] = React.useState('');
  const [prodImage, setProdImage] = React.useState('');
  const [YTURL, setYTURL] = React.useState('');
  const [videoURL, setVideoURL] = React.useState('');
  const [hiw, setHiw] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedCats, setSelectedCats] = React.useState(null);
  // for step 2:

  const steps = getSteps();

  const isStepOptional = step => {
    return step === 5;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submitTreatment()
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const changeCommunity = (community) => {
    setCommunity(community);
  }
  const changeProdType = (type) => {
    setProdType(type);
  }
  const changeAvailable = (avail) => {
    setAvailable(avail)
  }
  const changeProdName = (name) => {
    setProdName(name);
  }
  const changeProdImage = (image) => {
    setProdImage(image);
  }
  const changeYTURL = (url) => {
    setYTURL(url);
  }
  const changeVideoURL = (url) => {
    setVideoURL(url);
  }
  const changeDescription = (text) => {
    setDescription(text);
  }
  const changeHIW = (text) => {
    setHiw(text);
  }
  const changeSelectedCats = (cats) => {
    setSelectedCats(cats)
  }

  async function submitTreatment() {
    const data = {
      name: prodName,
      community_id: community && community.id,
      description: description,
      how_it_works_description: hiw,
      video_embed: "",
      images: prodImage ? [prodImage] : []
    }
    const resp = await create_treatment(data);
    if(resp) {
      handleNotification("create treatment success")
      // let temp = JSON.parse(resp)
      updateProducts(
        prodDispatch,
        prodState
      )
      props.onFinished();
    } else {
      console.warn("not success");
    }

  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicProductInfo
            community={community}
            type={prodType}
            name={prodName}
            image={prodImage}
            yt_url={YTURL}
            categories={[]}
            selectedCats={selectedCats}
            onChangeCommunity={comm => changeCommunity(comm)}
            onChangeProdType={type => changeProdType(type)}
            onChangeAvailable={avail => changeAvailable(avail)}
            onChangeProdName={name => changeProdName(name)}
            onChangeProdImage={img => changeProdImage(img)}
            onChangeYTURL={url => changeYTURL(url)}
            onChangeVideoURL={url => changeVideoURL(url)}
            onChangeSelectedCats={cats => changeSelectedCats(cats)}
          />;
      case 1:
        return <Description
          hiw={hiw}
          description={description}
          onChangeDescription={text => changeDescription(text)}
          onChangeHIW={text => changeHIW(text)}
        />;
      default:
        return 'Are you sure about the information you provided?';
    }
  }

  const isDisabled = (step) => {
    switch (step) {
      case 0:
        return (!community || !prodName || !prodImage)
      case 1:
        return (!hiw || !description);
      case 2:
        return false;
      default:
        return false;
    }
  }

  return (
    <React.Fragment>
    <main className={classes.layout}>
      <Paper className={classes.paper}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {getStepContent(activeStep)}
        <div>
          <Button size="small" style={{textTransform: 'none'}} disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            Back
          </Button>
          {isStepOptional(activeStep) && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{textTransform: 'none'}}
              onClick={handleSkip}
              className={classes.button}
            >
              Skip
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleNext}
            disabled={isDisabled(activeStep)}
            style={{ color: 'white', textTransform: 'none'}}
            className={classes.button}
          >
            {activeStep === steps.length - 1 ? 'Create Treatment' : 'Next'}
          </Button>
        </div>
      </div>
      </Paper>
    </main>
    </React.Fragment>
  );
}
