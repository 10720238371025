import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  sefbutton: {
    marginLeft: theme.spacing(2),
  },
  sideEffect: {
    backgroundColor: theme.palette.background.default
  }
}));

const communities = [
  { title: 'Hyperhidrosis' },
  { title: 'GWI' },
  { title: 'Carpal Tunnel'},
  { title: 'The Dark Knight' }
]
export default function SEInfo(props) {
  const classes = useStyles();

  const [sef, setSef] = React.useState('');
  const [sefData, setSefData] = React.useState([
    { key: 0, label: 'Rash' },
    { key: 1, label: 'Nausia' }
  ]);
  const handleDelete = sefToDelete => () => {
    setSefData(sefs => sefs.filter(sef => sef.key !== sefToDelete.key));
  };

  const handleAdd = (sefToAdd) => {
    let newArray = sefData;
    let newSef = {key: newArray.length, label: sefToAdd}; // key to be changed to sef id
    newArray.push(newSef);

    const unique = [];
    const map = new Map();
    for (const item of newArray) {
      if(!map.has(item.label)){
        map.set(item.label, true);    // set any value to Map
        unique.push(item);
      }
    }

    setSefData(unique);
    setSef('');
  }

  return (
    <React.Fragment>

      <Grid container spacing={3}>

        {/* <Divider style={{width:'100%'}} variant="middle"/> */}

        <div style={{width: '100%'}}>
          <Typography variant="h5" style={{marginTop: 30}}>
            known side effects
          </Typography>
        </div>

        <Grid item xs={12} className={classes.sideEffect}>
          <FormGroup row style={{alignItems:'center'}}>
            <TextField
              id="sef"
              name="side effect"
              label="side effect"
              margin="dense"
              variant="outlined"
              value={sef}
              onChange={e => setSef(e.target.value)}
            />
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAdd(sef)}
                className={classes.sefbutton}>
                Add
              </Button>
            </div>
          </FormGroup>
        </Grid>

        <Grid item xs={12} className={classes.sideEffect}>
        {sefData.map(data => {
          let icon;

          return (
            <Chip
              key={data.key}
              icon={icon}
              label={data.label}
              onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              className={classes.chip}
            />
          );
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
