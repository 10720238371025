import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

export default function ApexLineChart(props) {
  var theme = useTheme();

  return (
    <ApexCharts
      options={themeOptions(theme, props.labels)}
      series={props.data}
      type="area"
      height={350}
    />
  );
}

// ############################################################
function themeOptions(theme, labels=[]) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: labels,
      title: {
        text: 'Journey Day',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.65,
          opacityTo: 0.25,
          stops: [20, 100, 100, 100]
        },
    },
    colors: [theme.palette.chart_second.main, theme.palette.chart_third.main, theme.palette.chart_fourth.main, theme.palette.chart_fifth.main],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      offsetX: -10
    },
  };
}
