import React, {useEffect} from "react";
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
// components
import Layout from "./Layout";
import AdminLayout from "./Layout/AdminLayout";
import CreatorLayout from "./Layout/CreatorLayout";
import DemoLayout from "./Layout/DemoLayout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Forgot from "../pages/login/Forgot";
import Register from "../pages/register";
import TOS from "../pages/register/TOS";
import PickProduct from "../pages/dashboard/PickProduct";

// context
import { useUserState } from "../context/UserContext";
import { ProductProvider } from "../context/ProductContext";

import { createBrowserHistory } from "history";
import { confirm_invite_to_study } from '../api/study';

var jwtDecode = require('jwt-decode');
const queryString = require('query-string');

export const history = createBrowserHistory()

export default function App() {
  // global
  var classes = useStyles();
  var { state } = useUserState();
  var { isAuthenticated, is_admin, user_type, org, roles, permissions, profile } = state;

  async function checkInvites() {
    const inv_token = localStorage.getItem('invite_token');
    if (inv_token) {
      var decoded = jwtDecode(inv_token)
      if (decoded && decoded.study) {
        let resp = await confirm_invite_to_study(decoded.study.id, inv_token);
        localStorage.removeItem('invite_token')
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      checkInvites()
    }
    // eslint-disable-next-line no-undef
    // branch.data(function(err, data) {
    //   console.warn(err, data);
    //   if (data) {
    //     let parsed = data.data_parsed;
    //     if (Object.keys(parsed).length !== 0) {
    //       let path
    //       if (parsed.$desktop_deeplink_path) {
    //         path = parsed.$desktop_deeplink_path
    //       } else {
    //         path = parsed.$deeplink_path
    //       }
    //       if (!path.startsWith('/')) {
    //         path = "/" + path
    //       }
    //       console.warn("Deep Link Path:", path)
    //       history.push(path);
    //     }
    //   }
    // })
  }, [])

  return (
    <BrowserRouter history={history}>
      <ToastContainer
        className={classes.toastsContainer}
        closeOnClick={false}
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        progressClassName={classes.notificationProgress}
      />
      {isAuthenticated && !profile ?
        <div className={classes.waitContainer}>
          <CircularProgress color="primary"/>
        </div>
        :
        <ProductProvider>
          <Switch>
            <Route exact path="/" render={() =>
              <Redirect to={(org && (org.type=='DELEGATED')) || (roles && (roles.length > 0) && (roles[0]=='RESEARCH_COORDINATOR')) ? "/study" : "/treatments"} />} />
            <Route
              exact
              path="/app"
              render={() =>
                <Redirect to={(org && (org.type=='DELEGATED')) || (roles && (roles.length > 0) && (roles[0]=='RESEARCH_COORDINATOR')) ? "/study" : "/treatments"} />} />}
            />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/tos" component={TOS} />
            <PublicRoute path="/join" component={Register} />
            <PublicRoute path="/resetpassword" component={Forgot} />
            <PublicRoute path="/register" component={Register} />
            <PrivateRoute path="/app/product" component={PickProduct} />
            {
              (user_type=='demo') ?
              <PrivateRoute path="/" component={DemoLayout} />
              :
              (roles && roles.length && (roles[0]=='demo')) ?
              <PrivateRoute path="/" component={DemoLayout} />
              :
              <PrivateRoute path="/" component={Layout} />
            }
            <Route component={Error} />
          </Switch>
        </ProductProvider>
      }
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
              React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    let from = rest.location && rest.location.state && rest.location.state.from;
    let path = from && (from.pathname + from.search);
    var parsed = queryString.parse(rest.location && rest.location.search);
    if (parsed && parsed.inv) {
      localStorage.setItem('invite_token', parsed.inv) // coming from /register when logged out
    } else if (parsed && rest.location && rest.location.pathname=="/join") {
      localStorage.setItem('invite_token', parsed.k) // coming from /join when logged in
    }
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: path ? path : "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
