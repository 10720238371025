export const BASE_URL = process.env.REACT_APP_API_URL
export const AUX_URL = process.env.REACT_APP_AUX_URL

export const LOGIN = () => {
  return BASE_URL + 'authorization/login'
};

export const REFRESH = () => {
  return BASE_URL + 'authorization/refresh'
}

export const REGISTER = () => {
  return BASE_URL + 'authorization/register'
};

export const PRODUCTS = () => {
  return BASE_URL + 'products'
}

export const COMMUNITY_STATS = (comm_id) => {
  return BASE_URL + 'stats/community/' + comm_id
}

export const PRODUCT_STATS = (prod_id) => {
  return BASE_URL + 'stats/product/' + prod_id
}

export const PROMO_STATS = (promo_id) => {
  return BASE_URL + 'stats/promotion/' + promo_id
}

export const PROMOTIONS = (product_id) => {
  return BASE_URL + 'promotion/' + product_id
}

export const ORG_USER = () => {
  return BASE_URL + 'organization/user'
}

export const ORG_INFO = () => {
  return BASE_URL + 'organization'
}

export const PROFILE = () => {
  return BASE_URL + 'profile'
}

export const COMMUNITIES = () => {
  return AUX_URL + 'communities_public/'
}

export const COMMUNITY_CATEGORIES = (id) => {
  return AUX_URL + 'communities/' + id + '/treatment_categories'
}

export const TREATMENTS = (id) => {
  let params = {
    page: 0,
    limit: 20,
    search: "",
    categories: [],
  };
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  return AUX_URL + 'communities/' + id + '/treatments?' + query
}

export const TREATMENT = (id) => {
  return AUX_URL + 'treatments/' + id
}

export const INVITE_PATIENT = (id) => {
  return BASE_URL + 'invitation/' + id
}

export const GET_INVITES = (id) => {
  return BASE_URL + 'invitation'
}

export const GET_PATIENTS = () => {
  return BASE_URL + 'patient'
}

export const PATIENT_ANALYSIS = (journey_id) => {
  return BASE_URL + 'patient/' + journey_id + "/insight"
}

export const FORGOT_PASSWORD = () => {
  return BASE_URL + 'forgot_password';
}
