import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import MoreInfo from '../../components/Dialog';

const strings = {
  desc: {
    title: 'Treatment Description',
    body: 'Brief description of this treatment. Whome it is intended for, How effective it can be. This information will show up in treatment "Description" section.'
  },
  hiw: {
    title: 'How it works',
    body: 'Explain how users should approach this treatment, recommended frequency and intensity in applicable. This information will show up in treatment "How it works" section.'
  },
}

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  sefbutton: {
    marginLeft: theme.spacing(2),
  },
  sideEffect: {
    backgroundColor: theme.palette.background.default
  }
}));

export default function Description(props) {
  const classes = useStyles();

  const [desc, setDesc] = useState(props.description);
  const [hiw, setHiw] = useState(props.hiw);

  function handleDescChange(val) {
    setDesc(val);
    props.onChangeDescription(val);
  }
  function handleChangeHIW(val) {
    setHiw(val);
    props.onChangeHIW(val);
  }

  return (
    <React.Fragment>

      <Grid container spacing={3}>

        <Grid item xs={12} >
          <MoreInfo title={strings['desc'].title} body={strings['desc'].body} image="/img/INFO_DESC.png"/>
          <TextField
            required
            placeholder="Description"
            id="desc-input"
            name="Description"
            label="Description"
            margin="dense"
            fullWidth
            multiline
            variant="outlined"
            maxRows={8}
            rows={3}
            value={desc}
            onChange={e => handleDescChange(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} >
          <MoreInfo title={strings['hiw'].title} body={strings['hiw'].body} image="/img/INFO_HIW.png"/>
          <TextField
            required
            id="HIW"
            name="How it works"
            label="How it works"
            margin="dense"
            fullWidth
            multiline
            variant="outlined"
            maxRows={8}
            rows={3}
            value={hiw}
            onChange={e => handleChangeHIW(e.target.value)}
          />
        </Grid>

        {/* <Divider style={{width:'100%'}} variant="middle"/> */}
      </Grid>
    </React.Fragment>
  );
}
