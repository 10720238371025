import  React from 'react';
import { useUserState } from "../context/UserContext";
// import { allowedUserChecker } from 'utils';
import PropTypes from 'prop-types';
import AccessDenied from  "../pages/error/AccessDenied";

const RouterRBAC = ({ allowedRoles, children, ...rest }) => {
    var { state } = useUserState();
    var { isAuthenticated, is_admin, user_type, org, roles, permissions, profile } = state;
    let access = allowedUserChecker(roles, permissions, allowedRoles);
    if (access) {
      return children
    } else {
      return <AccessDenied/>
    }
};

RouterRBAC.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element
};

export default RouterRBAC;

function allowedUserChecker(roles, permissions, allowedRoles) {
  if(!allowedRoles || allowedRoles.length==0) {
    return true
  } else if (!roles || roles==[]) {
    return false
  }
  const filteredArray = roles.filter(value => allowedRoles.includes(value));
  return (filteredArray.length > 0)
}
