import React, {useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "./user-header-style.js";

const useStyles = makeStyles(componentStyles);

const UserHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [cover, setCover] = useState(null);

  const handleCapture = ({ target }) => {
      const fileReader = new FileReader();
      const name = target.accept.includes('image') ? 'images' : 'videos';

      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setCover(e.target.result)

        console.warn("e.target.result",e.target.result)
      };
  };

  return (
    <>
      <Box
        paddingTop="3rem"
        paddingBottom="8rem"
        alignItems="center"
        display="flex"
        className={classes.wrapperBox}
        minHeight="600px"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="-1"
          className={classes.overlayBox}
        >
        {cover &&
          <div className={classes.imageContainer}>
            <img src={cover} alt="logo" className={classes.coverPhoto}/>
          </div>
        }
        </Box>

        <Container
          display="flex"
          alignItems="center"
          maxWidth={false}
          component={Box}
          zIndex="1"
          classes={{ root: classes.containerRoot }}
        >
          <Grid container>
            <Grid item xs={12} md={10} lg={7}>
              <Typography
                variant="h1"
                classes={{ root: classes.typographyRootH1 }}
              >
                Hello Babak
              </Typography>
              <Box
                component="p"
                marginBottom="3rem"
                color={theme.palette.background.default}
                lineHeight="1.7"
                fontSize="1rem"
              >
                This is your profile page. This information will show up on your edenos profile!
              </Box>
              <Button
                variant="contained"
                component="label"
                classes={{ root: classes.buttonRoot }}
              >
                Edit cover photo
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCapture}
                  hidden/>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UserHeader;
