import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  InsertChart as InsightIcon,
  CardGiftcard as PromoIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Person as AccountIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon,
  VideoLibrary as MyLibraryIcon,
  ShoppingBasket,
  FindInPage as FindInPageIcon,
  Favorite as FavoriteIcon,
  AddToQueue as RTMIcon,
  Spa as SpaIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import RBAC from '../RBAC';
import permissions from '../../configs/rbac.config';

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import {useProductState} from '../../context/ProductContext';
import {useUserState} from '../../context/UserContext';

const structure = [
  { id: 1, label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },
  { id: 2, label: "Promotions", link: "/promotions", icon: <PromoIcon /> },
  { id: 3, label: "Orders", link: "/orders", icon: <ShoppingBasket /> },
  /*{
    id: 3,
    label: "Notifications",
    link: "/app/notifications",
    icon: <NotificationsIcon />,
  },*/
  {
    id: 4,
    label: "Edenos Insight",
    link: "/insight",
    icon: <InsightIcon />,
  },
  { id: 10, type: "divider" },
  { id: 8, label: "Support", link: "/support", icon: <SupportIcon /> },
];

const structure_admin = [
  /*{ id: 1, label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },*/
  { id: 12, label: "Treatments", link: "/treatments", icon: <FavoriteIcon /> },
  { id: 2, label: "Orders", link: "/orders", icon: <ShoppingBasket /> },
  { id: 3, label: "Promotions", link: "/promotions", icon: <PromoIcon /> },
  { id: 4, label: "Studies", link: "/study", icon: <FindInPageIcon /> },
  { id: 5, label: "RTM", link: "/rtm", icon: <RTMIcon /> },
  { id: 11, label: "Survey Library", link: "/surveylibrary", icon: <LibraryIcon /> },
  { id: 13, label: "Therapies", link: "/therapies", icon: <SpaIcon /> },
  /*{ id: 5, label: "Library", link: "/library", icon: <LibraryIcon /> },*/
  /*{
    id: 3,
    label: "Notifications",
    link: "/app/notifications",
    icon: <NotificationsIcon />,
  },*/
  {
    id: 6,
    label: "Edenos Insight",
    link: "/insight",
    icon: <InsightIcon />,
    // children: [
    //   { id: 44, label: "Icons", link: "/app/ui/icons" },
    //   { id: 45, label: "Charts", link: "/app/ui/charts" },
    //   { id: 46, label: "Maps", link: "/app/ui/maps" },
    // ],
  },
  { id: 10, type: "divider" },
  { id: 14, label: "Account", link: "/account", icon: <SettingsIcon /> },
  { id: 7, label: "Manage Users", link: "/manage", icon: <SupervisorAccountIcon /> },
  { id: 8, label: "Support", link: "/support", icon: <SupportIcon /> },
];

function DemoSidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var productState = useProductState().state;
  var userState = useUserState().state;
  var prods = productState.products;
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, [productState]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>

      {/*prods&&<List className={classes.sidebarList}>

          <SidebarLink
            key={1}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...{
                label: "Products Dashboard",
                link: "/dashboard/",
                icon: <UIElementsIcon />,
                children:
                  prods.map(prod => (
                    {
                      id: prod.id,
                      label: prod.name,
                      link: "/dashboard/" + prod.id,
                      icon: <Dot size="large" color="warning" />
                    }
                  ))
              }
            }
          />

      </List>
      */}
      <List className={classes.sidebarList}>
        {structure_admin.map(link => (
            <RBAC allowedRoles={permissions.layoutPermissions[link.label]} allowedOrgs={permissions.layoutOrgPermissions[link.label]} key={link.id}>
              <SidebarLink
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            </RBAC>
          ))
        }
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(DemoSidebar);
