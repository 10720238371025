import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Radio,
  Box
} from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classnames from "classnames";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

//
// function SimpleQuestion(props) {
//   var classes = useStyles();
//   const { children, value, index, ...other } = props;
//
//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       className={classes.tab}
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }
//
// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

class TextQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      value: ''
    };
  }
  //
  // var classes = useStyles();
  //
  // const [title, setTitle] = useState(0);
  // const [options, setOptions] = useState(null);
  //
  //
  // const handleChange = (event, newValue) => {
  //   // setValue(newValue);
  // };

  updateQuestionData() {
    const {title} = this.state;

    this.props.onDataChange({
      'title': title,
    })
  }

  changeTitle(val) {
    this.setState({title: val}, () => {
      this.updateQuestionData()
    })
  }

  changeValue(val) {
    this.setState({value: val})
    if (this.props.answer) {
      this.props.onDataChange({
        'value': val,
      })
    }
  }

  render () {
    const { classes, answer, optional } = this.props;
    return (
      <div className={classes.questionModuleContainer}>
        <fieldset disabled={this.props.disabled} style={{border: 0}}>
          <TextField
            required={!answer}
            disabled={answer}
            name="name"
            value={this.state.title}
            label="question"
            margin="dense"
            fullWidth
            multiline
            variant="outlined"
            maxrows={4}
            onChange={e => this.changeTitle(e.target.value)}
          />

          <FormControl component="fieldset" className={classes.formControl}>
            <TextField
              name="name"
              required={answer && !optional}
              value={this.state.value}
              label="answer"
              placeholder={'e.g. I had difficulty sleeping last night.'}
              disabled={!answer}
              margin="dense"
              variant="outlined"
              multiline
              fullWidth
              onChange={e => this.changeValue(e.target.value)}
            />
          </FormControl>
        </fieldset>
      </div>
    );
  }


}

export default withStyles(useStyles)(TextQuestion);
