import * as urls from './treatment-urls';
import { apiClient } from './apiClient';


export async function get_treatment(id, dispatch=null) {
  let url = urls.TREATMENT() + '/' + id;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_treatments(dispatch=null) {
  let url = urls.TREATMENT()+"?sort=desc";
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function create_treatment(data, dispatch=null) {
  let url = urls.TREATMENT();
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function upload_photo(data, dispatch=null) {
  let url = urls.UPLOADPHOTO();
  let response = await apiClient(
    url,
    'POST',
    data,
    {
      'content-type': 'multipart/form-data'
    },
    dispatch
  )
  return response.data
}
