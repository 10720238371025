import { makeStyles } from "@material-ui/styles";
import bkgnd from "../../images/bkgnd.png";

const MAX_WIDTH = 1200;

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.customShadows.widget,
    padding: theme.spacing(5),
  },
  infoForm: {

  },
  formControl: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  shortinput: {
    width: '45ch',
    backgroundColor: 'white'
  },
  description: {
    height: 500,
    width: '100%',
    resize: 'vertical',
    padding: theme.spacing(2),
    fontSize: 16,
    borderColor: 'lightgrey',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    borderRadius: 5
  },
  saveBtn: {
    display: "flex",
    width: '100%',
    justifyContent: 'flex-end',
  },
}));
