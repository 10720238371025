import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TabPanel,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import classnames from "classnames";
import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddStudy from './AddStudy';
import SurveyDesigner from './SurveyDesigner';
// styles
import useStyles from "./styles";
import { withStyles } from '@material-ui/styles';
import { create_survey, get_survey, get_surveys } from '../../api/study';
import { useUserState } from "../../context/UserContext";
import { handleNotification } from '../notifications/NotificationsClient';

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";


const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});



export default function Survey (props){
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     questions: [],
  //     surveyName: [],
  //     surveyID: null,
  //     surveys: [],
  //     openAdd: false
  //   };
  // }

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [globalSurveys, setGlobalSurveys] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [surveyID, setSurveyID] = useState(false);

  function prepareSurveyData() {
    let survey = {};
    let payload_questions = [];
    survey.name = surveyName;
    survey.description = ' ';
    for (let i=0; i < questions.length; i++) {
      const q = questions[i];
      let d = {}
      d.type = q.type.toLowerCase();
      d.title = q.title;
      d.rank = i+1;
      d.options = q.options;
      d.optional = q.optional;
      if (d.type=='scale') {
        d.min_label = q.min_label;
        d.max_label = q.max_label;
      }
      payload_questions.push(d);
    }
    survey.questions = payload_questions;
    if (surveyID) {
      survey.survey_id = surveyID
    }
    if (survey.questions.length == 0 && !surveyID) {
      return null
    }
    return survey;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleClickAdd() {
    setOpenAdd(true)
  }

  function handleClickStudy(id) {
    props.history.push(`/surveylibrary/${id}`);
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  async function onFinished(created) {
    if (created) {
      const resp = await create_survey(prepareSurveyData());
      if (resp && resp.id) {
        setOpenAdd(false)
        handleNotification("survey create success")
        init()
      } else {
        handleNotification("survey create fail")
      }
    } else {
      setOpenAdd(false)
    }
  }

  async function init() {
    const surveys = await get_surveys();
    setSurveys(surveys.filter(s => !s.is_global));
    setGlobalSurveys(surveys.filter(s => s.is_global));
    setQuestions([])
    setSurveyName("")
    setSurveyID(false)
  }

  useEffect(() => {
    init()
  }, [])


  return (
      <div >

        <div className={classes.addBtn}>
          <Button
            onClick={handleClickAdd}
            variant="contained"
            style={{ textTransform: 'none', color: 'white'}}
            startIcon={<PostAddIcon />}
            color="secondary">
            New Survey
          </Button>
        </div>

        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="My surveys" {...a11yProps(0)} />
          <Tab label="Public Surveys" {...a11yProps(1)} />
        </Tabs>
        <TabPanel {...props} value={value} index={0}>
          <Grid container spacing={4} className={classes.productGrid} >
            {surveys && surveys.map((item, index) => {
              return(
                <Grid key={index} item lg={4} md={6} sm={6} xs={12}
                  onClick={() => {handleClickStudy(item.id)}}>
                  <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                    <div className={classes.cardBody}>
                      <Grid
                        container
                        direction="row"
                        justifycontent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={12} md={8}>
                          <div className={classes.videoDesc}>
                            <div className={classes.videoTitle}>{item.name}</div>
                            {/*<div className={classes.dateContent}>
                              created: 04/09/2021
                            </div>*/}
                          </div>
                        </Grid>

                          <Grid item xs={12} sm={12} md={4} alignSelf="flex-end">
                            {/*<div className={classes.viewsContainer}>
                              <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                              <div className={classes.viewCount}>{4}</div>
                              studies
                            </div>*/}
                            <Chip label={item.questions.length + ((item.questions.length > 1) ? ' questions' : ' question')} color="success" variant="outlined" />
                          </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              )}
            )}
          </Grid>
        </TabPanel>
        <TabPanel {...props} value={value} index={1}>
          <Grid container spacing={4} className={classes.productGrid} >
            {globalSurveys && globalSurveys.map((item, index) => {
              return(
                <Grid key={index} item lg={4} md={6} sm={6} xs={12}
                  onClick={() => {handleClickStudy(item.id)}}>
                  <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                    <div className={classes.cardBody}>
                      <Grid
                        container
                        direction="row"
                        justifycontent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={12} md={8}>
                          <div className={classes.videoDesc}>
                            <div className={classes.videoTitle}>{item.name}</div>
                            {/*<div className={classes.dateContent}>
                              created: 04/09/2021
                            </div>*/}
                          </div>
                        </Grid>

                          <Grid item xs={12} sm={12} md={4} alignSelf="flex-end">
                            {/*<div className={classes.viewsContainer}>
                              <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                              <div className={classes.viewCount}>{4}</div>
                              studies
                            </div>*/}
                            <Chip label={item.questions.length + ((item.questions.length > 1) ? ' questions' : ' question')} color="success" variant="outlined" />
                          </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              )}
            )}
          </Grid>
        </TabPanel>
        <Dialog
          maxWidth={"lg"}
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          aria-labelledby="product-dialog-title"
          aria-describedby="product-dialog-description"
        >
          <SurveyDesigner
            surveyName={surveyName}
            questions={questions}
            onQuestionUpdate={(questions) => setQuestions(questions)}
            onSurveyNameUpdate={(name) => setSurveyName(name)}
            onSurveySelected={(survey) => setSurveyID(survey && survey.id)}
            onFinished={(created) => onFinished(created)}/>
        </Dialog>

      </div>
  );


}

// export default withStyles(useStyles)(Survey);
