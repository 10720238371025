import React from 'react';
import ApexCharts from "react-apexcharts";

function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
}

export default class Test extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: 'Question1',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question2',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question3',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question4',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question5',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question6',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question7',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question8',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Question9',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      }
      ],
      options: {
        chart: {
          height: 350,
          type: 'heatmap',
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#008FFB"],
        title: {
          text: 'HeatMap Chart (Single color)'
        },
      },


    };
  }



  render() {
    return (


<div id="chart">
<ApexCharts options={this.state.options} series={this.state.series} type="heatmap" height={350} />
</div>
)}
}
