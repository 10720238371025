export const BASE_URL = process.env.REACT_APP_API_URL

export const STUDY = () => {
  return BASE_URL + 'study'
};

export const SURVEY = () => {
  return BASE_URL + 'survey'
};

export const SURVEY_RESULT = () => {
  return BASE_URL + 'survey_result'
};

export const COHORT = (id) => {
  return BASE_URL + 'cohort/' + id
};

export const STUDY_ANALYTICS = (study_id) => {
  return BASE_URL + 'study/' + study_id + "/analytics"
}

export const PATIENT = (id) => {
  return BASE_URL + 'patient/' + id + "/"
}

export const JOURNEY = (id) => {
  return BASE_URL + 'journey/' + id
}

export const THERAPY = () => {
  return BASE_URL + 'therapy'
};
