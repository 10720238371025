export default {
  tasks: [
    {
      id: 0,
      type: "Meeting",
      title: "Meeting with Andrew Piker",
      time: "9:00"
    },
    {
      id: 1,
      type: "Call",
      title: "Call with HT Company",
      time: "12:00"
    },
    {
      id: 2,
      type: "Meeting",
      title: "Meeting with Zoe Alison",
      time: "14:00"
    },
    {
      id: 3,
      type: "Interview",
      title: "Interview with HR",
      time: "15:00"
    }
  ],
  overalJourney:
  {
      journey_score: {
        monthly: 4232,
        weekly: 1465,
        daily: 199,
        percent: { value: 3.7, profit: false }
      },
      color: "primary",
      grip: {
        monthly: { value: 830, profit: false },
        weekly: { value: 215, profit: true },
        daily: { value: 12, profit: false }
      },
      pain: {
        monthly: { value: 4.5, profit: false },
        weekly: { value: 3, profit: true },
        daily: { value: 3.25, profit: true }
      }
  },
  pieChart: [
    { name: 'Side effect 1', value: 432 }, { name: 'Side effect 2', value: 310 },
    { name: 'Side effect 3', value: 381 }, { name: 'Side effect 4', value: 207 },
  ],
  bigStat: [
    {
      product: "Product A",
      total: {
        monthly: 4232,
        weekly: 1465,
        daily: 199,
        percent: { value: 3.7, profit: false }
      },
      color: "primary",
      journeys: {
        monthly: { value: 830, profit: false },
        weekly: { value: 215, profit: true },
        daily: { value: 33, profit: true }
      },
      bounce: {
        monthly: { value: 4.5, profit: false },
        weekly: { value: 3, profit: true },
        daily: { value: 3.25, profit: true }
      }
    },
    {
      product: "Product B",
      total: {
        monthly: 754,
        weekly: 180,
        daily: 27,
        percent: { value: 2.5, profit: true }
      },
      color: "warning",
      journeys: {
        monthly: { value: 32, profit: true },
        weekly: { value: 8, profit: true },
        daily: { value: 2, profit: false }
      },
      bounce: {
        monthly: { value: 2.5, profit: true },
        weekly: { value: 4, profit: false },
        daily: { value: 4.5, profit: false }
      }
    },
    {
      product: "Product C",
      total: {
        monthly: 1025,
        weekly: 301,
        daily: 44,
        percent: { value: 3.1, profit: true }
      },
      color: "secondary",
      journeys: {
        monthly: { value: 230, profit: true },
        weekly: { value: 58, profit: false },
        daily: { value: 15, profit: false }
      },
      bounce: {
        monthly: { value: 21.5, profit: false },
        weekly: { value: 19.35, profit: false },
        daily: { value: 10.1, profit: true }
      }
    }
  ],
  notifications: [
    {
      id: 0,
      icon: "thumbs-up",
      color: "primary",
      content:
        'Ken <span className="fw-semi-bold">accepts</span> your invitation'
    },
    {
      id: 1,
      icon: "file",
      color: "success",
      content: "Report from LT Company"
    },
    {
      id: 2,
      icon: "envelope",
      color: "danger",
      content: '4 <span className="fw-semi-bold">Private</span> Mails'
    },
    {
      id: 3,
      icon: "comment",
      color: "success",
      content: '3 <span className="fw-semi-bold">Comments</span> to your Post'
    },
    {
      id: 4,
      icon: "cog",
      color: "light",
      content: 'New <span className="fw-semi-bold">Version</span> of RNS app'
    },
    {
      id: 5,
      icon: "bell",
      color: "info",
      content:
        '15 <span className="fw-semi-bold">Notifications</span> from Social Apps'
    }
  ],
  promo_table: [
    {
      id: 0,
      username: "PeterB",
      date_accepted: "28 Aug 2020",
      city: "Los Angeles",
      status: "Processing"
    },
    {
      id: 1,
      username: "Emily_D",
      date_accepted: "11 Aug 2020",
      city: "Austin",
      status: "Sent"
    },
    {
      id: 2,
      username: "Ironman12",
      date_accepted: "2 Aug 2020",
      city: "San Diego",
      status: "Sent"
    },
    {
      id: 3,
      username: "Gibbons",
      date_accepted: "19 July 2020",
      city: "Seattle",
      status: "Received"
    },
    {
      id: 4,
      username: "JenGil",
      date_accepted: "22 June 2020",
      city: "Los Angeles",
      status: "Sent"
    },
    {
      id: 5,
      username: "Annie1010",
      date_accepted: "19 Feb 2019",
      city: "Seattle",
      status: "Received"
    },
    {
      id: 6,
      username: "Gilmore21",
      date_accepted: "1 Mar 2019",
      city: "Los Angeles",
      status: "Received"
    }
  ],
  patient_table: [
    {
      firstname: "Babak",
      lastname: "Moatamed",
      treatment: "carpal tunnel",
      email: "babak@edenos.commm",
      status: "confirmed",
      started_at: "01/01/2022",
      journey: {
        last_entry_at: "02/02/2022",
        journey_entries_count: 3
      }
    },
    {
      firstname: "Jason",
      lastname: "Chang",
      treatment: "carpal tunnel",
      email: "jason@edenos.commm",
      status: "invited",
      started_at: "01/01/2022",
      journey: {
        started_at: "01/01/2022",
        last_entry_at: "02/02/2022",
        journey_entries_count: 3
      }
    },
    {
      firstname: "Peter",
      lastname: "Nguyen",
      treatment: "hyperhidrosis",
      email: "peter@edenos.commm",
      status: "sent",
      started_at: "01/01/2022",
      journey: {
        started_at: "01/01/2022",
        last_entry_at: "02/02/2022",
        journey_entries_count: 3
      }
    }
  ]
};
