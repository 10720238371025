import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import InjectedCheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51J3Ws2Hn6GyWjYq3qqZcIgTKYVyd1GbwzQglmqIdEAS6IUtkzneB5xhiTWTJBSEIupZnyynzI1ooG0k8avoIDh6V00hkDuNaWD');


class MyStoreCheckout extends React.Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <InjectedCheckoutForm />
      </Elements>
    );
  }
}

export default MyStoreCheckout;
