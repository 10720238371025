import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#2bc77d',
        backgroundColor: '#2bc77d',
        color: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '80%',
        borderRightColor: '#FFF',
        borderRightWidth: 1,
    },
    type: {
        width: '20%'
    }
});

const QuestionHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Question</Text>
        <Text style={styles.type}>Type</Text>
    </View>
);

export default QuestionHeader;
