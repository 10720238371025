import React, { useEffect } from "react";
import { handleNotification } from '../pages/notifications/NotificationsClient';
import { login, register, confirm } from '../api/auth';
import { get_profile, get_orginfo } from '../api/manage';
import { confirm_invite_to_study } from '../api/study';

var UserStateContext = React.createContext();
var jwtDecode = require('jwt-decode');

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state,
        isAuthenticated: true,
        user_type: action.data.user_type,
        org: action.data.org,
        roles: action.data.roles,
        permissions: action.data.permissions
       };
    case "SIGN_OUT_SUCCESS":
      return { isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "GOT_PROFILE":
      let user_type = (action.data.organization && action.data.organization.name == "Demo") ? 'demo' : 'influencer'
      return { ...state,
        isAuthenticated: true,
        profile: action.data,
        user_type: user_type,
        org: action.org ? action.org : state.org,
        roles: action.roles ? action.roles : state.roles,
        permissions: action.permissions ? action.permissions : state.permissions
      }
    case "REQUEST_SUCCESS":
      handleNotification("request success")
      return { ...state, isAuthenticated: false };
    case "REQUEST_FAILURE":
      return { ...state, isAuthenticated: false };
    case "REGISTER_SUCCESS":
      handleNotification("register success")
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  let token = localStorage.getItem("dash_token");
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!token,
  });
  async function fetchProfile() {
    if (!state.isAuthenticated) {
      return
    }
    const {data, error} = await get_profile()
    if (error && error === 'unauthroized') {
      console.warn("TODO: FIX", error)
      signOut(dispatch)
    } else if (error === 400) {
      signOut(dispatch)
    } else {
      var decoded = jwtDecode(token);
      let org = decoded.organization;
      let roles = (data && data.first_name && data.first_name=='Babak' && data.last_name && data.last_name=='Moatamed') ? ['demo'] : decoded.roles;
      let permissions = decoded.permissions;
      const orgInfo = await get_orginfo();
      dispatch({ type: "GOT_PROFILE", data: (data) ? (data) : [], org: orgInfo, roles, permissions })
    }
  }
  useEffect(()=> {
    fetchProfile()
  },[])

  return (
    <UserStateContext.Provider value={{state, dispatch}}>
        {children}
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, loginUser, signOut, requestUser, completeSignUp, confirmAccount };

// ###########################################################

function requestUser(dispatch, name, email, link, fda, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (name.toLowerCase() == 'edenos' && email.toLowerCase() == 'web@edenos.com') {
    setTimeout(() => {
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'REQUEST_SUCCESS' });
    }, 1000);
  } else {
    dispatch({ type: "REQUEST_FAILURE" });
    handleNotification("request error");
    setError(true);
    setIsLoading(false);
  }
}

async function confirmAccount(token, history, setIsLoading, setConfirm) {

  setConfirm(false);
  setIsLoading(true);
  if (token) {
    try {
      var decoded = jwtDecode(token);
      let {data, error} = await confirm(token)
      if (error) {
        handleNotification("confirm error")
        setConfirm(false);
        setIsLoading(false);
      } else {
        handleNotification("confirm success")
        setConfirm(true)
      }
    }
    catch (e) {
      setConfirm(false)
      setIsLoading(false)
      handleNotification("invalid link error")
    }
  } else {
    setConfirm(false)
    setIsLoading(false)
    handleNotification("invalid link error")
  }
}

async function completeSignUp(dispatch, token, email, password, firstname, lastname, organization, history, setIsLoading, setError) {

  setError(false);
  setIsLoading(true);

  if (token) {
    try {
      var decoded = jwtDecode(token);
      // if (decoded.email.toLowerCase() !== email) {
      //   setError(true)
      //   setIsLoading(false)
      //   handleNotification("bad email error")
      // } else {
        let {data, headers, error, msg_detail} = await register(token, email, password, firstname, lastname, organization)
        if (error) {
          console.warn("data, headers, error,",data, headers, error)
          setError(true);
          setIsLoading(false);
          if (msg_detail && msg_detail.password) {
            handleNotification(msg_detail.password, 'center', true)
          }
        } else {
          if (decoded.study) { // we also need to send the PATCH request to confirm the study
            let resp = await confirm_invite_to_study(decoded.study.id, token, headers.authorization);
          }
          dispatch({ type: "REGISTER_SUCCESS" });
          history.push('/login')
        }
      // }
    }
    catch (e) {
      setError(true)
      setIsLoading(false)
      handleNotification("invalid link error")
    }
  } else {
    setError(true)
    setIsLoading(false)
    handleNotification("invalid link error")
  }
}

async function loginUser(dispatch, email, password, ouid, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  let response = await login(email, password, ouid);

  if (!response.error) {
      let org;
      let roles = [];
      let permissions = [];
      if(response.headers) {
        var decoded = jwtDecode(response.headers.authorization);
        org = decoded.organization;
        roles = decoded.roles;
        permissions = decoded.permissions;
        await localStorage.setItem('dash_token', response.headers.authorization);
      }
      setError(null)
      setIsLoading(false)
      let user_type = (response.data.organization && response.data.organization.name == "Demo") ? 'demo' : 'influencer'
      dispatch({ type: 'LOGIN_SUCCESS', data: {
        user_type: user_type,
        org: org,
        roles:( email.toLowerCase() == 'demo@edenos.com') ? ['demo'] : roles,
        permissions: permissions
      } })

      setTimeout(async() => {
        const {data, error} = await get_profile();
        const orgInfo = await get_orginfo();
        console.warn("orgInfo",orgInfo)
        if (error && error === 'unauthroized') {
          console.warn("TODO: FIX")
        } else {
          dispatch({ type: "GOT_PROFILE", data: (data) ? (data) : [], org: orgInfo })
        }
      }, 400)

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    handleNotification("login error")
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history=null) {
  localStorage.removeItem("dash_token");
  localStorage.removeItem("dash_admin");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  if (history) {
    history.push("/login");
  }
}
