
import React from "react";
import { Button, Avatar } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <div className={classes.pageTitleImageContainer}>
        {props.image && (
          <Avatar alt={props.title} src={props.image} />
        )}
        <div>
        <Typography className={classes.typo} variant="h3" size="sm">
          {props.title}
        </Typography>
        { props.subTitle &&
          <Typography className={classes.typo} variant="h5" size="sm">
            {props.subTitle}
          </Typography>
        }
        </div>
      </div>
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {props.button}
        </Button>
      )}
    </div>
  );
}
