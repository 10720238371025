import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment,
  Box,
  Paper,
  Dialog,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  Chip
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DatePicker from "react-datepicker";
import classnames from "classnames";
import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
import { get_upload_url, upload_video, get_video } from '../../api/video';
import { useUserState } from "../../context/UserContext";
import {
    add_shadow_patient_to_study,
 } from '../../api/study';

import { CSVReader } from 'react-papaparse'

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";
import { handleNotification } from '../notifications/NotificationsClient';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const genders = [ 'Female', 'Male']
const ITEMS = [1,2,3,5,6,7,8,9]
const buttonRef = React.createRef()

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      first_name: "",
      last_name: "",
      zip: "",
      gender: "",
      dob: "2000-01-01",
      invite_to_app: true,
      questions: props.questions,
      patients: {},
      cohorts: [],
      value: 0
    };
  }


  componentDidMount() {
    let cohorts = this.props.cohorts;
    if (cohorts && (cohorts.length > 0)) {
      if (this.props.studyType == 'RTM') {
        this.setState({selected: cohorts[0]})
      }
      let patients = {}
      cohorts.forEach(function(c, index)
      {
          patients[c.id] = [];
      });
      this.setState({patients})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clear) {
      this.setState({
        first_name: "",
        last_name: "",
        zip: "",
        gender: "",
        dob: "2000-01-01"
      })
    }
  }

  onChangeType() {

  }

  async addShadowUser(data) {
    const resp = await add_shadow_patient_to_study(data.cohort.id, {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth
    });
    if (resp && resp.data) {
      handleNotification("shadow patient invite success")
      this.props.refreshPatients()
      return (1)
    } else if (resp && resp.msg_detail) {
      handleNotification(Object.keys(resp.msg_detail)[0] + " : " + resp.msg_detail[Object.keys(resp.msg_detail)[0]][0], null, true)
      return (-1)
    } else {
      handleNotification("invitation error")
      return (-1)
    }
  }

  async handleClickAdd(event) {
    event.preventDefault()
    let selected = this.state.selected;
    if (selected == null) return;
    if (this.state.invite_to_app ) {
      if (this.state.email.includes('@')){
        let p = this.state.patients;
        let patient_list = p[selected.id]
        if (patient_list == null) {
          patient_list = []
        }
        let found = patient_list.find(p => {
           return p.email == this.state.email
        })
        if (found) {
          handleNotification("duplicate email")
          return
        }
        patient_list.push({
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          date_of_birth: dob,
          gender: this.state.gender,
          type: 'platform'
        })
        p[selected.id] = patient_list
        this.setState({
          patients: p,
          first_name: "",
          last_name: "",
          zip: "",
          gender: "",
          email: "",
          dob: "2000-01-01"
        })
        if (this.props.updatePatients) {
          this.props.updatePatients(p)
        }
      }
    } else {
      var dob = ""
      try {
        dob = new Date(this.state.dob).toISOString().slice(0, 10);
      } catch (e) {
        console.warn("DOB format error")
      }
      // const resp = await this.addShadowUser({
      //   first_name: this.state.first_name,
      //   last_name: this.state.last_name,
      //   gender: this.state.gender == 'Male' ? 'm' : this.state.gender == 'Female' ? 'f' : '',
      //   date_of_birth: dob,
      //   cohort: this.state.selected
      // })


      let p = this.state.patients;
      let patient_list = p[selected.id]
      if (patient_list == null) {
        patient_list = []
      }
      patient_list.push({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        gender: this.state.gender == 'Male' ? 'm' : this.state.gender == 'Female' ? 'f' : '',
        date_of_birth: dob,
        cohort: this.state.selected,
        type: 'shadow'
      })
      p[selected.id] = patient_list
      this.setState({
        patients: p,
        first_name: "",
        last_name: "",
        zip: "",
        gender: "",
        email: "",
        dob: "2000-01-01"
      })
      if (this.props.updatePatients) {
        this.props.updatePatients(p)
      }
    }
  }

  handleDeleteEmail = (i) => {
    let selected = this.state.selected;
    let p = this.state.patients;
    let patient_list = p[selected.id]
    patient_list.splice(i, 1)
    p[selected.id] = patient_list
    this.setState({patients: p})
    if (this.props.updatePatients) {
      this.props.updatePatients(p)
    }
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  handleOnFileLoad = (data) => {
    console.log(data)
    let emails = []
    for(let i=0; i<data.length; i++) {
      let row = data[i]
      if(row.data[0] && row.data[0].includes('@')){
        emails.push(row.data[0])
      }
    }
    let selected = this.state.selected;
    let p = this.state.patients;
    let patient_list = p[selected]
    let new_list = p[selected].concat(emails)
    p[selected] = new_list
    this.setState({patients: p})
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

  handleInviteAll = () => {
    this.props.onSubmit(this.state.patients)
  }


  addList = e => {
    const list_id = `list-${new Date().getTime()}`
    const old_questions = this.state.questions;
    const question = {
      'id': list_id,
      'type': "test"
    }
    this.setState({ questions: old_questions.concat(question) });
  };

  checkCohortSelected() {
    if (!this.state.selected) {
      handleNotification("select cohort first")
    }
  }

  checkInviteDisabled() {
    for (var c in this.state.patients) {
      if (this.state.patients[c].length > 0) {
        return false
      }
    }
    return true
  }

  onDateChange(date) {
    // const dob = new Date(date).toISOString().slice(0, 10);
    this.setState({dob: date})
  }

  changeValue(val) {
    this.setState({value: val})
  }

  changeFirstName(val) {
    this.setState({first_name: val.replace(/[^a-z]/gi, '')})
  }

  changeLastName(val) {
    this.setState({last_name: val.replace(/[^a-z]/gi, '')})
  }

  render(){
    const { classes, cohorts } = this.props;
    return (
      <div className={classes.inviteContainer}>
        <form className={classes.cohortMenu} onSubmit={(event) => this.handleClickAdd(event)}>
            <h4>Add Patient</h4>

              <div className={classes.cohortMenuFields}>
              {this.props.studyType != 'RTM' &&
                <TextField
                  required
                  value={this.state.selected}
                  onChange={e => this.setState({selected: e.target.value})}
                  select // tell TextField to render select
                  margin="dense"
                  variant="outlined"
                  label="Cohort"
                  style={{ backgroundColor: 'white'}}
                  fullWidth
                >
                  {cohorts && cohorts.map((c, i) => {
                    return(
                      <MenuItem key={i} value={c}>{c.name}</MenuItem>
                    )
                  })}
                </TextField>
              }
              <TextField
                required
                id="firstname"
                name="firstname"
                label="First Name"
                margin="dense"
                variant="outlined"
                style={{ backgroundColor: 'white'}}
                value={this.state.first_name}
                onChange={e => this.changeFirstName(e.target.value)}
                fullWidth
              />
              <TextField
                required
                id="lastname"
                name="lastname"
                label="Last Name"
                margin="dense"
                variant="outlined"
                style={{ backgroundColor: 'white'}}
                value={this.state.last_name}
                onChange={e => this.changeLastName(e.target.value)}
                fullWidth
              />

              <TextField
                required
                value={this.state.gender}
                onChange={e => this.setState({gender: e.target.value})}
                select // tell TextField to render select
                margin="dense"
                variant="outlined"
                label="Gender"
                style={{ backgroundColor: 'white'}}
                fullWidth
              >
                {genders.map((g, i) => {
                  return(
                    <MenuItem key={i} value={g}>{g}</MenuItem>
                  )
                })}
              </TextField>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    onChange={(date) => this.onDateChange(date)}
                    value={this.state.dob}
                    fullWidth
                    format="MM/dd/yyyy"
                    id="dob"
                    label="Date of birth"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </MuiPickersUtilsProvider>


              {this.props.studyType != 'RTM' &&
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="Invite to patient app"
                    control={
                      <Switch
                        checked={this.state.invite_to_app}
                        onChange={(e) => this.setState({invite_to_app: e.target.checked})}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={<Typography className={classes.inviteSwitchLabel}>Invite to patient app</Typography>}
                    labelPlacement="end"
                  />
                </FormControl>}

              <TextField
                required={this.state.invite_to_app}
                disabled={!this.state.invite_to_app}
                id="email"
                name="email"
                label="Email"
                margin="dense"
                variant="outlined"
                style={{ backgroundColor: 'white'}}
                value={this.state.email}
                onChange={e => this.setState({email: e.target.value})}
                fullWidth
              />
              </div>
              <Button
                variant="contained"
                size="small"
                type="submit"
                color="secondary"
                style={{color: 'white', textTransform: 'none', marginTop: 10}}
              >
                {this.state.invite_to_app ? 'Add and Invite Patient' : 'Add Patient'}
              </Button>

        </form>


        <div className={classes.patientEmailContainer}>
          <div className={classes.inviteHeader}>
            {/*cohorts && (cohorts.length>0) && (this.state.selected != null) && cohorts[this.state.selected].name*/}

            {/*<div className={classes.inviteInputContainer} onClick={() => this.checkCohortSelected()}>
              <div className={classes.inviteInputLabel}>Patient Email: </div>
              <TextField
                disabled={!this.state.selected}
                id="name"
                name="name"
                maxRows={1}
                margin="dense"
                value={this.state.email}
                style={{ margin: 1, marginLeft: 10, marginRight: 10}}
                size="small"
                InputProps={{
                  shrink: true,
                  style: {
                    fontSize: 12
                  },
                  startAdornment: <InputAdornment position="start">
                      <EmailIcon fontSize="small" style={{color:"lightgrey"}}/>
                    </InputAdornment>
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.setState({email: e.target.value})}
              />
              <div className={classes.inviteInputLabel}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{color: 'grey', textTransform: 'none', backgroundColor: '#f5f5f5'}}
                  onClick={this.handleClickAdd.bind(this)}>
                  Add
                </Button>
              </div>
            </div>*/}
            <div className={classes.row}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                noClick
                noDrag
                onRemoveFile={this.handleOnRemoveFile}
              >
                {({ file }) => (

                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      style={{color: 'white', textTransform: 'none'}}
                      onClick={this.handleOpenDialog}
                    >
                      Import from CSV
                    </Button>
                )}
              </CSVReader>
              {this.props.controls &&
                <Button
                  disabled={this.checkInviteDisabled()}
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{color: 'white', textTransform: 'none', marginLeft: 10}}
                  onClick={this.handleInviteAll}
                >
                  Invite All
                </Button>
              }
            </div>
          </div>
          <div className={classes.patientEmailContainer2}>
          {(Object.keys(this.state.patients).length>0) &&
            <>
            <Tabs
              className={classes.tabs}
              variant="scrollable"
              value={this.state.value}
              onChange={(e, val) => this.changeValue(val)}
              indicatorColor="primary"
              aria-label="Vertical tabs example"
            >
              {
                this.props.cohorts.map((c, i) => (
                  <Tab key={i} label={"cohort: " + c.name} {...a11yProps(i+9)} />
                ))
              }
            </Tabs>
            <Divider />
            <TabPanel {...this.props} value={this.state.value} index={this.state.value}>
              <List dense={true}>
                {this.state.selected && (Object.keys(this.state.patients).length>0) && this.state.patients[Object.keys(this.state.patients)[this.state.value]].map((p,index) => (
                  <React.Fragment key={index}>

                  <ListItem>
                    <ListItemText
                      primary={p.first_name + " " + p.last_name}
                      secondary={p.email}
                    />
                    <ListItemSecondaryAction>
                      {p.type == 'platform' ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Chip size="small" label="patient app" />
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon fontSize="small" style={{color:"lightgrey"}} onClick={() => this.handleDeleteEmail(index)}/>
                          </IconButton>
                        </div>
                        :
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon fontSize="small" style={{color:"lightgrey"}} onClick={() => this.handleDeleteEmail(index)}/>
                        </IconButton>
                      }
                    </ListItemSecondaryAction>
                  </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </TabPanel>
          </>}
          </div>
        </div>

      </div>
    );
  }

}

export default withStyles(useStyles)(Invite);
