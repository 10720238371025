import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip
} from "@material-ui/core";
import classnames from "classnames";
import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
// import AddVideo from './AddVideo';
import AddProduct from './AddProduct';
// styles
import useStyles from "./styles";
import { get_upload_url, upload_video, get_video } from '../../api/video';
import { create_treatment, get_treatment, get_treatments } from '../../api/treatment';
import { useUserState } from "../../context/UserContext";
import { useProductState } from "../../context/ProductContext";

// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

import RBAC from '../../components/RBAC';

const support_options = [
  {name: 'treatment'},
  {name: 'reports'},
  {name: 'billing and payment'},
  {name: 'account'},
  {name: 'other'}
]

const styles = theme => ({
  input: {
    height: 40
  },
  button: {
    height: 40
  },
  selectRoot: {
    height: 40,
    display: "table"
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    display: "table-cell",
    verticalAlign: "middle"
  }
});

export default function Support(props) {
  var classes = useStyles();
  var prodState = useProductState().state;
  const treatments = prodState && prodState.products;

  const [title, setTitle] = useState(0);
  const [orgInfo, setOrgInfo] = useState(null);
  const [body, setBody] = useState('');
  const [cat, setCat] = useState('');
  // const [treatments, setTreatments] = useState([]);
  var [openAdd, setOpenAdd] = useState(false);
  var [THUMB_JWT, setThumbJWT] = useState(null);
  var [VIDEO_JWT, setVideoJWT] = useState(null);
  var [TEMP_PLAYBACK_ID, setTEMP_PLAYBACK_ID] = useState(null);
  var {state, dispatch} = useUserState();

  const handleChange = (event, newValue) => {
  };

  async function prepare_upload_url() {
    const resp = await get_upload_url();
    if(resp) {
      // let temp = JSON.parse(resp)
    } else {
      console.warn("not success");
    }
  }

  async function upload() {
    const resp = await upload_video();
    if(resp) {
      // let temp = JSON.parse(resp)
    } else {
      console.warn("not success");
    }
  }

  async function get_video_asset() {
    const resp = await get_video();
    if(resp) {
      // let temp = JSON.parse(resp)
      setTEMP_PLAYBACK_ID(resp.playback_id)
      setVideoJWT(resp.video_jwt)
      setThumbJWT(resp.thumbnail_jwt)
    } else {
      console.warn("not success");
    }
  }

  function handleClickTreatment(item) {
    props.history.push("/treatments/"+item.id)
  }

  function handleClickAdd() {
    setOpenAdd(true)
  }

  // async function getTreatments() {
  //   const treatments = await get_treatments();
  //   console.warn("treatments", treatments)
  //   setTreatments(treatments)
  // }
  useEffect(() => {
    // prepare_upload_url()
    // getTreatments()
    // get_video_asset()
  }, [prodState])

  const disabled = !body || !cat;
  return (
      <div className={classes.root}>

        <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
          <div className={classes.addBtn}>
            <Button
              onClick={handleClickAdd}
              variant="contained"
              style={{ textTransform: 'none', color: 'white'}}
              color="primary">
              Add Treatment
            </Button>
          </div>
        </RBAC>

        <Grid container spacing={4} className={classes.productGrid}>
          {treatments.map((item, index) => {
            return(
              <Grid key={index} item lg={4} md={6} sm={6} xs={12}
                onClick={() => {handleClickTreatment(item)}}>
                <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                  <div className={classes.cardHeader}>
                    <div>{item.community && item.community.name}</div>
                    {item.premium ?
                      <div className={classes.premiumText}>premium</div>
                      :
                      null
                    }
                    {item.pending ?
                      <div className={classes.pendingText}>pending</div>
                      :
                      null
                    }
                  </div>
                  <Divider />
                  <div className={classes.cardBody}>
                    <Grid
                      container
                      direction="row"
                      justifycontent="center"
                      spacing={2}
                    >
                      <Grid item sm={5} xs={12}>
                        <div className={classes.videoContainer}>
                          <img src={(item.images && item.images.length > 0) ? item.images[0] : ''} className={classes.productImage}/>
                        </div>
                      </Grid>
                      <Grid item sm={7}>
                        <div className={classes.videoDesc}>
                          <div className={classes.videoTitle}>{item.name}</div>
                          {/*<div className={classes.dateContent}>
                            created: {item.date}
                          </div>*/}
                          <span className={classes.cardDescription}>{item.description}</span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.categories}>
                      {item.categories.map((cat, i) =>
                        <Chip key={i} style={{marginRight: 3}} label={cat} size="small"/>
                      )}
                  </div>
                  <RBAC allowedRoles={["demo"]}>
                    <div className={classes.cardFooter}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div className={classes.viewsContainer}>
                          <ViewIcon style={{ fontSize: 14, color: "grey"}}/>
                          <div className={classes.viewCount}>2</div>
                          studies
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.viewsContainer}>
                          <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                          <div className={classes.viewCount}>116</div>
                          patients
                        </div>
                      </Grid>
                    </Grid>
                    </div>
                  </RBAC>
                </Paper>
              </Grid>
            )}
          )}
          {/*mock.videos.map((item, index) => {
            return(
              <Grid key={index} item lg={4} md={6} sm={6} xs={12}
                onClick={() => {onChangeType(item)}}>
                <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                  <div className={classes.cardHeader}>
                    <div>{item.community}</div>
                    {item.premium ?
                      <div className={classes.premiumText}>premium</div>
                      :
                      null
                    }
                    {item.pending ?
                      <div className={classes.pendingText}>pending</div>
                      :
                      null
                    }
                  </div>
                  <Divider />
                  <div className={classes.cardBody}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item sm={5} xs={12}>
                        <div className={classes.videoContainer}>
                          {THUMB_JWT && VIDEO_JWT && TEMP_PLAYBACK_ID &&
                            <video
                                id="my-player"
                                class="video-js"
                                controls
                                width="100%"
                                preload="none"
                                poster={`https://image.mux.com/${TEMP_PLAYBACK_ID}/thumbnail.png?token=${THUMB_JWT}&width=214&height=121&fit_mode=pad`}
                                data-setup='{}'>
                              <source src={`https://stream.mux.com/${TEMP_PLAYBACK_ID}.m3u8?token=${VIDEO_JWT}`} type="video/mp4"></source>
                              <p class="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a
                                web browser that
                                <a href="https://videojs.com/html5-video-support/" target="_blank">
                                  supports HTML5 video
                                </a>
                              </p>
                            </video>
                          }
                        </div>
                      </Grid>
                      <Grid item sm={7}>
                        <div className={classes.videoDesc}>
                          <div className={classes.videoTitle}>{item.title}</div>
                          <div className={classes.dateContent}>
                            uploaded: {item.date}
                          </div>
                          <div className={classes.videoDuration}>
                            28 min
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <div className={classes.viewsContainer}>
                            <ViewIcon style={{ fontSize: 14, color: "grey"}}/>
                            <div className={classes.viewCount}>{item.views}</div>
                            views
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.viewsContainer}>
                            <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                            <div className={classes.viewCount}>{item.supporters}</div>
                            supporters
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            )}
          )*/}
        </Grid>

        <Dialog
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          aria-labelledby="product-dialog-title"
          aria-describedby="product-dialog-description"
        >
          <AddProduct onFinished={() => setOpenAdd(false)}/>
        </Dialog>

      </div>
  );

}
