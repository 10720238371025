import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Checkbox,
  Box
} from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classnames from "classnames";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

//
// function SimpleQuestion(props) {
//   var classes = useStyles();
//   const { children, value, index, ...other } = props;
//
//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       className={classes.tab}
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }
//
// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

class CheckBoxQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: (props.options && props.options.length > 0) ? props.options : [""],
      title: props.title,
      selectedOptions: new Array(props.options && props.options.length).fill(false)
    };
  }
  //
  // var classes = useStyles();
  //
  // const [title, setTitle] = useState(0);
  // const [options, setOptions] = useState(null);
  //
  //
  componentDidMount() {
    if (this.props.options && this.props.answer) {
      let selected = this.state.selectedOptions;
      let resp = [];
      for (let i=0; i < this.props.options.length; i++) {
        resp.push({option: this.props.options[i], selected: selected[i]})
      }
      this.props.onDataChange({value: resp})
    }
  }

  handleChange(index, newValue) {
    let selected = this.state.selectedOptions;
    selected[index] = newValue
    this.setState({selectedOptions:selected})
    if (this.props.answer) {
      let resp = [];
      for (let i=0; i < this.props.options.length; i++) {
        resp.push({option: this.props.options[i], selected: selected[i]})
      }
      this.props.onDataChange({value: resp})
    }
  };


  updateQuestionData() {
    const {questions, title} = this.state;

    this.props.onDataChange({
      'title': title,
      'options': questions
    })
  }

  changeTitle(val) {
    this.setState({title: val}, () => {
      this.updateQuestionData()
    })
  }

  changeCheckBoxLabel(index, val) {
    let questions = this.state.questions;
    // let question = questions[index];
    questions[index] = val
    this.setState({questions}, () => {
      this.updateQuestionData()
    })
  }

  onClickAdd() {
    let questions = this.state.questions;
    questions = questions.concat('')
    this.setState({questions}, () => {
      this.updateQuestionData()
    })
  }

  removeCheckBox(index) {
    let questions = this.state.questions;
    questions[index] = ''
    if (index > -1) {
      questions.splice(index, 1);
    }
    this.setState({questions}, () => {
      this.updateQuestionData()
    })
  }

  render () {
    const { classes, answer, optional } = this.props;
    return (
      <div className={classes.questionModuleContainer}>
        <fieldset disabled={this.props.disabled} style={{border: 0}}>
        <TextField
          required={!answer}
          disabled={answer}
          id="name"
          name="name"
          value={this.state.title}
          label="question"
          margin="dense"
          fullWidth
          multiline
          variant="outlined"
          maxrows={4}
          onChange={e => this.changeTitle(e.target.value)}
        />

        {!answer &&
          <Button
            variant="contained"
            size="small"
            style={{marginTop: '20px', textTransform: 'none'}}
            onClick={() => this.onClickAdd()}
          >
            Add Option
          </Button>
        }

        <FormControl required={answer && !optional} component="fieldset" className={classes.formControl}>
          {answer && <FormLabel component="legend">Select one at least</FormLabel>}
          <FormGroup>
            {this.state.questions.map((q,i) => (
              <div key={i} className={classes.checkboxRow}>
                <FormControlLabel
                  fontSize="small"
                  control={<Checkbox margin="dense" fontSize="small" color="primary" name={i} onChange={(e, val) => this.handleChange(i, val)}/>}
                  label={<TextField
                    required={!answer}
                    disabled={answer}
                    id={"check_"+i}
                    value={q}
                    placeholder="new option"
                    name="checkbox"
                    margin="dense"
                    onChange={e => this.changeCheckBoxLabel(i, e.target.value)}/>
                  }
                />
                {!answer &&
                <DeleteForeverIcon style={{ color: '#D11A2A' }} onClick={() => this.removeCheckBox(i)}/>}
              </div>
            ))}
          </FormGroup>
        </FormControl>
        </fieldset>
      </div>
    );
  }


}

export default withStyles(useStyles)(CheckBoxQuestion);
