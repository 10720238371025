import React, { useState, useEffect } from "react";
import {CardElement} from '@stripe/react-stripe-js';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Link,
  Tabs,
  Tab,
  TextField,
  Fade,
  Divider,
  Modal,
  Chip,
  Paper
} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import useStyles from "./styles";
import MaskedInput from 'react-text-mask';
import MoreInfo from '../../components/Dialog';
import { BootstrapInput } from '../../components/BootstrapInput';
import Widget from '../../components/Widget';
import MyStoreCheckout from './MyStoreCheckout';
import PickPlans from './PickPlans';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { get_portal } from '../../api/stripe';

// import { Button } from "@material-ui/core";

// class CardSection extends React.Component {
//   render() {
//     return (
//       <label>
//         Card details
//         <CardElement style={{base: {fontSize: '18px'}}} />
//       </label>
//     );
//   }
// }

const payment_methods = [
  {
    name: 'bank'
  },
  {
    name: 'debit'
  }
]
const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

function PlanCard(props) {
  var classes = useStyles();

  const { title, value, trial } = props;

  return (
    <div className={classes.planDetailsCard}>
      <Typography variant="body1" gutterBottom weight="medium" color="textSecondary">
        {title}
      </Typography>
      <div>
        <Typography variant="body" gutterBottom weight="medium">
          {value}
        </Typography>
        {trial && <Chip variant="outlined" size="small" label="trial" style={{marginLeft: 5, color:"#9013FE", borderColor:"#9013FE"}}/>}
      </div>
    </div>
  );
}

export default function PhysicianPlan(props) {
  // handleSubmit = (ev) => {
  //   ev.preventDefault();
  //   if (this.props.stripe) {
  //     this.props.stripe
  //       .createToken()
  //       .then((payload) => console.log('[token]', payload));
  //   } else {
  //     console.log("Stripe.js hasn't loaded yet.");
  //   }
  // };

  var classes = useStyles();
  const info = props.org
  const profile = props.profile
  const customer_id = (profile && profile.payment) ? profile.payment.customer_id : 'cus_JhGI1aL3bX08eH';

  const [isLoading, setIsLoading] = useState(true);
  const [openPayment, setOpenPayment] = useState(false);
  const [openPlans, setOpenPlans] = useState(false);
  const [method, setMethod] = useState('');

  useEffect(() => {
      if(info) {
        setIsLoading(false)
      }
  }, [info])

  const handleChangePaymentMethod = (event) => {
    setMethod(event.target.value);
  };

  // Create a Session for a user
  async function createSession () {
    try{
      const resp = await get_portal();
      if (resp) {
        // const data = await resp.json();
        if(resp.portal_url) {
          window.location.href = resp.portal_url;
        }
      }
    } catch(e) {

    }

  };

  return (
    <div>
      <form className={classes.infoForm}>

        <div className={classes.sectionHeader}>
          <Typography variant="h6" size="xl" weight="medium" className={classes.planHeader}>
            Plan Details
          </Typography>
        </div>

        {info && info.subscription &&
          <div>
            {info.subscription.plans.map((p) => (
              <Paper classes={{ root: classes.paperRoot }}>
              <div className={classes.planDetailsContainer}>
              <PlanCard title={"Plan"} value={p.name} trial={p.trial}/>
              <PlanCard title={"Monthly Cost"} value={"$"+p.price} />
              </div>
              </Paper>
            ))}

            {/*<PlanCard title={"Start date:"} value={"01/01/2021"} />*/}
          </div>
        }

        {/*
        <div className={classes.usageContainer}>
          <div className={classes.planUsageText}>
            <InputLabel shrink htmlFor="usage">
              plan usage
            </InputLabel>
            <InputLabel shrink htmlFor="usage">
              140/200
            </InputLabel>
          </div>
          <BorderLinearProgress variant="determinate" color="primary" value={70} />
        </div>

        <Divider className={classes.divider}  />

        <div className={classes.sectionHeader}>
          <Typography variant="h6" size="xl" weight="medium" className={classes.planHeader}>
            Payment
          </Typography>

          <div className={classes.changePlanLink}>
            <Link
              component="button"
              variant="body2"
              color="primary"
              onClick={(event)=>{event.preventDefault();setOpenPayment(true);}}
            >
              Change Method
            </Link>
          </div>
        </div>*/}



        {/*<div className={classes.saveBtn} >
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'none', color: 'white'}}
            onClick={() => createSession()}>
            Billing Portal
          </Button>
        </div>*/}

        <div className={classes.saveBtn}>
          <Link
            component="button"
            variant="body2"
            color="primary"
            onClick={(event)=>{event.preventDefault();createSession();}}
          >
            Billing Portal
          </Link>
        </div>

      </form>

      <Dialog open={openPayment} aria-labelledby="form-dialog-title">
        <DialogContent>
          <div className={classes.selecMethodContainer}>
          <DialogContentText>
            Select a payment method from the following available options:
          </DialogContentText>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <RadioGroup row aria-label="method" name="method" value={method} onChange={handleChangePaymentMethod}>
                <FormControlLabel value="Bank Info" control={<Radio color="primary"/>} label="Bank Info" />
                <FormControlLabel value="Credit/Debit" control={<Radio color="primary"/>} label="Credit/Debit" />
              </RadioGroup>
            </Grid>
          </Grid>

          {(method=='Credit/Debit') ?
            <MyStoreCheckout />
            :
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="bank-account">
                  Account Number
                </InputLabel>
                <BootstrapInput
                  type="number"
                  label="Account Number"
                  placeholder="Account Number"
                  className={classes.textField}
                  margin="dense"
                  value={null}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="bank-routing">
                  Routing Number
                </InputLabel>
                <BootstrapInput
                  label="Routing Number"
                  placeholder="Routing Number"
                  className={classes.textField}
                  margin="dense"
                  value={null}
                />
              </FormControl>
            </FormGroup>
          }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: 'none'}}
            onClick={()=>{ setOpenPayment(false) }}
            size="small"
            color="primary">
            Cancel
          </Button>
          <Button
            style={{ textTransform: 'none'}}
            onClick={()=>{ setOpenPayment(false) }}
            color="primary"
            variant="contained"
            size="small">
            Select
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openPlans}
        onClose={() => setOpenPlans(false)}
        className={classes.planModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PickPlans onClose={() => setOpenPlans(false)}/>
      </Modal>
    </div>
  );
}
