import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import { renderActiveShape } from '../charts/Charts';

import { useProductState } from "../../context/ProductContext";


export default function PickProduct(props) {
  var classes = useStyles();
  var theme = useTheme();
  var {state, dispatch} = useProductState();

  // local
  var [activeIndex, setActiveIndexId] = useState(0);
  var array = (state.products) ? state.products : []
  console.warn(array)

  return (
      <div className={classes.productContainer}>
      <PageTitle title="Select your product"  />
      <Grid container spacing={4} className={classes.productGrid} >

      {array.map((item, index) => {
        return(
          <Grid key={index} item lg={4} md={5} sm={8} xs={12}
          onClick={() => {selectProduct(item)}}>
            <Widget
              title={item.name}
              upperTitle
              className={classes.card}
              disableWidgetMenu={true}
            >
              <div>
                <img src={(item.images && item.images.length > 0) ? item.images[0] : ''} className={classes.productImage}/>
              </div>
            </Widget>
          </Grid>
        )}
      )}
      </Grid>
      </div>
  );

  function selectProduct(product){
    dispatch({type: "SELECT_PRODUCT", data: product})
    let product_url = product ? "/dashboard/" + product.id : "/dashboard"
    props.history.push(product_url);
  }
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
