import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import BasicInfo from './BasicInfo';
import SurveyInfo from './SurveyInfo';
import ReviewStep from './ReviewStep';
import Description from './Description';
import SurveyDesigner from './SurveyDesigner';
import { DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import Invite from './Invite';
import { create_study, invite_patient_to_study, add_shadow_patient_to_study, get_therapies } from '../../api/study';
import { create_product } from '../../api/stripe';
import { handleNotification } from '../notifications/NotificationsClient';


// import FDAinfo from './FDAinfo';
// import SEInfo from './SEInfo';
// import useStyles from "./styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  designerDiv: {
    height: '75vh',
    maxHeight: '75vh',
  },
  layout: {
    width: '100%',
    position: 'relative'
  },
  topPadding: {
    paddingTop: theme.spacing(4)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  stepper: {
    backgroundColor: 'transparent',
    borderRadius: '3px',
    border: "1px solid lightgrey",
    padding: theme.spacing(3, 0, 3),
  },
  controlBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function getSteps(type) {
  if (type == 'RTM') {
    return ['RTM basics', 'Design Survey Questions', 'Review', 'Invite Patients'];
  } else {
    return ['Define Cohorts', 'Design Survey Questions', 'Review', 'Invite Patients'];
  }
}


export default function StudyDesign(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [openAdd, setOpenAdd] = useState(false);
  const [cohorts, setCohorts] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [initQuestions, setInitQuestions] = useState([]);
  const [endQuestions, setEndQuestions] = useState([]);
  const [submitedStudyID, setSubmitedStudyID] = useState('');
  // for step 1:
  const [community, setCommunity] = useState('');
  const [studyName, setStudyName] = useState('');
  const [surveyID, setSurveyID] = useState(null);
  const [surveyName, setSurveyName] = useState('');
  const [initSurveyID, setInitSurveyID] = useState(null);
  const [initSurveyName, setInitSurveyName] = useState('');
  const [endSurveyID, setEndSurveyID] = useState(null);
  const [endSurveyName, setEndSurveyName] = useState('');
  const [clinicalGroups, setClinicalGroups] = useState([]);
  const [prodType, setProdType] = useState('');
  const [treatment, setTreatment] = useState(null);
  const [prodImage, setProdImage] = useState('');
  const [expected, setExpected] = useState(null);
  const [YTURL, setYTURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [hiw, setHiw] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCats, setSelectedCats] = useState(null);
  const [metricEnabled, setMetricEnabled] = useState(false);
  const [size, setSize] = useState(0);
  const [patients, setPatients] = useState({});
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [therapies, setTherapies] = useState([]);
  // for step 2:

  const steps = getSteps(props.type);

  function prepareStudyData() {
    let payload = {};
    let survey = null;
    let initial_survey = null;
    let final_survey = null;
    let payload_questions = [];
    let payload_init_questions = [];
    let payload_end_questions = [];
    let payload_cohorts = []
    payload.name = studyName;
    payload.treatment_id = treatment.id;
    if (surveyID) {
      survey = {id: surveyID};
    } else {
      survey = {}
      survey.name = surveyName;
      for (let i=0; i < questions.length; i++) {
        const q = questions[i];
        let d = {}
        d.type = q.type.toLowerCase();
        d.title = q.title;
        d.rank = i+1;
        d.options = q.options;
        d.optional = q.optional || false;
        if (d.type=='scale') {
          d.min_label = q.min_label;
          d.max_label = q.max_label;
        }
        payload_questions.push(d);
      }
      survey.questions = payload_questions;
    }
    if (initSurveyID) {
      initial_survey = {id: initSurveyID};
    } else if (initSurveyName && (initQuestions.length > 0))  {
      initial_survey = {}
      initial_survey.name = initSurveyName;
      initial_survey.description = description || ' ';
      for (let i=0; i < initQuestions.length; i++) {
        const q = initQuestions[i];
        let d = {}
        d.type = q.type.toLowerCase();
        d.title = q.title;
        d.rank = i+1;
        d.options = q.options;
        d.optional = q.optional || false;
        if (d.type=='scale') {
          d.min_label = q.min_label;
          d.max_label = q.max_label;
        }
        payload_init_questions.push(d);
      }
      initial_survey.questions = payload_init_questions;
    }

    if (endSurveyID) {
      final_survey = {id: endSurveyID};
    } else if (endSurveyName && (endQuestions.length > 0))  {
      final_survey = {}
      final_survey.name = endSurveyName;
      final_survey.description = description || ' ';
      for (let i=0; i < endQuestions.length; i++) {
        const q = endQuestions[i];
        let d = {}
        d.type = q.type.toLowerCase();
        d.title = q.title;
        d.rank = i+1;
        d.options = q.options;
        d.optional = q.optional || false;
        if (d.type=='scale') {
          d.min_label = q.min_label;
          d.max_label = q.max_label;
        }
        payload_end_questions.push(d);
      }
      final_survey.questions = payload_end_questions;
    }
    payload.survey = survey;
    payload.initial_survey = initial_survey;
    payload.final_survey = final_survey;
    payload.cohorts = cohorts;
    if (metricEnabled && expected && size) {
      payload.expected_onboarding_end_date = expected;
      payload.size = size;
    }

    let clinicalPayload = clinicalGroups
    for (let i=0; i < clinicalGroups.length; i++) {
      let group = clinicalGroups[i]
      for (let j=0; j < group.longitudinal_survey.questions.length; j++) {
        let q = group.longitudinal_survey.questions[j];
        q.type = q.type.toLowerCase();
        q.rank = j+1;
        q.optional = q.optional || false;
        if (q.type=='scale') {
          q.min_label = q.min_label;
          q.max_label = q.max_label;
        }
        clinicalPayload[i].longitudinal_survey.questions[j] = q
      }

      if (group.initial_survey.questions.length > 0) {
        for (let j=0; j < group.initial_survey.questions.length; j++) {
          let q = group.initial_survey.questions[j];
          q.type = q.type.toLowerCase();
          q.rank = j+1;
          q.optional = q.optional || false;
          if (q.type=='scale') {
            q.min_label = q.min_label;
            q.max_label = q.max_label;
          }
          clinicalPayload[i].initial_survey.questions[j] = q
        }
      } else {
        delete clinicalPayload[i].initial_survey
      }

      if (group.final_survey.questions.length > 0) {
        for (let j=0; j < group.final_survey.questions.length; j++) {
          let q = group.final_survey.questions[j];
          q.type = q.type.toLowerCase();
          q.rank = j+1;
          q.optional = q.optional || false;
          if (q.type=='scale') {
            q.min_label = q.min_label;
            q.max_label = q.max_label;
          }
          clinicalPayload[i].final_survey.questions[j] = q
        }
      } else {
        delete clinicalPayload[i].final_survey
      }

    }
    payload.clinical_study_investigator_groups = clinicalPayload
    payload.study_type = (props.type == 'RTM') ? 'REMOTE_THERAPEUTIC_MONITORING' : 'RESEARCH'
    return payload;
  }

  const isStepOptional = step => {
    return step === 5;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if ( activeStep === steps.length - 2) { // if in the create step
      setCreating(true)
      const resp = await create_study(prepareStudyData());
      setCreating(false)
      if (resp && resp.id) {
        setCohorts(resp.cohorts)
        setSubmitedStudyID(resp.id)
        handleNotification("study create success")
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        handleNotification("study create fail")
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    if (activeStep == (steps.length - 1)) {
      var empty = true
      for (var coh in patients) {
        if (patients[coh].length > 0) {
          empty = false
          break
        }
      }
      if (!empty) {
        setOpen(true)
      } else {
        goToStudy()
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleDiscard = () => {
    props.history.push("/study");
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  async function goToStudy() {
    if (props.type == 'RTM') {
      props.history.push("/rtm/" + submitedStudyID);
    } else {
      props.history.push("/study/" + submitedStudyID);
    }
  }

  async function addShadowUser(cohort, data) {
    const resp = await add_shadow_patient_to_study(cohort, {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
    });
    if (resp) {
      handleNotification("patient added to study")
    }
  }

  async function invitePatients(data) {

    for (var coh in data) {
      let list = data[coh];
      let payload = []
      for (let i=0; i < list.length; i++) {
        if (list[i].email) {
          payload.push({
            'private': true,
            'email': list[i].email,
            'first_name': list[i].first_name,
            'last_name': list[i].last_name,
            'date_of_birth': list[i].date_of_birth,
            'gender': list[i].gender,
            'type': 'clinical_study'
          })
        } else {
          addShadowUser(coh, list[i])
        }
      }
      if (payload.length == 0) {
        continue
      }
      const resp = await invite_patient_to_study(coh, {'invites': payload});
      if (resp) {
        handleNotification("patient invite success");
      }
    }
    goToStudy()
  }

  async function init() {
    const trps = await get_therapies();
    console.warn("get_therapies",trps)
    setTherapies(trps)
  }

  useEffect(() => {
    init()
  }, [])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicInfo
            studyName={studyName}
            studyType={props.type}
            treatment={treatment}
            community={community}
            cohorts={cohorts}
            therapies={therapies}
            onClickedAddSurvey={() => setOpenAdd(true)}
            onChangeTreatment={(t) => setTreatment(t)}
            onStudyNameChange={(name) => setStudyName(name)}
            onCohortChange={(cohortData) => setCohorts(cohortData)}
            onTreatmentChanged={(treatment) => setTreatment(treatment)}
            onExpectedChange={(date) => setExpected(date)}
            onMetricChanged={(e) => setMetricEnabled(e)}
            onSizeChanged={(n) => setSize(n)}
            />;
        // return <div className={classes.designerDiv}><SurveyDesigner community={community}/></div>;
      case 1:
        return <SurveyInfo
            clinicalGroups={clinicalGroups}
            studyType={props.type}
            questions={questions}
            initQuestions={initQuestions}
            endQuestions={endQuestions}
            surveyName={surveyName}
            initSurveyName={initSurveyName}
            endSurveyName={endSurveyName}
            surveySelected={surveyID}
            initSurveySelected={initSurveyID}
            endSurveySelected={endSurveyID}
            onQuestionUpdate={(questions) => setQuestions(questions)}
            onSurveyNameUpdate={(name) => setSurveyName(name)}
            onSurveySelected={(survey) => setSurveyID(survey)}
            onInitQuestionUpdate={(questions) => setInitQuestions(questions)}
            onInitSurveyNameUpdate={(name) => setInitSurveyName(name)}
            onInitSurveySelected={(survey) => setInitSurveyID(survey)}
            onEndQuestionUpdate={(questions) => setEndQuestions(questions)}
            onEndSurveyNameUpdate={(name) => setEndSurveyName(name)}
            onEndSurveySelected={(survey) => setEndSurveyID(survey)}
            onClinicalGroups={(groups) => setClinicalGroups(groups)}
            onFinished={() => setOpenAdd(false)}/>
      // case 1:
      //   return <SurveyDesigner
      //       questions={questions}
      //       onQuestionUpdate={(questions) => setQuestions(questions)}
      //       onSurveyNameUpdate={(name) => setSurveyName(name)}
      //       onSurveySelected={(survey) => setSurveyID(survey.id)}
      //       onFinished={() => setOpenAdd(false)}/>
      case 2:
        return <ReviewStep
            studyName={studyName}
            studyType={props.type}
            questions={questions}
            initQuestions={initQuestions}
            endQuestions={endQuestions}
            treatment={treatment}
            clinicalGroups={clinicalGroups}
            cohorts={cohorts}/>
      case 3:
        return <Invite
            studyName={studyName}
            questions={questions}
            studyType={props.type}
            treatment={treatment}
            cohorts={cohorts}
            controls={true}
            updatePatients={(p) => setPatients(p)}
            onSubmit={(data) => invitePatients(data)}/>
      default:
        return 'invite step';
    }
  }

  const isDisabled = (step) => {
    switch (step) {
      case 0:
        if (cohorts.length == 0 || !treatment || studyName=='')
          return true
        else {
            // check for cohort
            for (let i=0; i < cohorts.length; i++) {
              let c = cohorts[i]
              if (!c.name || !c.description || !c.notes) {
                return true
              }
            }
            return false
        }
      case 1:
        return ((questions.length == 0 || !surveyName) && !surveyID);
      case 2:
        return false;
      default:
        return false;
    }
  }

  return (
    <React.Fragment>
    <div className={classes.layout}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              You can invite more patients and other physicians to help you with your study under the study management tab!
            </Typography>
            <Button variant="contained" color="primary" onClick={() => goToStudy()} className={classes.button}>
              Ok
            </Button>
          </div>
        ) : (
          <div  className={classes.topPadding}>
            {getStepContent(activeStep)}
            <div className={classes.controlBtns}>
              <Button
                disabled={activeStep === 0}
                size="small"
                variant={activeStep === steps.length - 1 ? "contained" : "text"}
                style={{textTransform: 'none'}}
                onClick={handleBack}
                className={classes.button}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Back'}
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  size="small"
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              {(activeStep !== steps.length - 1) &&
                <Button
                  disabled={creating}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleNext}
                  style={{ color: 'white', textTransform: 'none'}}
                  disabled={isDisabled(activeStep)}
                  className={classes.button}
                >
                  {activeStep === steps.length - 2 ? 'Create' : 'Next'}
                </Button>
              }
            </div>
          </div>
        )}
      </div>
    </div>

      {/*<Dialog
        maxWidth={"lg"}
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        aria-labelledby="product-dialog-title"
        aria-describedby="product-dialog-description"
      >
        <div className={classes.designerDiv}>
          <SurveyDesigner
            questions={questions}
            onQuestionUpdate={(questions) => setQuestions(questions)}
            onFinished={() => setOpenAdd(false)}/>
        </div>
      </Dialog>*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"You have pending patient invitations. Click on Invite All to send invitations."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to discard invitations?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{textTransform: 'none'}}>
            No
          </Button>
          <Button onClick={handleDiscard} style={{textTransform: 'none', color: 'red'}}  autoFocus>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
