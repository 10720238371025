import * as urls from './study-urls';
import { apiClient } from './apiClient';


export async function get_study(id, dispatch=null) {
  let url = urls.STUDY() + '/' + id;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return {
    data: response.data,
    headers: response.headers
  }
}

export async function get_study_summary(id, dispatch=null) {
  let url = urls.STUDY() + '/' + id + '/summary';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return {
    data: response.data,
    headers: response.headers
  }
}

export async function get_studies(dispatch=null) {
  let url = urls.STUDY();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_rtms(dispatch=null) {
  let url = urls.STUDY() + '?study_type=REMOTE_THERAPEUTIC_MONITORING';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_reports(id, start=null, end=null, dispatch=null) {
  let params = {}
  if (start) params['start_date'] = start
  if (end) params['end_date'] = end

  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

  let url = urls.PATIENT(id) + 'analytics?' + query
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function create_study(data, dispatch=null) {
  let url = urls.STUDY();
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function update_study(data, id, dispatch=null) {
  let url = urls.STUDY() + '/' + id ;
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function update_study_clinicalgroup(data, id, group_id, dispatch=null) {
  let url = urls.STUDY() + '/' + id + '/investigator_group/' + group_id;
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function stop_study(id, data, dispatch=null) {
  let url = urls.STUDY() + '/' + id;
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function get_survey(id, dispatch=null) {
  let url = urls.SURVEY() + '/' + id;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_surveys(dispatch=null) {
  let url = urls.SURVEY();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_therapies(community=null, dispatch=null) {
  let params = {}
  if (community) params['community'] = community

  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  let url = urls.THERAPY() + '?' + query
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_survey_result(id, dispatch=null) {
  let url = urls.SURVEY_RESULT() + '/' + id;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return {
    data: response.data,
    headers: response.headers
  }
}

export async function get_journey(id, dispatch=null) {
  let url = urls.JOURNEY(id)
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_journey_result(id, start=null, end=null, dispatch=null) {

  let params = {}
  if (start) params['start_date'] = start
  if (end) params['end_date'] = end

  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  let url = urls.JOURNEY(id) + '/journey_entries?' + query;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return {
    data: response.data,
    headers: response.headers
  }
}

export async function create_survey(data, dispatch=null) {
  let url = urls.SURVEY();
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function invite_patient_to_study(id, data, dispatch=null) {
  let url = urls.COHORT(id) + '/invitation';
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response.data
}

export async function add_patient_entry (id, data, dispatch=null) {
  let url = urls.PATIENT(id) + "entry"
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response
}

export async function add_shadow_patient_to_study (id, data, dispatch=null) {
  let url = urls.COHORT(id) + '/patient';
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response
}

export async function get_study_patients (id, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/patient';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_patient_journeys (id, dispatch=null) {
  let url = urls.PATIENT(id) + 'journeys';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function end_journey (data, id, dispatch=null) {
  let url = urls.JOURNEY(id);
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  return response
}

export async function get_studygroup (id, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/investigator_group';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_study_invitations(id, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/invitations';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_study_staff(id, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/user';
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function invite_staff_to_study(id, data, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/invitations';
  let response = await apiClient(
    url,
    'POST',
    data,
    {},
    dispatch
  )
  return response
}

export async function confirm_invite_to_study(id, inv_token, auth_token, dispatch=null) {
  let url = urls.STUDY() + "/" + id + '/invitations';
  let headers = auth_token ? {
      'Invitation-Token': inv_token,
      'Authorization': auth_token
    } :
    {
      'Invitation-Token': inv_token,
    };
  let response = await apiClient(
    url,
    'PATCH',
    {},
    headers,
    dispatch
  )
  return response
}

export async function get_study_analytics(id, type, rank, cohort=null, dispatch=null) {
  let params = {
    type: type,
    rank: rank,
  };
  if (cohort) {
    params['cohort_id'] = cohort
  }
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  let url = urls.STUDY_ANALYTICS(id) + '?' + query;
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return {
    data: response.data,
  }
}

// export async function update_orginfo(dispatch, data) {
//   let url = urls.ORG_INFO();
//   let response = await apiClient(
//     url,
//     'PUT',
//     data,
//     {},
//     dispatch
//   )
//   if (response.error) {
//     return null
//   } else {
//     return response.data;
//   }
// }
//
// export async function get_profile(dispatch) {
//   let url = urls.PROFILE();
//   let response = await apiClient(
//     url,
//     'GET',
//     {},
//     {},
//     dispatch
//   )
//   return response
// }
