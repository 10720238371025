import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import classnames from "classnames";
import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddStudy from './AddStudy';
// styles
import useStyles from "./styles";
import { withStyles } from '@material-ui/styles';
import { get_survey } from '../../api/study';
import { useUserState } from "../../context/UserContext";
import { handleNotification } from '../notifications/NotificationsClient';

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";
import SimpleQuestion from './SimpleQuestion';
import CheckBoxQuestion from './CheckBox';
import RadioQuestion from './RadioQuestion';
import ImageQuestion from './ImageQuestion';
import NumberQuestion from './NumberQuestion';
import TextQuestion from './TextQuestion';

const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});



function RenderQuestion(props){
  const question = props.question;
  const classes = useStyles();
  return (
    <div>
      <div className={classes.questionPreview}>
        <h4>{question.title}</h4>
      </div>
      {question.options &&
        <div className={classes.questionPreview}>
          <h4>options:</h4>
          {question.options.map((o, index) => (
            <p key={index}>{o}</p>
          ))}
        </div>
      }
    </div>
  )
}

export default function SurveyView(props)  {

  const classes = useStyles();

  const [survey, setSurvey] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);

  async function init() {
    const id = props.match.params.survey_id;
    const survey = await get_survey(id);
    setSurvey(survey);
  }




  useEffect(() => {
    init()
  }, [])

  return (
      <div className={classes.root}>
        {survey && survey.questions.map((question, index) => {
          return (
            <div key={index}>
              <h3>Question {index + 1}</h3>
              <RenderQuestion question={question}/>
            </div>
          )}
        )}
      </div>
  );

}
