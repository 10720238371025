import React, { useState, useEffect } from "react";
import {
  Grid
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./styles";
// import {useStripe} from '@stripe/react-stripe-js';
import {get_plans, create_checkout} from "../../api/stripe";
import './plan.css';

export default function PickPlans(props) {

  const [plans, setPlans] = useState([]);
  var classes = useStyles();

  async function onSelectPlan(id, trial, return_path) {

    const resp = await create_checkout(id, trial, return_path);
      // Call Stripe.js method to redirect to the new Checkout page
    if (resp) {
      console.warn("resp",resp)

      if (resp.status == 202) {
        props.onSuccessRefresh()
      } else {
        window.location.replace(resp.data.checkout_session_url)
      }
      // stripe
      //   .redirectToCheckout({
      //     sessionId: resp.id
      //   })
    }
  }

  function handleFetchResult (result) {
    if (!result.ok) {
      return result.json().then(function(json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function(err) {
        showErrorMessage(err);
        throw err;
      });
    }
    return result.json();
  };


  // get current plans
  async function getPlans () {
    const plans = await get_plans(props.type);
    setPlans(plans);
  };

  // Create a Checkout Session with the selected plan ID
  function createCheckoutSession (priceId) {
    return fetch("http://127.0.0.1:4242/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        price_id: priceId
      })
    }).then(handleFetchResult).catch(function(err) {
      showErrorMessage(err);
      throw err;
    });
  };

  // Handle any errors returned from Checkout
  function handleResult (result) {
    if (result.error) {
      showErrorMessage(result.error.message);
    }
  };

  function showErrorMessage (message) {

  };

  useEffect(() => {
      getPlans()
  }, [])

  return (
    <div className="plan-modal-content">
    <div className="close-button">
      <CloseIcon style={{color: 'white'}} onClick={props.onClose}/>
    </div>
    <div className="plan-modal-content-main">

      <div className="togethere-background"></div>
      <div className="sr-root">
          <header className="sr-header">
            <div className="sr-header__logo">
              {props.inactive ? "You need a subscription to use this feature" : "You need a subscription to use this feature"}
            </div>
          </header>
          {/*<h3 className="plan-header-text">Choose a collaboration plan</h3>*/}
          <div className="price-table-container">
            <Grid container justify="center" spacing={2}>
              {/*<Grid item align="center" xs={10} sm={4} lg={4} md={4}>
                <section>
                  <img
                    src="/img/tier1.png"
                    width="120"
                    height="120"
                  />
                  <div className="name">Free Trial</div>
                  <div className="price">$0</div>
                  <div className="duration">per month</div>
                  <button id="basic-plan-btn" onClick={() => onSelectPlan(0)}>Select</button>
                </section>
                <div className="plan-detail-text">
                  <div>
                    <CheckIcon style={{ color: 'white' }}/>
                    <p>free for 30 days</p>
                  </div>
                  <div>
                    <CheckIcon style={{ color: 'white' }}/>
                    <p>up to 10 users</p>
                  </div>
                </div>
              </Grid>*/}
              {plans.map((p,i) =>
                  <Grid item key={i} align="center" xs={12} sm={12} md={6} lg={6}>
                    <Grid item align="center" xs={12} sm={6} md={6} lg={6}>
                      <section>
                        <img
                          src="/img/tier2.png"
                          width="120"
                          height="120"
                        />
                        <div className="name">{p.name}</div>
                        <div className="price">{"$"+p.price}</div>
                        <div className="duration">per month</div>
                        <button id="basic-plan-btn" onClick={() => onSelectPlan(p.id, false, p.study_type == 'RESEARCH' ? 'study' : 'rtm')}>Select plan</button>
                        {p.trial_allowed && <>
                          <div style={{fontSize: 'smaller'}}>or</div><button id="basic-plan-btn" onClick={() => onSelectPlan(p.id, true, p.study_type == 'RESEARCH' ? 'study' : 'rtm')}>Start a free trial</button>
                          </>}
                      </section>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="plan-detail-text">
                        <div dangerouslySetInnerHTML={{ __html: p.description }}>
                        </div>
                      {/*
                        <div>
                          <p>&#x2714; Access to patient and study reports</p>
                        </div>
                        <div>
                          <p>&#x2714; Design studies and onboard patients</p>
                        </div>
                        <div>
                          <p>&#x2714; Invite investigators to your study</p>
                        </div>
                        <div>
                          <p>&#x2714; Integration with patient mobile app with reminders and push notifications for your study</p>
                        </div>*/}
                      </div>
                    </Grid>
                  </Grid>
              )}
              {/*<Grid item align="center" xs={10} sm={4} lg={4} md={4}>
                <section>
                  <img
                    src="/img/tier3.png"
                    width="120"
                    height="120"
                  />
                  <div className="name">Professional</div>
                  <div className="price">$299</div>
                  <div className="duration">per month</div>
                  <button id="pro-plan-btn" onClick={() => onSelectPlan(2)}>Select</button>
                </section>
                <div className="plan-detail-text">
                  <div>
                    <CheckIcon style={{ color: 'white' }}/>
                    <p>monthly patient reports</p>
                  </div>
                  <div>
                    <CheckIcon style={{ color: 'white' }}/>
                    <p>Edenos insights</p>
                  </div>
                  <div>
                    <CheckIcon style={{ color: 'white' }}/>
                    <p>unlimited users</p>
                  </div>
                </div>
              </Grid>*/}
            </Grid>
          </div>
      </div>
    </div>
    </div>
  )
}
