import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, patients, sites, pis, invs) {
  return { name, patients, sites, pis, invs };
}

const rows = [
  createData('Study A', 159, 6, 2, 8),
  createData('Study B', 137, 4, 1, 5),
  createData('Study C', 162, 3, 2, 9),
  createData('Study D', 305, 7, 1, 12),
  createData('Study E', 54, 3, 1, 3),
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    fontWeight: '600',
    fontSize: 14,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

export default function Studies_Table() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Study</StyledTableCell>
            <StyledTableCell align="right">Patients</StyledTableCell>
            <StyledTableCell align="right">Sites</StyledTableCell>
            <StyledTableCell align="right">PIs</StyledTableCell>
            <StyledTableCell align="right">Investigators</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.patients}</TableCell>
              <TableCell align="right">{row.sites}</TableCell>
              <TableCell align="right">{row.pis}</TableCell>
              <TableCell align="right">{row.invs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
