import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TextField,
  Button
 } from "@material-ui/core";
 import {
   Lock as LockIcon,
 } from "@material-ui/icons";
// components
import { Typography } from "../../components/Wrappers";
import Paper from '@material-ui/core/Paper';
import useStyles from "./styles";


export default function LockedFeature(props) {

  var classes = useStyles();


  return (
    <>

          <Paper className={classes.paper} elevation={0} >
            <LockIcon style={{ fontSize: 88, color: "lightgrey"  }}/>
            <Typography style={{ color: "lightgrey", marginTop: 10 }}>
              Please contact your organization admin to activate this feature.
            </Typography>
          </Paper>

    </>
  );
}
