import * as urls from './urls';
import { apiClient } from './apiClient';


export async function get_products() {
  let url = urls.PRODUCTS();
  let response = await apiClient(
    url,
    'GET'
  )


  console.warn("GET_PRODUCTS (api):     ", response)
  return response
}


export async function get_stats(product_slug, community_slug) {
  let prod_stats = await get_prod_stats(product_slug);
  let comm_stats = await get_comm_stats(community_slug);

  if (!prod_stats && !comm_stats) {
    return null
  } else {
    return {
      product_stats: prod_stats,
      community_stats: comm_stats
    }
  }
}

async function get_prod_stats(product_slug) {
  let url = urls.PRODUCT_STATS(product_slug);
  let response = await apiClient(
    url,
    'GET'
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

async function get_comm_stats(comm_slug) {
  let url = urls.COMMUNITY_STATS(comm_slug);
  let response = await apiClient(
    url,
    'GET'
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function get_promo_stats(promo_slug) {
  let url = urls.PROMO_STATS(promo_slug);
  let response = await apiClient(
    url,
    'GET'
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function get_promotions(product_slug) {
  let url = urls.PROMOTIONS(product_slug);
  let response = await apiClient(
    url,
    'GET'
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}
