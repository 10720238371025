import React, { useState, useEffect } from "react";
import {CardElement} from '@stripe/react-stripe-js';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Link,
  Tabs,
  Tab,
  TextField,
  Fade,
  Divider,
  Modal
} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import useStyles from "../styles";
import MaskedInput from 'react-text-mask';
import MoreInfo from '../../../components/Dialog';
import { BootstrapInput } from '../../../components/BootstrapInput';
import MyStoreCheckout from '../MyStoreCheckout';
import PickPlans from '../PickPlans';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { create_account_link, get_connected_account, get_account_dashboard_link, create_connected_sub } from '../../../api/stripe';

// import { Button } from "@material-ui/core";

// class CardSection extends React.Component {
//   render() {
//     return (
//       <label>
//         Card details
//         <CardElement style={{base: {fontSize: '18px'}}} />
//       </label>
//     );
//   }
// }
function PaymentInfoElement(props) {
  var classes = useStyles();
  const { name, value, ...other } = props;

  return (
    <div className={classes.rowContainer}>
      <div className={classes.rowName}>
        {name}
      </div>
      <div className={classes.rowValue}>
        {value}
      </div>
    </div>
  );
}

const ONBOARDED = true;
const ACC_ID = "acct_1JFNYyQd3gPRRqDl";

const payment_methods = [
  {
    name: 'bank'
  },
  {
    name: 'debit'
  }
]
const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

function PlanCard(props) {
  var classes = useStyles();

  const { title, value } = props;

  return (
    <div className={classes.planDetailsCard}>
      <Typography variant="body1" gutterBottom size="md" weight="medium" color="textSecondary">
        {title}
      </Typography>
      <div>
        <Typography variant="h6" gutterBottom size="xl" weight="medium">
          {value}
        </Typography>
      </div>
    </div>
  );
}

export default function CreatorPlan(props) {
  // handleSubmit = (ev) => {
  //   ev.preventDefault();
  //   if (this.props.stripe) {
  //     this.props.stripe
  //       .createToken()
  //       .then((payload) => console.log('[token]', payload));
  //   } else {
  //     console.log("Stripe.js hasn't loaded yet.");
  //   }
  // };

  var classes = useStyles();
  const info = props.org
  const profile = props.profile
  const customer_id = (profile && profile.payment) ? profile.payment.customer_id : 'cus_JhGI1aL3bX08eH';

  const [isLoading, setIsLoading] = useState(true);
  const [openPayment, setOpenPayment] = useState(false);
  const [openPlans, setOpenPlans] = useState(false);
  const [method, setMethod] = useState('');
  const [onboardingLink, setOnboardingLink] = useState('');
  const [dashboardLink, setDashboardLink] = useState('');
  const [methods, setMethods] = useState([]);
  const [attention, setAttention] = useState(false);

  useEffect(() => {
      if (ACC_ID) {
        getAccount();
      } else {
        getOnboardingLink();
      }
  }, [ACC_ID])

  useEffect(() => {
      if(info) {
        setIsLoading(false)
      }
  }, [info])

  const handleChangePaymentMethod = (event) => {
    setMethod(event.target.value);
  };

  // Create a Session for a user
  async function createSession (user_id) {
    const resp = await fetch("http://127.0.0.1:4242/create-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        price_id: user_id,
        customer_id: customer_id
      })
    })
    if (resp) {
      console.warn(resp)
      const data = await resp.json();
      console.warn(data)
      if(data) {
        window.location.href = data.url;
      }
    }
  };

  async function getOnboardingLink() {
    const resp = await create_account_link(ACC_ID)
    if (resp) {
      setOnboardingLink(resp.url)
    }
  }
  async function getAccount() {
    const resp = await get_connected_account(ACC_ID)
    if (resp) {
      if (resp.attention) {
        // if there's something needed with stripe account
        setOnboardingLink(resp.url)
        setAttention(true)
      } else {
        setMethods(resp.methods);
        generateDashboardLink();
      }
    }
  }

  async function generateDashboardLink() {
    const resp = await get_account_dashboard_link(ACC_ID);
    if (resp) {
      setOnboardingLink(resp.url)
    }
  }

  async function createConnectedSub() {
    const resp = await create_connected_sub(ACC_ID);
    if (resp) {
      console.warn("IN:", resp)
    }
  }

  function handleOnboarding() {

  }

  return (
    <div>
      <form className={classes.infoForm}>

        {/*<div className={classes.sectionHeader}>
          <Typography variant="h6" size="xl" weight="medium" className={classes.planHeader}>
            Plan Details
          </Typography>

          <div className={classes.changePlanLink}>
            <Link
              component="button"
              variant="body2"
              color="primary"
              onClick={(event)=>{event.preventDefault();setOpenPlans(true);}}
            >
              Change Plan
            </Link>
          </div>
        </div>

        <div className={classes.planDetailsContainer}>
          <PlanCard title={"Plan"} value={"Tier 1"} />
          <PlanCard title={"Monthly Cost"} value={"$199"} />
          <PlanCard title={"Start date:"} value={"01/01/2021"} />
        </div>

        <div className={classes.usageContainer}>
          <div className={classes.planUsageText}>
            <InputLabel shrink htmlFor="usage">
              plan usage
            </InputLabel>
            <InputLabel shrink htmlFor="usage">
              140/200
            </InputLabel>
          </div>
          <BorderLinearProgress variant="determinate" color="primary" value={70} />
        </div>

        <Divider className={classes.divider}  />
        */}
        <div className={classes.sectionHeader}>
          <Typography variant="h6" size="xl" weight="medium" className={classes.planHeader}>
            Payment
          </Typography>

          {ONBOARDED &&
            <div className={classes.changePlanLink}>
              <Link
                variant="body2"
                color="primary"
                href={onboardingLink}
              >
                Change Payment Info
              </Link>
            </div>
          }
        </div>

        {ONBOARDED ?
          <div>
            {
              methods.map((method) => {
                if (method.method == 'card')
                  return (
                    <Grid key={method.method.id} item xs={12} sm={6} md={4}>
                      <PaymentInfoElement name={"Card"} value={method.type} />
                      <Divider/>
                      <PaymentInfoElement name={"Last 4 digits"} value={method.last4} />
                      <Divider/>
                      <PaymentInfoElement name={"Country"} value={method.country} />
                      <Divider/>
                      <PaymentInfoElement name={"Expiration"} value={method.exp_year} />
                    </Grid>
                  )
                else if (method.method == 'bank_account') return (
                  <Grid item xs={12} sm={6} md={4}>
                    <PaymentInfoElement name={"Bank"} value={method.bank_name} />
                    <Divider/>
                    <PaymentInfoElement name={"Account ending in"} value={method.last4} />
                    <Divider/>
                    <PaymentInfoElement name={"Country"} value={method.country} />
                  </Grid>
                )
              })
            }
          </div>
          :
          <div>
            You haven't setup your payment methods yet.
          </div>
        }

        {ONBOARDED ?
          <div className={classes.saveBtn} >
            <Button
              variant="contained"
              color="primary"
              onClick={() => createSession()}>
              View Payment
            </Button>
          </div>
          :
          <div className={classes.changePlanLink}>
            <Link
              variant="body2"
              color="primary"
              href={onboardingLink}
            >
              Setup Payment
            </Link>
          </div>
        }
      </form>


      <Modal
        open={openPlans}
        onClose={() => setOpenPlans(false)}
        className={classes.planModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PickPlans customer_id={customer_id}/>
      </Modal>
    </div>
  );
}
