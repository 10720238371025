import { makeStyles } from "@material-ui/styles";
import bkgnd from "../../images/bkgnd.png";

const MAX_WIDTH = 1200;

export default makeStyles(theme => ({
  checkout: {
    maxWidth: 400,
    // box-sizing: border-box;
    padding: 5
  },

  label: {
    color: '#6b7c93',
    fontWeight: '300',
    // letter-spacing: 0.025em,
  },
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    // backgroundImage: `url(${bkgnd})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 125,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "#48ad7e",
    fontWeight: 500,
    fontSize: 74,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  cardInput:{
    color: '#424770',
    backgroundColor: 'white',
    borderRadius: 4,
    letterSpacing: '0.025em',
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: '#aab7c4',
    },
    padding: 7,
    boxShadow: theme.customShadows.widget
  },
  form: {
    width: 320,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.customShadows.widget,
  },
  tabs: {
    backgroundColor: 'white',
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    marginLeft: '5%'
  },
  infoForm: {
    borderWidth: 3,
  },
  saveBtn: {
    display: "flex",
    width: '100%',
    justifyContent: 'flex-end',
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  formControl: {
    marginRight: 15,
    marginTop: 10
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    backgroundColor: theme.palette.background.light,
    width: 250
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paymentButton: {
  },
  test: {
    alignItems: 'center',
    display: "flex",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  planHeader: {
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  planDetailsContainer: {
    flexDirection: 'row',
    width: "100%",
    maxWidth: MAX_WIDTH,
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  usageContainer: {
    width: "100%",
    maxWidth: MAX_WIDTH,
  },
  planDetailsCard: {
    flex: 1,
    margin: theme.spacing(0,1,0,0),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  planUsageText: {
    flexDirection: 'row',
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selecMethodContainer: {
    width: theme.spacing(100),
    display: "flex",
    flexDirection: 'column'
  },
  changePlanLink: {

  },
  sectionHeader: {
    flexDirection: 'row',
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  planModal: {
    display: 'flex',
    margin: theme.spacing(20,10),
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  planModalContent: {
    backgroundColor: theme.palette.background.paper
  },
  rowContainer: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  rowName: {
    color: 'grey',
    fontWeight: '600',
    fontSize: 12
  },
  rowValue: {
    color: 'black',
    fontSize: 12,
    marginLeft: 20
  },
  paperRoot: {
    boxShadow: theme.customShadows.widgetLight,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));
