import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Radio,
  Box,
  Divider
} from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classnames from "classnames";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RBAC from '../../components/RBAC';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// styles
import useStyles from "./designer-styles";
import { withStyles, makeStyles } from '@material-ui/styles';
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

//
// function SimpleQuestion(props) {
//   var classes = useStyles();
//   const { children, value, index, ...other } = props;
//
//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       className={classes.tab}
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }
//
// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const rowStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    padding: '10px',
    margin: '5px',
    overflow: 'hidden'
  },
  title: {
    fontWeight: '600',
    color: 'grey'
  },
  value: {
    alignSelf: 'flex-end'
  }
}));

const Row = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {props.value}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

class ReviewStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }
  //
  // var classes = useStyles();
  //
  // const [title, setTitle] = useState(0);
  // const [options, setOptions] = useState(null);
  //
  //
  // const handleChange = (event, newValue) => {
  //   // setValue(newValue);
  // };


  changeTitle(val) {
    this.setState({title: val})
  }

  changeValue(val) {
    this.setState({value: val})
  }

  render () {
    const { classes, study, questions, initQuestions, endQuestions, cohorts, treatment, studyName, created_date, onboarding_date, clinicalGroups } = this.props;
    var onboarding  = new Date(onboarding_date).toLocaleDateString("en-US");
    var start  = created_date ? new Date(created_date.includes('Z') ? created_date : created_date + 'Z').toLocaleDateString("en-US") : '';
    return (
      <div className={classes.questionModuleContainer}>
        <h4>Basic Info</h4>
        {created_date && <Row name={"Start Date"} value={start}/>}
        {onboarding_date && <Row name={"Expected Onboarding Date"} value={onboarding}/>}
        <Row name={"Study"} value={studyName}/>
        <Row name={"Treatment"} value={treatment && treatment.name}/>
        <Divider/>
        {this.props.type != 'RTM' &&
          <>
          <h4>Cohort Info</h4>
          <Row name={"Number of Cohorts"} value={cohorts && cohorts.length}/>
          {
            cohorts.map((c, i) => (
              <Row key={i} name={`Cohort ${i+1}`} value={c.name}/>
            ))
          }

          <Divider/>
          </>
        }
        <h4>Survey Info</h4>

        <Tabs
          className={classes.tabs}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          value={this.state.value}
          onChange={(e, val) => this.changeValue(val)}
          indicatorColor="primary"
          aria-label="Vertical tabs example"
        >
          <Tab label="Patient Survey" {...a11yProps(0)} />
          {
            clinicalGroups.map((g, i) => (
              <Tab key={i} label={g.name + " Survey"} {...a11yProps(i+1)} />
            ))
          }
        </Tabs>
        <TabPanel {...this.props} value={this.state.value} index={0}>
          { initQuestions &&
            <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Initial Survey</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '100%'}}>
              {
                initQuestions && initQuestions.map((q, i) => (
                  <Row key={i} name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
                ))
              }
              </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>
        }
          <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Longitudinal Survey</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
            {
              questions && questions.map((q, i) => (
                <Row key={i} name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
              ))
            }
            </div>
          </ExpansionPanelDetails>
          </ExpansionPanel>
          { endQuestions ?
            <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>End Survey</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '100%'}}>
                {
                  endQuestions && endQuestions.map((q, i) => (
                    <Row key={i} name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
                  ))
                }
              </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            :
            <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
              <ExpansionPanel>
              <ExpansionPanelSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Button
                  onClick={() => this.props.addEndSurvey()}
                  variant="contained"
                  disabled={study.end_date}
                  style={{ textTransform: 'none', color: 'white', marginBottom: 10}}
                  color="secondary">
                  Add End Survey
                </Button>
              </ExpansionPanelSummary>
              </ExpansionPanel>
            </RBAC>
          }
        </TabPanel>

        {
          clinicalGroups.map((g, i) => (
            <TabPanel {...this.props} value={this.state.value} index={i+1} key={i+1}>
              { (g.initial_survey && g.initial_survey.questions) &&
                <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Initial Survey</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{width: '100%'}}>
                  {
                    g.initial_survey && g.initial_survey.questions.map((q, i) => (
                      <Row name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
                    ))
                  }
                  </div>
                </ExpansionPanelDetails>
                </ExpansionPanel>
              }

              <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Longitudinal Survey</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{width: '100%'}}>
                {
                  g.longitudinal_survey.questions && g.longitudinal_survey.questions.map((q, i) => (
                    <Row name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
                  ))
                }
                </div>
              </ExpansionPanelDetails>
              </ExpansionPanel>

              { (g.final_survey && g.final_survey.questions) ?
                <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>End Survey</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{width: '100%'}}>
                    {
                      g.final_survey && g.final_survey.questions.map((q, i) => (
                        <Row name={`Question ${i+1} ${q.optional ? " (optional)" : ""}`} value={q && q.title} key={i}/>
                      ))
                    }
                  </div>
                </ExpansionPanelDetails>
                </ExpansionPanel>
                :
                <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
                  <ExpansionPanel>
                  <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Button
                      onClick={() => this.props.addGroupEndSurvey(g.id)}
                      variant="contained"
                      style={{ textTransform: 'none', color: 'white', marginBottom: 10}}
                      color="secondary">
                      Add End Survey
                    </Button>
                  </ExpansionPanelSummary>
                  </ExpansionPanel>
                </RBAC>
              }
            </TabPanel>
          ))
        }





      </div>
    );
  }


}

export default withStyles(useStyles)(ReviewStep);
