import React from "react";
import { Grid, Chip, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Button } from "../../components/Wrappers";
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import useStyles from "./styles";

// data

const states = {
  completed: "success",
  accepted: "secondary",
  generated: "default",
  rejected: "defualt",
};


const columns = [
  {
    "name": "email",
    "label": "Email",
    "options": {
     filter: false,
    }
  },
  {
    "name": "invitation_url",
    "label": "Invitation Link",
    "options": {
     filter: false,
    }
  },
  {
    "name": "id",
    "label": "id",
    "options": {
     display: false,
     filter: false
    }
  },
  {
    "name": "has_result",
    "label": "has_result",
    "options": {
     display: false,
     filter: false
    }
  },
  {
    "name": "journey_id",
    "label": "journey_id",
    "options": {
     display: false,
     filter: false
    }
  },
  {
    "name": "treatment.name",
    "label": "Treatment",
    "options": {
     filter: false,
     display: false,
    }
  },
  {
    "name": "started_at",
    "label": "Started",
    "options": {
     filter: false,
     display: false,
    }
  },
  {
    "name": "journey.last_entry_at",
    "label": "Latest Post",
    "options": {
     filter: false,
     display: false,
    }
  },
  {
    "name": "journey.journey_entries_count",
    "label": "Posts",
    "options": {
     filter: false,
     display: false,
    }
  },
  {
    "name": "status",
    "label": "Status"
  }
]

function TableMenu(props) {
  var classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="default" aria-label="create" component="label" onClick={handleClick}>
        <CreateIcon/>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
      </Menu>
    </div>
  )
}

export default function InvitationsTable(props) {
  var classes = useStyles();


  return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {(props.isLoading || !props.invitations) ?
            <div className={classes.center}>
              <CircularProgress color="primary" />
            </div>
            :
            props.invitations.length == 0 ?
            null
            :
            <MUIDataTable
            title="Patient Invitations"
            data={props.invitations}
            columns={columns}
            options={{
              filter: false,
              print: false,
              download: false,
              filterType: "checkbox",
              selectableRows: "none",
              enableNestedDataAccess: '.',
              viewColumns: false,
              customRowRender: (data, dataIndex, rowIndex) => {
                const [ email, invitation_url, id, has_result, journey_id, treatment, started_at, last_entry_at, journey_entries_count, status ] = data;
                return (
                  <TableRow key={invitation_url}>
                    <TableCell>{email}</TableCell>
                    <TableCell className="pl-3 fw-normal">{invitation_url}</TableCell>
                    <TableCell>
                      {has_result ?
                        <Button
                          color="success"
                          size="small"
                          className="px-2"
                          variant="contained"
                          style={{textTransform: 'none'}}
                          onClick={() => {props.gotoSurveyResult(journey_id)}}
                        >
                          started / view data
                        </Button>
                        :
                        <Chip
                          color={states[status.toLowerCase()]}
                          size="small"
                          className="px-2"
                          style={{textTransform: 'none'}}
                          label = {status.toLowerCase()}
                        />
                      }
                    </TableCell>
                    {/*<TableCell>
                      <TableMenu/>
                    </TableCell>*/}
                  </TableRow>
                );
              }
            }}>
          </MUIDataTable>
          }
        </Grid>
      </Grid>
  );
}
