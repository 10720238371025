import React, { useEffect } from "react";
import { handleNotification } from '../pages/notifications/NotificationsClient';
import { useUserState, signOut } from './UserContext';

import { get_treatments } from '../api/treatment';

var ProductContext = React.createContext();

function productReducer(state, action) {
  switch (action.type) {
    case "GOT_PRODUCTS":
      let products = action.data;
      return { ...state, products: products, selectedProduct: state.selectedProduct ? state.selectedProduct : null, isLoadingProduct: false };
      break;
    case "SELECT_PRODUCT":
      if (action.data)
        return { ...state, selectedProduct: action.data };
      else
        return { ...state, selectedProduct: (state.products && state.products.length) ? state.products[0] : null };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "REQUEST_SUCCESS":
      handleNotification("request success")
      return { ...state, isAuthenticated: false };
    case "REQUEST_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ProductProvider({ children }) {

  var [state, dispatch] = React.useReducer(productReducer, {
    isLoadingProduct: true,
    products: []
  });

  var userDispatch = useUserState().dispatch;
  var userState = useUserState().state;

  async function fetchData() {
    if(userState.isAuthenticated) {
      let data = await get_treatments();
      dispatch({ type: "GOT_PRODUCTS", data: (data) ? (data) : [] })

      // if (error && error === 'unauthroized') {
      //   signOut(userDispatch)
      // } else {
      //   dispatch({ type: "GOT_PRODUCTS", data: (data) ? (data) : [] })
      // }
    }
  }

  useEffect(() => {
    fetchData();
  }, [userState])

  return (
    <ProductContext.Provider value={{state, dispatch}}>
        {children}
    </ProductContext.Provider>
  );
}

function useProductState() {
  var context = React.useContext(ProductContext);
  if (context === undefined) {
    throw new Error("useProductState must be used within a ProductProvider");
  }
  return context;
}

export { ProductProvider, useProductState, gotoProduct, updateProducts };

// ###########################################################

async function updateProducts(dispatch, state) {
  let data = await get_treatments();
  dispatch({ type: "GOT_PRODUCTS", data: (data) ? (data) : [] })
}

function gotoProduct(dispatch, state, id, setIsLoading, setError) {
  if(state.selectedProduct && state.selectedProduct.id == id || (!id && (state.selectedProduct === state.products[0])) )
    return
  setError(false);
  setIsLoading(true);
  if ( !state.isLoadingProduct ) {
    var newProd = state.products.find( prod => prod.id === id)
    dispatch({ type: "SELECT_PRODUCT", data: newProd});
    setError(false);
    setIsLoading(false);

  } else {
      setError(false);
      setIsLoading(false);
  }
}
