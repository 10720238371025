import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box
} from "@material-ui/core";
import classnames from "classnames";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import CreatorInfoForm from './CreatorInfoForm';
import CreatorPlan from './CreatorPlan';
// styles
import useStyles from "../styles";
import { get_orginfo, update_orginfo } from '../../../api/manage';
import { useUserState } from "../../../context/UserContext";

// logo
import logo from "../../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../../images/bkgnd.png";
import google from "../../../images/google.svg";

const stripePromise = loadStripe('pk_test_51J3Ws2Hn6GyWjYq3qqZcIgTKYVyd1GbwzQglmqIdEAS6IUtkzneB5xhiTWTJBSEIupZnyynzI1ooG0k8avoIDh6V00hkDuNaWD');

function TabPanel(props) {
  var classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      className={classes.tab}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CreatorAccount(props) {
  var classes = useStyles();

  const [value, setValue] = useState(0);
  const [orgInfo, setOrgInfo] = useState(null);

  var {state, dispatch} = useUserState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getOrgInfo() {
    const resp = await get_orginfo();
    if(resp) {
      console.warn("info: ", resp)
      setOrgInfo(resp);
    } else {
      console.warn("not success");
    }
  }

  useEffect(() => {
      getOrgInfo()
  }, [])


  async function updateInfo(formData) {
    let response = await update_orginfo(dispatch, formData)
    if (response) {
      let updated_info = orgInfo;
      for (var key in formData)
        updated_info[key] = formData[key]
      setOrgInfo(updated_info)
    }
  }

  return (
    <Elements stripe={stripePromise}>

      <div className={classes.root}>

        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Account information" {...a11yProps(0)} />
          <Tab label="Payments" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CreatorInfoForm org={orgInfo} profile={state && state.profile} update={(formData) => updateInfo(formData)}/>
        </TabPanel>
        <TabPanel value={value} org={orgInfo} profile={state && state.profile} index={1}>
          <CreatorPlan />
        </TabPanel>

      </div>
    </Elements>
  );

}
