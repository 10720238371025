export const BASE_URL = process.env.REACT_APP_API_URL

export const CREATE_PRODUCT = () => {
  return BASE_URL + 'create-product'
};

export const GET_PRODUCT = (id) => {
  return BASE_URL + 'get-products'
};

export const GET_PRODUCT_PRICES = (id) => {
  return BASE_URL + 'get-product-prices?product_id=' + id
};

export const GET_CUSTOMER_PLAN = () => {
  return BASE_URL + 'get-customer-plan'
};

export const CREATE_ACCOUNT_LINK = () => {
  return BASE_URL + 'create-account-link'
};

export const GET_CONNECTED_ACCOUNT = () => {
  return BASE_URL + 'retrieve-connected-account'
};

export const GET_ACCOUNT_DASHBOARD_LINK = () => {
  return BASE_URL + 'retrieve-account-dashboard-link'
};

export const CREATE_CONNECTED_SUB = () => {
  return BASE_URL + 'create-connected-subscription'
};

export const PLANS = () => {
    return BASE_URL + 'plan'
};

export const CHECKOUT = () => {
    return BASE_URL + 'checkout'
};

export const PORTAL = () => {
    return BASE_URL + 'settings/stripe_portal'
};
