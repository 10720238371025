import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classnames from "classnames";
import { css } from 'glamor';

// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from "../../components/Notification";
import { Typography, Button } from "../../components/Wrappers/Wrappers";

const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT,
];


// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

function sendNotification(componentProps, position = toast.POSITION.TOP_RIGHT, options) {
  return toast(
    <Notification
      {...componentProps}
    />,
    {...options, ...{position: position}
    }
  );
}

export function handleNotification(notificationType, position, custom=false) {
  var componentProps;


  if (notificationType === "error") return;

  if (custom) {
    componentProps = {
      type: "feedback",
      message: notificationType,
      variant: "contained",
      color: "warning",
    };
  } else {
    switch (notificationType) {
      case "info":
        componentProps = {
          type: "feedback",
          message: "New user feedback received",
          variant: "contained",
          color: "primary",
        };
        break;
      case "error":
        componentProps = {
          type: "message",
          message: "Message was not sent!",
          variant: "contained",
          color: "secondary",
          extraButton: "Resend",
          extraButtonClick: this.retryErrorNotification,
        };
        break;
      case "login error":
        componentProps = {
          type: "feedback",
          message: "Invalid login credentials",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "forgot success":
        componentProps = {
          type: "message",
          message: "Check your inbox!",
          variant: "contained",
          color: "success"
        };
        break;
      case "reset success":
        componentProps = {
          type: "message",
          message: "Password is changed successfully",
          variant: "contained",
          color: "success"
        };
        break;
      case "request error":
        componentProps = {
          type: "feedback",
          message: "something went wrong",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "request success":
        componentProps = {
          type: "feedback",
          message: "We will contact you soon!",
          variant: "contained",
          color: "success"
        };
        break;
      case "bad email error":
        componentProps = {
          type: "message",
          message: "the link is not set for this email",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "confirm error":
        componentProps = {
          type: "message",
          message: "could not verify account",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "invalid link error":
        componentProps = {
          type: "message",
          message: "registration link is invalid",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "register success":
        componentProps = {
          type: "info",
          message: "You are successfully registered",
          variant: "contained",
          color: "success"
        };
        break;
      case "user add success":
        componentProps = {
          type: "info",
          message: "An invitation link has been sent to user",
          variant: "contained",
          color: "success"
        };
        break;
      case "user add error":
        componentProps = {
          type: "feedback",
          message: "failed to add user",
          variant: "contained",
          color: "secondary"
        };
        break;
      case "user remove success":
        componentProps = {
          type: "feedback",
          message: "user removed",
          variant: "contained",
          color: "success"
        };
        break;
      case "resend link success":
        componentProps = {
          type: "info",
          message: "a new link has been sent",
          variant: "contained",
          color: "success"
        };
        break;
      case "patient invite success":
        componentProps = {
          type: "info",
          message: "An invitation link has been generated",
          variant: "contained",
          color: "success"
        };
        break;
      case "patient invite error":
        componentProps = {
          type: "feedback",
          message: "failed to generate journey link",
          variant: "contained",
          color: "warning"
        };
        break;
      case "survey create success":
        componentProps = {
          type: "info",
          message: "Survey added to your library",
          variant: "contained",
          color: "success"
        };
        break;
      case "survey create fail":
        componentProps = {
          type: "feedback",
          message: "Failed to create survey",
          variant: "contained",
          color: "warning"
        };
        break;
      case "End survey added":
        componentProps = {
          type: "info",
          message: "End survey added",
          variant: "contained",
          color: "success"
        };
        break;
      case "study create success":
        componentProps = {
          type: "info",
          message: "Your study has been started",
          variant: "contained",
          color: "success"
        };
        break;
      case "study create fail":
        componentProps = {
          type: "feedback",
          message: "Failed to create study",
          variant: "contained",
          color: "warning"
        };
        break;
      case "study invite staff success":
        componentProps = {
          type: "feedback",
          message: "User has been added to your study",
          variant: "contained",
          color: "success"
        };
        break;
      case "shadow patient invite success":
        componentProps = {
          type: "feedback",
          message: "Patient has been invited to study",
          variant: "contained",
          color: "success"
        };
        break;
      case "create treatment success":
        componentProps = {
          type: "info",
          message: "Treatment is created successfully",
          variant: "contained",
          color: "success"
        };
        break;
      case "select cohort first":
        componentProps = {
          type: "feedback",
          message: "Select a cohort first",
          variant: "contained",
          color: "warning"
        };
        break;
      case "subscription error":
        componentProps = {
          type: "feedback",
          message: "Something went wrong. Please contact the study admin!",
          variant: "contained",
          color: "warning"
        };
        break;
      case "invitation error":
        componentProps = {
          type: "feedback",
          message: "failed to add patient",
          variant: "contained",
          color: "warning"
        };
        break;
      case "duplicate email":
        componentProps = {
          type: "feedback",
          message: "email already exists",
          variant: "contained",
          color: "warning"
        };
        break;
      case "Journey ended":
        componentProps = {
          type: "feedback",
          message: "journey ended",
          variant: "contained",
          color: "warning"
        }
        break;
      case "reminder copied":
        componentProps = {
          type: "info",
          message: "Copied to clipboard",
          variant: "contained",
          color: "success"
        };
        break;
      case "subscription success":
        componentProps = {
          type: "info",
          message: "Subscribed successfully",
          variant: "contained",
          color: "success"
        };
        break;
      case "Journey has ended.":
        componentProps = {
          type: "feedback",
          message: "Journey has ended.",
          variant: "contained",
          color: "warning"
        };
        break;
      case "Required Fields":
        componentProps = {
          type: "feedback",
          message: "Please answer the required questions.",
          variant: "contained",
          color: "warning"
        };
        break;
      case "Response saved":
        componentProps = {
          type: "info",
          message: "Response saved",
          variant: "contained",
          color: "success"
        };
        break;
      default:
        componentProps = {
          type: "shipped",
          message: "success",
          variant: "contained",
          color: "success",
        };
    }
  }


  let notifType = componentProps.type.toLowerCase();
  var toastId = sendNotification(componentProps, position, {
    type: 'notifType',
    hideProgressBar: true,
    className: css({
        display: "flex",
        alignItems: "center",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
    })
  });

}
