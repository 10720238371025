import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Box, FormControl, Divider, TextField, Typography, InputAdornment, OutlinedInput, Modal } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { generatePath } from 'react-router';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from "../../components/Wrappers";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import ApexLineChart from "../charts/components/ApexLineChart";
import CohortStat from "./CohortStat.js";
import useStyles from "./styles";
import Test from './StudyCharts/Heatmap';
import Compare from './StudyCharts/CompareCohorts';
import StudyCharts from './analysis/StudyCharts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import SurveyDesigner from "./SurveyDesigner";

import { get_study,
    get_study_summary,
    stop_study,
    invite_patient_to_study,
    add_shadow_patient_to_study,
    invite_staff_to_study,
    update_study,
    update_study_clinicalgroup,
    get_study_invitations,
    get_study_patients,
    get_studygroup,
    end_journey,
    get_study_staff } from '../../api/study';
import { get_invites } from '../../api/doctors';
import { useUserState } from "../../context/UserContext";
import StopIcon from '@material-ui/icons/Stop';
import EmailIcon from '@material-ui/icons/Email';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { handleNotification } from '../notifications/NotificationsClient';
import RBAC from '../../components/RBAC';
import Invite from './Invite';
import PatientTable from '../doctordashboard/PatientTable';
import InvitationsTable from '../doctordashboard/InvitationsTable';
import ReviewStep from './ReviewStep';
import OnboardingChart from '../dashboard/components/OnboardingChart';
import AddtoJourney from './AddtoJourney';

// data
import mock from "./mock";

const ACTIVE_TAB = {
  0: "overview",
  1: "patients",
  2: "analysis",
  3: "management"
};

const ROLES_NAME = {
  'PRINCIPAL_INVESTIGATOR': "Principal Investigator",
  'INVESTIGATOR': 'Investigator',
  'DOCTOR': 'Doctor',
  'RESEARCH_ADMIN': 'Admin'
}
const ADMIN_TYPES = [{
  name: 'Principal Investigator',
  value: 'PRINCIPAL_INVESTIGATOR'
}];

const PI_TYPES = [{
  name: 'Investigator',
  value: 'INVESTIGATOR'
}]

const states = {
  confirmed: "success",
  generated: "default",
  completed: "success",
  invited: "warning",
  sent: "secondary",
};

const series = [
  {
    name: "Grip Strength",
    data: [1, 2, 1, 2, 3, 3, 4],
  },
  {
    name: "Pain",
    data: [5, 5, 4, 3, 2, 2, 3],
  },
];

const columns = [
  {
    "name": "user",
    "label": "Name",
    "options": {
     filter: false,
    }
  },
  {
    "name": "user.organization.name",
    "label": "Organization"
  },
  {
    "name": "user.email",
    "label": "Email",
    "options": {
     filter: false,
    }
  },
  {
    "name": "roles",
    "label": "Roles"
  },
  {
    "name": "invitation_url",
    "label": "Invite Link",
    "options": {
     filter: false,
    }
  },
  {
    "name": "status",
    "label": "Status"
  }
]

// const columns = [
//   {
//     "name": "firstname",
//     "label": "First Name"
//   },
//   {
//     "name": "lastname",
//     "label": "Last Name"
//   },
//   {
//     "name": "treatment",
//     "label": "Treatment"
//   },
//   {
//     "name": "email",
//     "label": "Email"
//   },
//   {
//     "name": "cohort",
//     "label": "Cohort"
//   },
//   {
//     "name": "status",
//     "label": "Status"
//   }
// ]



function TabPanel(props) {
  var classes = useStyles();

  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      className={classes.tab}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function StudyAnalysis(props) {
  const [value, setValue] = useState(0);
  const [study, setStudy] = useState(null);
  const [patients, setPatients] = useState([]);
  const [newPatients, setNewPatients] = useState([]);
  const [studySummary, setStudySummary] = useState(null);
  const [role, setRole] = useState('');
  const [stopStudy, setStopStudy] = useState(false);
  const [stopText, setStopText] = useState('');
  const [selectedCohortA, setSelectedCohortA] = useState(null)
  const [selectedCohortB, setSelectedCohortB] = useState(null);
  const [openInvite, setOpenInvite] = useState(false);
  const [openInvitePatient, setOpenInvitePatient] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminType, setAdminType] = useState(null);
  const [group_id, setGroupID] = useState(null);
  const [groups, setGroups] = useState([]);
  const [staff, setStaff] = useState([]);
  const [clear, setClear] = useState(false);
  const [openDiscard, setOpenDiscard] = useState(false);
  const [staffTypes, setStaffTypes] = useState([]);
  const [patientID, setPatientID] = useState(0);
  const [isInternal, setIsInternal] = useState(true);
  const [endSurveyOpen, setEndSurveyOpen] = useState(false);
  const [finalSurveyName, setFinalSurveyName] = useState("");
  const [finalQuestions, setFinalQuestions] = useState([]);
  const [groupFinalQuestions, setGroupFinalQuestions] = useState([]);
  const [groupFinalName, setGroupFinalName] = useState("");
  const [finalSurveyID, setFinalSurveyID] = useState(null);
  const [groupFinalSurveyID, setGroupFinalSurveyID] = useState(null);
  const [clinicalEndSurveyOpen, setClinicalEndSurveyOpen] = useState(-1);
  const [patientLoading, setPatientLoading] = useState(true)
  const user_dispatch = useUserState().dispatch;
  var userState = useUserState().state;

  const handleChange = (event, newValue) => {
    const path = generatePath(props.match.path, {study_id: props.match.params.study_id, "active_tab": ACTIVE_TAB[newValue]})
    props.history.push(path)
    setValue(newValue);
  };

  function prepareStudyData(questions) {
    let payload = {
      survey: study.survey,
      study_type: study.study_type,
      size: study.size,
      name: study.name,
      treatment_id: study.treatment.id
    };

    let cohorts = study.cohorts.map((c) => {return {
      name: c.name,
      description: c.description,
      id: c.id
    }})
    payload.cohorts = cohorts
    if (study.expected_onboarding_end_date) {
      payload.expected_onboarding_end_date = study.expected_onboarding_end_date
    }

    let final_survey = {}
    let payload_final_questions = [];

    if (finalSurveyID) {
      final_survey = {id: finalSurveyID};
    } else if (finalSurveyName && (questions.length > 0))  {
      final_survey.name = finalSurveyName;
      for (let i=0; i < questions.length; i++) {
        const q = questions[i];
        let d = {}
        d.type = q.type.toLowerCase();
        d.title = q.title;
        d.rank = i+1;
        d.options = q.options;
        d.optional = q.optional || false;
        if (d.type=='scale') {
          d.min_label = q.min_label;
          d.max_label = q.max_label;
        }
        payload_final_questions.push(d);
      }
      final_survey.questions = payload_final_questions;
    }
    payload.final_survey = final_survey;
    return payload;
  }

  function prepareGroupStudyData(group) {
    let found = study.groups.find(g => {
       return g.id == group
    })
    if (!found) {
      return null
    }

    let payload = found;
    if ('initial_survey' in payload) {
      delete payload['initial_survey']
    }
    let final_survey = {}
    let payload_final_questions = [];

    if (groupFinalSurveyID) {
      final_survey = {id: groupFinalSurveyID};
    } else if (groupFinalName && (groupFinalQuestions.length > 0))  {
      final_survey.name = groupFinalName;
      for (let i=0; i < groupFinalQuestions.length; i++) {
        const q = groupFinalQuestions[i];
        let d = {}
        d.type = q.type.toLowerCase();
        d.title = q.title;
        d.rank = i+1;
        d.options = q.options;
        d.optional = q.optional || false;
        if (d.type=='scale') {
          d.min_label = q.min_label;
          d.max_label = q.max_label;
        }
        payload_final_questions.push(d);
      }
      final_survey.questions = payload_final_questions;
    }
    payload.final_survey = final_survey;
    delete payload.id
    return payload;
  }


  async function getStudy(id) {
    const {data, headers} = await get_study(id, user_dispatch);
    setRole(headers && headers['x-clinical-study-roles'])
    setStudy(data);
  }

  async function getStudyGroups(id) {
    const gp = await get_studygroup(id)
    setGroups(gp)
  }

  async function getStudySummary(id) {
    const {data, headers} = await get_study_summary(id, user_dispatch);
    setStudySummary(data);
  }

  async function getPatients(id) {
    setPatientLoading(true)
    const patients = await get_study_patients(id, user_dispatch);
    setPatientLoading(false)
    if (patients) {
      setPatients(patients);
    }
  }

  async function getInvites(id) {
    const invitations = await get_study_invitations(id, user_dispatch);
    if (invitations) {
      var generated_invs = invitations.filter(function (inv) {
        return !inv.has_result;
      });
      setInvitations(generated_invs);
    }
  }

  async function getStaff(id) {
    const staff = await get_study_staff(id, user_dispatch);
    if (staff) {
      setStaff(staff);
    }
  }

  async function inviteStaff() {
    let data = {
      email: adminEmail,
      type: adminType
    }

    if (group_id) {
      data.clinical_study_investigator_group_id = group_id;
    }
    const resp = await invite_staff_to_study(study && study.id, data, user_dispatch);
    if (resp && !resp.error) {
      setOpenInvite(false)
      handleNotification("study invite staff success")
      const id = props.match.params.study_id;
      getStaff(id);
    }
  }

  useEffect(() => {
    const active_tab = props.match.params.active_tab;
    if (active_tab) {
      for (let k in Object.keys(ACTIVE_TAB)) {
        if (active_tab == ACTIVE_TAB[k]) {
          setValue(parseInt(k))
          break
        }
      }
    }
  }, [props.match.params])

  useEffect(() => {
    (async () => {
      const id = props.match.params.study_id;
      await getStudy(id);
      await getStudySummary(id);
      await getStudyGroups(id);
      await getStaff(id);
      await getPatients(id);
      await getInvites(id);
      const urlParams = new URLSearchParams(window.location.search);
      const patient_id = urlParams.get('id');
      if (patient_id) {
        setPatientID(patient_id)
      }
      if (userState && userState.roles) {
        if (userState.roles.includes("RESEARCH_ADMIN")) {
          setAdminType(ADMIN_TYPES[0].value)
          setStaffTypes(ADMIN_TYPES)
        } else {
          setAdminType(PI_TYPES[0].value)
          setStaffTypes(PI_TYPES)
        }
      }
    })()
  },[])

  async function handleStop() {
    const id = props.match.params.study_id;
    const now = new Date()
    const resp = await stop_study(id, {
      end_date: now.toISOString(),
      cohorts: study.cohorts,
      name: study.name,
      treatment_id: study.treatment.id,
      survey: study.survey
    });
    if (resp && resp.id) {
      handleNotification("study cancel success")
      setStopStudy(false)
      getStudy(id)
    } else {
      handleNotification("study cancel fail")
    }
  }

  function handleStopText(text) {
    setStopText(text)
  }

  function handleCancel() {
    setStopStudy(false)
  }

  async function addShadowUser(data) {
    const resp = await add_shadow_patient_to_study(data.cohort.id, {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth
    });
    if (resp && resp.data) {
      // handleNotification("shadow patient invite success")
      // getPatients(id)
      return true
    } else if (resp && resp.msg_detail) {
      handleNotification(Object.keys(resp.msg_detail)[0] + " : " + resp.msg_detail[Object.keys(resp.msg_detail)[0]][0], null, true)
      return false
    } else {
      handleNotification("invitation error")
      return false
    }
  }

  async function invitePatients(data) {
    let invited_internal_patient = false
    const id = props.match.params.study_id;
    for (var coh in data) {
      let list = data[coh];
      let payload = []
      for (let i=0; i < list.length; i++) {
        if (list[i].type == 'shadow') {

          try {
            let success = await addShadowUser({
              first_name: list[i].first_name,
              last_name: list[i].last_name,
              gender: list[i].gender,
              date_of_birth: list[i].date_of_birth,
              cohort: list[i].cohort
            })

            if (success) { invited_internal_patient = true }
          } catch (e) {

          }
        } else {
          payload.push({
            'private': true,
            'email': list[i].email,
            'first_name': list[i].first_name,
            'last_name': list[i].last_name,
            'date_of_birth': list[i].date_of_birth,
            'type': 'clinical_study'
          })
        }
      }
      if (payload.length == 0) {
        continue
      }
      const resp = await invite_patient_to_study(coh, {'invites': payload});
      if (resp) {
        handleNotification("patient invite success")
      }
    }
    if (invited_internal_patient) {
      handleNotification("shadow patient invite success")
    }
    getPatients(id)
    getInvites(id)
    setOpenInvitePatient(false)
    setNewPatients([])
  }

  function onDownload(buildHead, buildBody, columns, data){
    try {
      return buildHead(columns) + myBuildBody(data)
    } catch (e) {
      console.warn("error happened during downloading table")
    }
  }

  function myBuildBody(data) {
    let output = []
    for (let i=0; i < data.length; i++) {
      var obj = data[i]['data']
      var str = [obj[0]['first_name'] + ' ' + obj[0]['last_name'], obj[1], obj[2], obj[3], obj[4], obj[5]]
      output.push(str.join())
    }
    return output.join("\n")
  }

  function closeAddModal(refresh) {
    setPatientID(0)
    setIsInternal(true)
    if (refresh) {
      const id = props.match.params.study_id;
      getPatients(id);
    }
  }

  function handleSelectPatient(id) {
    setPatientID(id)
    props.history.push(`?id=${id}`)
    let found = patients.find(p => {
       return p.id == id
    })
    if (found && (found.type == "CLINICAL_STUDY")) {
      setIsInternal(false)
    }
  }

  async function handleEndJourney(journey) {
    let payload = {}
    payload.description = journey.description
    payload.day_offset = journey.day_offset
    payload.title = journey.title

    payload.end_date = new Date().toISOString()
    const resp = await end_journey(payload, journey.id);
    if (resp && !resp.error ) {
      handleNotification("Journey ended")
    }
  }

  async function updateClinicalEndSurveyName(name) {
    setGroupFinalName(name)
  }

  async function updateEndSurvey(status) {
    setEndSurveyOpen(false)
    if (!status) {
      return
    }

    const resp = await update_study(prepareStudyData(finalQuestions), study && study.id)
    if (resp && resp.id) {
      handleNotification("End survey added")
      const id = props.match.params.study_id;
      getStudy(id);
    }
  }

  async function updateClinicalEndSurvey (status, group) {
    setClinicalEndSurveyOpen(-1)
    if (!status) {
      return
    }
    const resp = await update_study_clinicalgroup(prepareGroupStudyData(group), study && study.id, group)
    if (resp) {
      const id = props.match.params.study_id;
      getStudy(id);
    }
  }

  function handleGroupEndSurvey(g) {
    setGroupFinalQuestions([])
    setGroupFinalName("")
    setClinicalEndSurveyOpen(g)
  }

  const handleCloseInvites = () => {
    var empty = true
    for (var coh in newPatients) {
      if (newPatients[coh].length > 0) {
        empty = false
        break
      }
    }
    if (!empty) {
      setOpenDiscard(true)
    } else {
      setOpenInvitePatient(false)
    }
  };

  const handleDiscard = () => {
    setOpenDiscard(false)
    setOpenInvitePatient(false)
  }

  function removeParams() {
    props.history.replace(window.location.pathname)
  }
  // var classes = props.classes
  var classes = useStyles();

  let end_date = (study && study.end_date) ? new Date(study.end_date.includes('Z') ? study.end_date : study.end_date+'Z').toLocaleDateString() : null
  return (
    <div>
      <PageTitle
        title={study && study.name}
        subTitle={end_date ? `Ended on ${end_date}` : null}
      />

      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label={(props.type=='RTM') ? "RTM Overview" : "Study Overview"} {...a11yProps(0)} />
        <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']} secondaryAccess={role == 'PRINCIPAL_INVESTIGATOR' || role == 'INVESTIGATOR'}><Tab label="Patients" {...a11yProps(1)} /></RBAC>
        <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}><Tab label="Cohort Analysis" {...a11yProps(2)} /></RBAC>
        <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']} secondaryAccess={role == 'PRINCIPAL_INVESTIGATOR'}><Tab label="Study Management" {...a11yProps(3)} /></RBAC>
      </Tabs>
      <TabPanel {...props} value={value} index={0}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Widget title={props.type=='RTM' ? "RTM Overview" : "Study Overview"} upperTitle disableWidgetMenu>
                {study && <ReviewStep
                    type={props.type}
                    study={study}
                    studyName={study && study.name}
                    questions={study && study.survey.questions}
                    initQuestions={study && study.initial_survey && study.initial_survey.questions}
                    endQuestions={study && study.final_survey && study.final_survey.questions}
                    clinicalGroups={study && study.groups}
                    treatment={study && study.treatment}
                    created_date={studySummary && studySummary.created_date}
                    onboarding_date={studySummary && studySummary.expected_onboarding_end_date}
                    addEndSurvey={() => setEndSurveyOpen(true)}
                    addGroupEndSurvey={(g) => handleGroupEndSurvey(g)}
                    cohorts={study && study.cohorts}/>}
              </Widget>
            </Grid>
            {studySummary && (studySummary.size > 0) && studySummary.expected_onboarding_data &&
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Widget
                title="Patient onboarding performance"
                upperTitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
                disableWidgetMenu
              >
                <OnboardingChart
                  onboarding={studySummary.actual_onboarding_data}
                  expected={studySummary.expected_onboarding_data}
                  size={studySummary.size}
                  init={studySummary.created_date}/>
              </Widget>
            </Grid>}

            {/*
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Widget title="Journey Survey" upperTitle noBodyPadding disableWidgetMenu>
                <ApexLineChart data={series} labels={[1,2,3,4,5,6,7]}/>
              </Widget>
            </Grid>*/}
            {/*
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Widget title="heatamp" upperTitle noBodyPadding disableWidgetMenu>
                <Test />
              </Widget>
            </Grid>*/}
          </Grid>
      </TabPanel>
      <TabPanel {...props} value={value} index={2}>
        {(study && study.cohorts && (study.cohorts.length > 0))&&
          <StudyCharts {...props} study={study}/>
        }
      </TabPanel>
      <TabPanel {...props} value={value} index={1}>
        <Button
          disabled={study && study.end_date}
          onClick={() => setOpenInvitePatient(true)}
          variant="contained"
          style={{ textTransform: 'none', color: 'white', marginBottom: 10}}
          startIcon={<EmailIcon />}
          color="secondary">
          Invite Patients
        </Button>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PatientTable
              studyType={study && study.study_type}
              patients={patients}
              isLoading={patientLoading}
              gotoForms={(id) => window.open("/forms/" + id, "_blank")}
              addtoJourney={(id) => handleSelectPatient(id)}
              endJourney={(payload) => handleEndJourney(payload)}
              gotoSurveyResult={(id) => props.history.push("/survey_results/" + id)}/>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InvitationsTable
              invitations={invitations}
              isLoading={false}
              gotoSurveyResult={(id) => props.history.push("/survey_results/" + id)}/>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel {...props} value={value} index={3}>
        {(study && !study.end_date) &&
          <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
            <div className={classes.addBtn}>
              <Button
                onClick={() => setStopStudy(true)}
                variant="contained"
                style={{ textTransform: 'none', color: 'white'}}
                startIcon={<StopIcon />}
                color="error">
                Finish Study
              </Button>
            </div>
          </RBAC>
        }
        {(props.type!='RTM') &&
        <RBAC allowedRfoles={['admin', 'demo', 'RESEARCH_ADMIN']} secondaryAccess={role == 'PRINCIPAL_INVESTIGATOR'}>
          <React.Fragment>
            <Button
              disabled={study && study.end_date}
              onClick={() => setOpenInvite(true)}
              variant="contained"
              style={{ textTransform: 'none', color: 'white', marginBottom: 10}}
              startIcon={<EmailIcon />}
              color="secondary">
              Invite Clinical Team
            </Button>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {(props.isLoading) ?
                  <div className={classes.center}>
                    <CircularProgress color="primary" />
                  </div>
                  :
                  <MUIDataTable
                    title="Clinical Team"
                    dense
                    data={staff}
                    columns={columns}
                    options={{
                      onDownload:onDownload,
                      filterType: "checkbox",
                      setTableProps: () => {
                        return {
                          // material ui v4 only
                          size: 'small',
                        }},

                      selectableRows: "none",
                      viewColumns: false,
                      customRowRender: data => {
                        const [ user, orgname, email, roles, invitation_url, status ] = data;
                        return (
                          <TableRow key={user.id}>
                            <TableCell className="pl-3 fw-normal">{user.first_name} {user.last_name}</TableCell>
                            <TableCell>{user.organization && user.organization.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{ROLES_NAME[roles]}</TableCell>
                            <TableCell>{invitation_url}</TableCell>
                            <TableCell>
                              <Button
                                disabled={status.toLowerCase() == 'confirmed'}
                                color={states[status.toLowerCase()]}
                                size="small"
                                style={{textTransform: 'none'}}
                                className="px-2"
                                variant="contained"
                              >
                                {status.toLowerCase()}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }}
                />
                }
              </Grid>
            </Grid>
          </React.Fragment>
        </RBAC>
        }
      </TabPanel>
      <Dialog
        open={openInvite}
        aria-describedby="alert-dialog"
        keepMounted
        onClose={() => setOpenInvite(false)}
      >
        <DialogTitle>{"Invite other investigators and doctors to help you with this study."}</DialogTitle>
        <DialogContent>
          <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
            <DialogContentText>
              Principal investigators can invite other investigators to the study.
            </DialogContentText>
          </RBAC>

          <Grid container spacing={1}>
            <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
              <Grid item xs={8} sm={5}>
                <Select
                  value={adminType}
                  fullWidth
                  variant="outlined"
                  onChange={e => setAdminType(e.target.value)}
                >
                  {staffTypes.map((c, i) => {
                    return(
                      <MenuItem key={i} value={c.value}>{c.name}</MenuItem>)
                  })}
                </Select>
              </Grid>
            </RBAC>
            <RBAC allowedRoles={['RESEARCH_COORDINATOR']}>
              <Grid item xs={8} sm={5}>
                <Select
                  value={group_id}
                  fullWidth
                  variant="outlined"
                  onChange={e => setGroupID(e.target.value)}
                >
                  {study && study.groups.map((g, i) => {
                    return(
                      <MenuItem key={i} value={g.id}>{g.name}</MenuItem>)
                  })}
                </Select>
              </Grid>
            </RBAC>
            <Grid item xs={12} sm={7}>
              <TextField
                name="email"
                maxrows={1}
                fullWidth
                size="small"
                variant="outlined"
                value={adminEmail}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                      <EmailIcon fontSize="small" style={{color:"lightgrey"}}/>
                    </InputAdornment>
                }}
                onChange={(e) => setAdminEmail(e.target.value)}
              />
            </Grid>
          </Grid>

          {/*<div className={classes.chargeMsg}>
            {(adminType == ADMIN_TYPES[0]['value']) &&
              <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
                <DialogContentText>
                  Your organization will be charged for each external principal investigator.
                </DialogContentText>
              </RBAC>
            }
          </div>*/}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenInvite(false)} size="small" style={{ textTransform: 'none', color: 'white'}}>Cancel</Button>
          <Button variant="contained" onClick={() => inviteStaff()} color="secondary" size="small" disabled={!adminEmail} style={{ textTransform: 'none', color: 'white'}}>Invite</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={stopStudy}
        aria-describedby="alert-dialog"
        keepMounted
        onClose={handleCancel}
      >
        <DialogTitle>{"Are you sure you want to stop this study?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            By stopping this study patients won't be able to continue their journeys anymore and study onboarding will be disabled. If you still want to stop the study type 'STOP' below:
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            variant="outlined"
            placeholder="STOP"
            onChange={(e) => setStopText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCancel} size="small">Cancel</Button>
          <Button variant="contained" color="error" size="small" disabled={stopText!="STOP"} onClick={handleStop}>Stop</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={openInvitePatient}
        onClose={() => handleCloseInvites()}
      >
        <div className={classes.inviteBackground}>
          <Invite
              studyName={study && study.name}
              questions={study && study.questions}
              treatment={study && study.treatment}
              studyType={(props.type=='RTM') ? "RTM" : "RESEARCH" }
              cohorts={study && study.cohorts}
              controls={true}
              clear={clear}
              updatePatients={(p) => setNewPatients(p)}
              refreshPatients={() => getPatients(props.match.params.study_id)}
              onSubmit={(data) => invitePatients(data)}/>
        </div>
      </Dialog>


      <Dialog
        open={openDiscard}
        onClose={() => setOpenDiscard(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"You have pending patient invitations. Click on Invite All to send invitations."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to discard invitations?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDiscard(false)} style={{textTransform: 'none'}}>
            No
          </Button>
          <Button onClick={handleDiscard} style={{textTransform: 'none', color: 'red'}}  autoFocus>
            Discard
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        className={classes.surveyModal}
        open={study && patientID != 0}
        onClose={() => {
          setIsInternal(true)
          setPatientID(0)
          removeParams()
        }}>
        <AddtoJourney
          study={study}
          groups={groups}
          patient={patientID}
          internal={isInternal}
          onClose={(refresh) => closeAddModal(refresh)}
          />
      </Modal>

      <Modal
        className={classes.surveyModal}
        open={endSurveyOpen}
        onClose={() => setEndSurveyOpen(false)}>
        <SurveyDesigner
          questions={[]}
          surveyName={""}
          onQuestionUpdate={(qs) => setFinalQuestions(qs)}
          onSurveyNameUpdate={(name) => setFinalSurveyName(name)}
          onSurveySelected={(survey) => setFinalSurveyID(survey && survey.id)}
          onFinished={(status) => updateEndSurvey(status)}
          edit={true}/>
      </Modal>
      <Modal
        className={classes.surveyModal}
        open={clinicalEndSurveyOpen!=-1 }
        onClose={() => setClinicalEndSurveyOpen(-1)}>
        <SurveyDesigner
          questions={[]}
          surveyName={""}
          onQuestionUpdate={(qs) => setGroupFinalQuestions(qs)}
          onSurveyNameUpdate={(name) => updateClinicalEndSurveyName(name)}
          onSurveySelected={(survey) => setGroupFinalSurveyID(survey && survey.id)}
          onFinished={(status) => updateClinicalEndSurvey(status, clinicalEndSurveyOpen)}
          edit={true}/>
      </Modal>

    </div>

  );
}

withStyles(useStyles)(TabPanel)
// export default withStyles(useStyles)(StudyAnalysis);
