import { makeStyles } from "@material-ui/styles";
import bkgnd from "../../images/bkgnd.png";

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.customShadows.widget,
    padding: theme.spacing(5),
    textTransform: 'none'
  },
  infoForm: {

  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(1),
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  shortinput: {
    width: '45ch',
    backgroundColor: 'white'
  },
  description: {
    height: 500,
    width: '100%',
    resize: 'vertical',
    padding: theme.spacing(2),
    fontSize: 16,
    borderColor: 'lightgrey',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    borderRadius: 5
  },
  saveBtn: {
    display: "flex",
    width: '100%',
    justifyContent: 'flex-end',
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  cardRoot: {
    boxShadow: theme.customShadows.widget,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  cardBody: {
    padding: theme.spacing(2),
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '5px 10px 5px 10px',
    paddingHorizontal: '10px'
  },
  premiumText: {
    marginLeft: theme.spacing(1),
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 7px 1px 7px',
    backgroundColor: "#deffe7",
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  studyStatusEnd: {
    marginLeft: theme.spacing(1),
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 7px 1px 7px',
    backgroundColor: "#fff6e3",
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.warning.main,
    border: '1px solid'
  },
  pendingText: {
    marginLeft: theme.spacing(1),
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 7px 3px 7px',
    backgroundColor: "#fffaed",
    fontSize: 10,
    color: theme.palette.warning.main,
    border: '1px solid'
  },
  videoContainer: {
    width: '100%',
    position: 'relative',
  },
  videoDesc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  videoDuration: {
    marginTop: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: '1px 7px 3px 7px',
    borderRadius: 5,
    fontSize: 10,
    color: 'white'
  },
  dateContent: {
    color: 'grey',
    flexDirection: 'row',
    fontSize: 12,
  },
  videoTitle: {
    fontSize: 18,
    fontWeight: '600'
  },
  viewsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'grey',
  },
  viewCount: {
    margin: '0px 5px 0px 5px',
  },
  dropBox: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    fontSize: 12,
    textAlign: 'center',
    transition: 'border .24s ease-in-out'
  },
  dropBoxInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
  },
  imageCloseIcon: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'grey',
    top: 0,
    right: 0,
    zIndex: 10
  },
  youTubeContainer: {
    borderRadius: '3px',
    overflow: 'hidden'
  },

  designContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  dropAreaContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflow: 'scroll',
    background: theme.palette.background.default,
  },
  questionMenu: {
    flexDirection: 'column',
    width: '200px',
    padding: '10px',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid lightgrey',
    padding: '10px',
    width: '100%',
    height: '100%'
  },
  questionDropArea: {
    flexGrow: 1,
    overflowY: 'scroll',
    overflow: 'hidden',
    width: '100%',
  },
  dashedDrop: {
    flexGrow: 1,
    overflowY: 'scroll',
    overflow: 'hidden',
    width: '100%',
    border: "2px dashed lightgrey",
  },
  questionRow: {
    display: 'flex',
    position: 'relative',
    border: "1px solid lightgrey",
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  questionRowDisabled: {
    display: 'flex',
    position: 'relative',
    border: "1px solid lightgrey",
    flexDirection: 'column',
    backgroundColor: '#EBEBE4',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  cohortRow: {
    border: "1px solid lightgrey",
    backgroundColor: '#fff',
  },
  questionHeader: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
    width: '100%',
  },
  questionRowItem: {
    display: 'flex',
    fontWeight: '600',
    color: 'grey',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  questionDelete: {
    color: 'grey',
    marginLeft: theme.spacing(1)
  },
  optionalSwitch: {
    marginRight: theme.spacing(1)
  },
  optionalSwitchLabel: {
    color: 'grey',
    fontSize: 12,
    marginRight: theme.spacing(1)
  },
  inviteSwitchLabel: {
    color: 'grey',
    fontSize: 12,
    margin: theme.spacing(2)
  },
  topRightControl: {
    position: 'absolute',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    right: 10
  },
  questionType: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    alignContent: 'flex-start',
    lineHeight: 1.5,
    borderRadius: '3px',
    background: '#fff',
  },
  questionClone: {
    alignItems: 'center',
    padding: '5px',
    alignContent: 'flex-start',
    lineHeight: 1.5,
    borderRadius: '3px',
    background: '#fff',
  },

  questionModuleContainer: {
    display: 'block',
    flexDirection: 'column'
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'grey'
  },
  sliderDiv: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  designHeader: {
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(1,1,1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#E5F6FD'
  },
  designFooter: {
    display: 'flex',
    borderTop: '1px solid lightgrey',
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  studyInfoRow: {
    backgroundColor: '#E5F6FD',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid lightgrey',
    padding: '2px 10px 5px 10px',
  },
  cohortInfoRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex'
  },
  studyBody: {
    padding: theme.spacing(1),
  },
  defineCohort: {
    borderRadius: "5px",
    border: '1px solid lightgrey',
    paddingBottom: theme.spacing(4),
    overflow: 'hidden'
  },

  // invite:
  inviteContainer: {
    height: '100%',
    maxHeight: 800,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '5px',
    border: '1px solid lightgrey',
  },
  cohortMenu: {
    flexDirection: 'column',
    display: 'flex',
    width: '400px',
    padding: '10px',
  },
  cohortMenuFields: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  inviteHeader: {
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row'
  },
  patientEmailContainer: {
    borderLeft: '1px solid lightgrey',
    width: '100%',
    height: '100%',
  },
  patientEmailContainer2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
    height: '500px',
    backgroundColor: 'white',
    overflowY: 'scroll',
    overflow: 'hidden'
  },
  inviteInputContainer: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '5px',
    border: '1px solid lightgrey',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  inviteInputLabel: {
    display: 'flex',
    position: 'relative',
    color :'grey',
    fontSize: 14
  },
  //study analysis
  tabs: {
    backgroundColor: 'white',
    textTransform: 'none',
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
  },
  surveyNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadSurveyBtn: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
  },
  compareHeader: {
    margin: theme.spacing(1,0,0,0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  inviteBackground: {
    backgroundColor: theme.palette.background.default
  },
  studyContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  questionPreview: {
    padding: theme.spacing(1,1,1,1),
    margin: theme.spacing(1,0,1,0),
    width: '100%',
    borderRadius: 10,
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullScreen: {
    height: "80vh",
    width: "100%",
  },
  doneButton: {
    height: '100%'
  }
});

export default (useStyles)
