import * as urls from './stripe-urls';
import { apiClient } from './apiClient';


export async function get_plans(type) {
  let params = {
    study_type: type
  };
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

  let url = urls.PLANS() + "?" + query
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    null
  )
  return response.data
}

export async function create_checkout(plan_id, trial=false, path=null, dispatch) {

  let params = {
    return_path: path
  };
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

  let url = path ? urls.CHECKOUT() + "?" + query : urls.CHECKOUT()
  let response = await apiClient(
    url,
    'POST',
    {
      "plan_id": plan_id,
      "trial": trial
    },
    {},
    dispatch
  )
  return response
}

export async function get_portal(cus_id, dispatch) {
  let url = urls.PORTAL();
  let response = await apiClient(
    url,
    'GET',
    {
      customer: cus_id
    },
    {},
    dispatch
  )
  return response.data
}

export async function create_product(name, description, treatment_id, dispatch) {
  let url = urls.CREATE_PRODUCT();
  let response = await apiClient(
    url,
    'POST',
    {
      'name': name,
      'description': description,
      'treatment_id': treatment_id
    },
    {},
    dispatch
  )
  return response.data
}

export async function get_product(id) {
  let url = urls.GET_PRODUCT()
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    null
  )
  return response.data
}

export async function get_product_prices(id) {
  let url = urls.GET_PRODUCT_PRICES(id)
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    null
  )
  return response.data
}

export async function create_account_link(id) {
  let url = urls.CREATE_ACCOUNT_LINK()
  let response = await apiClient(
    url,
    'POST',
    {
      'account_id': id
    },
    {},
    null
  )
  return response.data
}
export async function get_connected_account(id) {
  let url = urls.GET_CONNECTED_ACCOUNT(id)
  let response = await apiClient(
    url,
    'GET',
    {
      'account_id': id
    },
    {},
    null
  )
  return response.data
}

export async function get_account_dashboard_link(id) {
  let url = urls.GET_ACCOUNT_DASHBOARD_LINK(id)
  let response = await apiClient(
    url,
    'GET',
    {
      'account_id': id
    },
    {},
    null
  )
  return response.data
}

export async function create_connected_sub(acc_id, customer_id) {
  let url = urls.CREATE_CONNECTED_SUB()
  let response = await apiClient(
    url,
    'POST',
    {
      'account_id': acc_id,
      'customer_id': customer_id
    },
    {},
    null
  )
  return response.data
}

// export async function update_orginfo(dispatch, data) {
//   let url = urls.ORG_INFO();
//   let response = await apiClient(
//     url,
//     'PUT',
//     data,
//     {},
//     dispatch
//   )
//   if (response.error) {
//     return null
//   } else {
//     return response.data;
//   }
// }
//
// export async function get_profile(dispatch) {
//   let url = urls.PROFILE();
//   let response = await apiClient(
//     url,
//     'GET',
//     {},
//     {},
//     dispatch
//   )
//   return response
// }
