import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TabPanel,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';

import classnames from "classnames";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import PostAddIcon from '@material-ui/icons/PostAdd';
// styles
import useStyles from "../study/styles";
import { withStyles } from '@material-ui/styles';
import { create_survey, get_survey, get_therapies } from '../../api/study';
import { get_communities, get_community_categories } from '../../api/doctors';
import { useUserState } from "../../context/UserContext";

import "../study/study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";


const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});



export default function Survey (props){
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     questions: [],
  //     surveyName: [],
  //     surveyID: null,
  //     surveys: [],
  //     openAdd: false
  //   };
  // }

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [therapies, setTherapies] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState([]);
  const [globalSurveys, setGlobalSurveys] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [surveyID, setSurveyID] = useState(false);

  function prepareSurveyData() {
    let survey = {};
    let payload_questions = [];
    survey.name = surveyName;
    survey.description = ' ';
    for (let i=0; i < questions.length; i++) {
      const q = questions[i];
      let d = {}
      d.type = q.type.toLowerCase();
      d.title = q.title;
      d.rank = i+1;
      d.options = q.options;
      d.optional = q.optional;
      if (d.type=='scale') {
        d.min_label = q.min_label;
        d.max_label = q.max_label;
      }
      payload_questions.push(d);
    }
    survey.questions = payload_questions;
    if (surveyID) {
      survey.survey_id = surveyID
    }
    if (survey.questions.length == 0 && !surveyID) {
      return null
    }
    return survey;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleClickAdd() {
    setOpenAdd(true)
  }

  function handleClickTherapy(id) {
    return
    props.history.push(`/therapies/${id}`);
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCommunityChanged = async (comm) => {
    // getCommunityCategories(comm.id)
    setCommunity(comm);
    const thrp = await get_therapies(comm && comm.id);
    setTherapies(thrp);
  }

  async function getCommunities(){
    var comms = await get_communities();
    if (comms && comms.length) {
      setCommunities(comms)
    } else {
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  async function init() {
    const therapies = await get_therapies();
    console.warn("therapies",therapies)
    setTherapies(therapies);
    getCommunities();
  }

  useEffect(() => {
    init()
  }, [])


  return (
      <div >
      <Autocomplete
        id="community-box"
        options={communities}
        value={community}
        getOptionLabel={option => option.name}
        onChange={(event, newValue) => {
          handleCommunityChanged(newValue);
        }}
        renderInput={params => (
          <div style={{marginBottom: 40}}>
          <TextField {...params} label="Community" margin="dense"  />
          </div>
        )}
      />
      <Grid container spacing={4} className={classes.productGrid} >
        {therapies && therapies.map((item, index) => {
          return(
            <Grid key={index} item lg={4} md={6} sm={6} xs={12}
              onClick={() => {handleClickTherapy(item.id)}}>
              <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                <div className={classes.cardBody}>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={8}>
                      <div className={classes.videoDesc}>
                        <div className={classes.videoTitle}>{item.name}</div>
                        <div className={classes.dateContent}>
                          created: {new Date(item.created_date).toLocaleDateString("en-US")}
                        </div>
                        <div >{item.description}</div>
                      </div>
                    </Grid>

                      <Grid item xs={12} sm={12} md={4} alignSelf="flex-end">
                        {/*<div className={classes.viewsContainer}>
                          <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                          <div className={classes.viewCount}>{4}</div>
                          studies
                        </div>*/}
                        <Chip label={item.community.name} color="success" variant="outlined" />
                      </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          )}
        )}
      </Grid>

      </div>
  );


}

// export default withStyles(useStyles)(Survey);
