import React from 'react';
import ApexCharts from "react-apexcharts";



export default class PatientPerformance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [ {
         name: 'Actual',
         data: [
           {
             x: 'Symptom 1',
             y: 12,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 14,
                 strokeWidth: 2,
                 strokeDashArray: 2,
                 strokeColor: '#775DD0'
               }
             ]
           },
           {
             x: 'Symptom 2',
             y: 44,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 54,
                 strokeWidth: 5,
                 strokeHeight: 10,
                 strokeColor: '#775DD0'
               }
             ]
           },
           {
             x: 'Symptom 3',
             y: 54,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 52,
                 strokeWidth: 10,
                 strokeHeight: 0,
                 strokeLineCap: 'round',
                 strokeColor: '#775DD0'
               }
             ]
           },
           {
             x: 'Symptom 4',
             y: 66,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 61,
                 strokeWidth: 10,
                 strokeHeight: 0,
                 strokeLineCap: 'round',
                 strokeColor: '#775DD0'
               }
             ]
           },
           {
             x: 'Symptom 5',
             y: 81,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 66,
                 strokeWidth: 10,
                 strokeHeight: 0,
                 strokeLineCap: 'round',
                 strokeColor: '#775DD0'
               }
             ]
           },
           {
             x: 'Symptom 6',
             y: 67,
             goals: [
               {
                 name: 'Cohort Average',
                 value: 70,
                 strokeWidth: 5,
                 strokeHeight: 10,
                 strokeColor: '#775DD0'
               }
             ]
           }
         ]
       }
      ],
      options: {
         series: [
         {
           name: 'Actual',
           data: [
             {
               x: 'Symptom 1',
               y: 12,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 14,
                   strokeWidth: 2,
                   strokeDashArray: 2,
                   strokeColor: '#775DD0'
                 }
               ]
             },
             {
               x: 'Symptom 2',
               y: 44,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 54,
                   strokeWidth: 5,
                   strokeHeight: 10,
                   strokeColor: '#775DD0'
                 }
               ]
             },
             {
               x: 'Symptom 3',
               y: 54,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 52,
                   strokeWidth: 10,
                   strokeHeight: 0,
                   strokeLineCap: 'round',
                   strokeColor: '#775DD0'
                 }
               ]
             },
             {
               x: 'Symptom 4',
               y: 66,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 61,
                   strokeWidth: 10,
                   strokeHeight: 0,
                   strokeLineCap: 'round',
                   strokeColor: '#775DD0'
                 }
               ]
             },
             {
               x: 'Symptom 5',
               y: 81,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 66,
                   strokeWidth: 10,
                   strokeHeight: 0,
                   strokeLineCap: 'round',
                   strokeColor: '#775DD0'
                 }
               ]
             },
             {
               x: 'Symptom 6',
               y: 67,
               goals: [
                 {
                   name: 'Cohort Average',
                   value: 70,
                   strokeWidth: 5,
                   strokeHeight: 10,
                   strokeColor: '#775DD0'
                 }
               ]
             }
           ]
         }
       ],
         chart: {
         height: 350,
         type: 'bar'
       },
       plotOptions: {
         bar: {
           horizontal: true,
         }
       },
       colors: ['#00E396'],
       dataLabels: {
         formatter: function(val, opt) {
           const goals =
             opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
               .goals

           if (goals && goals.length) {
             return `${val} / ${goals[0].value}`
           }
           return val
         }
       },
       legend: {
         show: true,
         showForSingleSeries: true,
         customLegendItems: ['Actual', 'Cohort Average'],
         markers: {
           fillColors: ['#00E396', '#775DD0']
         }
       }
       }


    };
  }


  render() {
    return (
      <div id="chart">
        <ApexCharts options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    )}
}
