import React, { useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import ApexLineChart from "../charts/components/ApexLineChart";
import BigStat from "./components/BigStat/BigStat";
import { renderActiveShape } from '../charts/Charts';

import { useStatsState } from "../../context/StatsContext";
import { useProductState, gotoProduct } from "../../context/ProductContext";
import { confirm_invite_to_study } from '../../api/study';

import RBAC from "../../components/RBAC";

var jwtDecode = require('jwt-decode');
const mainChartData = getMainChartData();
const SEFPieColors = ["chart_first", "chart_second", "chart_third", "chart_fourth", "chart_fifth"]

export default function DemoDashboard(props) {
  var classes = useStyles();
  var theme = useTheme();


  var [mainChartState, setMainChartState] = useState("monthly");
  var [activeIndex, setActiveIndexId] = useState(0);
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);

  var prodState = useProductState().state;
  var prodDispatch = useProductState().dispatch;
  var statState = useStatsState().state;
  var stats = statState.stats;
  var product_stats = stats && stats.product_stats;
  var community_stats = stats && stats.community_stats;

  const product_param = props.match.params.prod_id;

  useEffect(() => {
    function prodChange() {
      if(!isLoading && !prodState.isLoadingProduct && prodState.products.length){
        gotoProduct(
          prodDispatch,
          prodState,
          product_param,
          setIsLoading,
          setError
        )
      }
    }
    prodChange();
  }, [prodState, product_param])
  // local

  var sefPieData = []
  if (product_stats && product_stats.side_effects) {
    sefPieData = product_stats.side_effects.filter(sef => (sef.frequency > 0))
    let totalFreq = sefPieData.reduce(function (sum, sef) {
        return sum + sef.frequency;
    }, 0);

    if (sefPieData.length > 0) {
      let rest = sefPieData[0].total - totalFreq
      sefPieData = sefPieData.map((sef, index) => (
        {
          'name': sef.name,
          'value': sef.frequency,
          'color': SEFPieColors[index]
        }
      ))

      if (rest != 0 && rest != 100) {
        sefPieData.push({
          'name': 'others',
          'value': rest,
          'color': "primary"
        })
      }
    }
  }


  var surveyGraphData = [];
  let questions = new Set()
  let questions_list = [];

  var apexData = []
  var days_array = []
  if (product_stats && product_stats.survey_data) {
    var surveyData = {}
    let all = product_stats.survey_data;
    let days = new Set()

    for (let i = 0; i < all.length; i++) {
      if(!questions.has(all[i].question)) {
        surveyData[all[i].question] = []
        questions.add(all[i].question)
      }
      surveyData[all[i].question].push((all[i].avg).toFixed(2))
      days.add('day ' + (all[i].day_index + 1))
    }

    for (let q of questions) {
      var obj = {
        name: q,
        data: surveyData[q]
      }
      apexData.push(obj)
    }
    // convert days set to array
    days_array = Array.from(days)
    questions_list = Array.from(questions)

    for (let i = 0; i < days_array.length; i++) {
      let data_point = {}
      for (let q of questions) {
        data_point[q] = surveyData[q][i]
      }
      surveyGraphData.push(data_point)
    }
  }

  return (
    <>
      <PageTitle
        title={prodState.selectedProduct && prodState.selectedProduct.name}
        image={prodState.selectedProduct && prodState.selectedProduct.images.length > 0 && prodState.selectedProduct.images[0]}
      />
      {prodState.selectedProduct &&
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Widget
              title="Users using your product"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              disableWidgetMenu
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {product_stats ? product_stats.users_count : "-"}
                </Typography>
                <LineChart
                  width={55}
                  height={30}
                  data={[
                    { value: 10 },
                    { value: 15 },
                    { value: 10 },
                    { value: 17 },
                    { value: 18 },
                  ]}
                  margin={{ left: theme.spacing(2) }}
                >
                  <Line
                    type="natural"
                    dataKey="value"
                    stroke={theme.palette.success.main}
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Number of Journeys
                  </Typography>
                  <Typography size="md">{product_stats ? product_stats.journey_count : "-"}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Community Dominance
                  </Typography>
                  <Typography size="md">{(product_stats && community_stats) ? ((product_stats.users_count/(community_stats.total_users+0.00001)) * 100).toFixed(2) : "-"}%</Typography>
                </Grid>
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Reviews
                  </Typography>
                  <Typography size="md">{product_stats ? product_stats.review_count : "-"}</Typography>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Widget
              title="User Demographics"
              upperTitle
              className={classes.card}
              bodyClass={classes.fullHeightBody}
              disableWidgetMenu
            >
              <div className={classes.performanceLegendWrapper}>
                <div className={classes.legendElement}>
                  <Dot color="warning" />
                  <Typography
                    color="text"
                    colorBrightness="secondary"
                    className={classes.legendElementText}
                  >
                    Male
                  </Typography>
                </div>
                <div className={classes.legendElement}>
                  <Dot color="primary" />
                  <Typography
                    color="text"
                    colorBrightness="secondary"
                    className={classes.legendElementText}
                  >
                    Female
                  </Typography>
                </div>
              </div>
              <div className={classes.progressSection}>
                <Typography
                  size="md"
                  color="text"
                  colorBrightness="secondary"
                  className={classes.progressSectionTitle}
                >
                  Population Distribution
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={community_stats ? (community_stats.male_users/community_stats.total_users) * 100 : 0}
                  classes={{ barColorPrimary: classes.progressBar }}
                  className={classes.progress}
                />
              </div>
              {/*<div>
                <Typography
                  size="md"
                  color="text"
                  colorBrightness="secondary"
                  className={classes.progressSectionTitle}
                >
                  SDK
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={55}
                  classes={{ barColorPrimary: classes.progressBar }}
                  className={classes.progress}
                />
              </div>
              */}
            </Widget>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Widget title="Top Side Effects" upperTitle className={classes.card} disableWidgetMenu>
              <Grid container spacing={1}>
                <Grid item lg={10} md={8} sm={10} xs={12}>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart width={600} height={300}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={sefPieData}
                      cx={220}
                      cy={150}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={2}
                      dataKey="value"
                      onMouseEnter={(e, id) => setActiveIndexId(id)}
                    >
                    {sefPieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={theme.palette[entry.color].main}
                      />
                    ))}
                    </Pie>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      cx={220}
                      cy={150}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={2}
                      dataKey="value"
                      data={mock.pieChart}
                      onMouseEnter={(e, id) => setActiveIndexId(id)}>
                      {mock.pieChart.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[SEFPieColors[index]].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                </Grid>
                <Grid item lg={2} md={4} sm={2} xs={12}>
                  <div className={classes.pieChartLegendWrapper}>
                    {sefPieData.map(({ name, value, color }, index) => (
                      <div key={color} className={classes.legendItemContainer}>
                        <Dot color={color} />
                        <Typography style={{ whiteSpace: "nowrap" }}>
                          &nbsp;{name}&nbsp;
                        </Typography>
                        <Typography color="text" colorBrightness="secondary">
                          &nbsp;{value}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Widget title="Journey Survey" upperTitle noBodyPadding disableWidgetMenu>
              <ApexLineChart data={apexData} labels={days_array}/>
            </Widget>
          </Grid>
          <RBAC allowedRoles={["demo"]}>
            <React.Fragment>
              {mock.bigStat.map(stat => (
                <Grid item md={4} sm={6} xs={12} key={stat.product}>
                  <BigStat {...stat} />
                </Grid>
              ))}
            </React.Fragment>
          </RBAC>
        </Grid>
      }
    </>
  );
}

// #######################################################################


function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
