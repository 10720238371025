import React, { useEffect } from "react";
import { handleNotification } from '../pages/notifications/NotificationsClient';

import { get_stats } from '../api/stats';
import { useProductState } from './ProductContext';

var StatsContext = React.createContext();

function statReducer(state, action) {
  switch (action.type) {
    case "GOT_STATS":
      // handleNotification("request success")
      return { ...state, stats: action.data, product: action.product };
    case "NO_PRODUCTS":
      return { ...state, productError: true };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function StatsProvider({ children }) {

  var [state, dispatch] = React.useReducer(statReducer, {
    productError: false
  });

  var productState = useProductState().state;

  async function fetchData(prod) {
    if (!prod)
      dispatch({ type: "NO_PRODUCTS"})
    else {
      let stats = await get_stats(prod.id, prod.community.id)
      dispatch({ type: "GOT_STATS", data: stats, product: prod })
    }
  }

  useEffect(()=> {
    if (!productState.isLoadingProduct && productState.products && productState.products.length) {
      fetchData(productState.selectedProduct)
    }
  },[productState])

  return (
    <StatsContext.Provider value={{state, dispatch}}>
        {children}
    </StatsContext.Provider>
  );
}

function useStatsState() {
  var context = React.useContext(StatsContext);
  if (context === undefined) {
    throw new Error("useStatsState must be used within a StatsProvider");
  }
  return context;
}

export { StatsProvider, useStatsState };

// ###########################################################
