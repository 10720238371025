import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TextField
 } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import { Button } from "../../components/Wrappers";
import ManageTable from './ManageTable';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';

import { get_users, add_user, resend_link } from '../../api/manage';
import { handleNotification } from '../notifications/NotificationsClient';

import { useProductState, gotoProduct } from "../../context/ProductContext";
import { useUserState } from "../../context/UserContext";

import useStyles from "./styles";


export default function Manage(props) {

  var classes = useStyles();

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [selectedProd, setSelectedProd] = useState(false);
  var [users, setUsers] = useState([]);

  var [addFirstname, setAddFirstname] = useState("");
  var [addLastname, setAddLastname] = useState("");
  var [addEmail, setAddEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const user_dispatch = useUserState().dispatch;

  async function getUsers(){
    setIsLoading(true)
    var org_users = await get_users(0, 20, user_dispatch);
    if (org_users && org_users.length) {
      setUsers(org_users)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }


  useEffect(() => {
      getUsers()
  }, [])
  // local

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAdd = async () => {

    var resp = await add_user(addFirstname, addLastname, addEmail, user_dispatch);
    if(resp.data) {
      handleNotification("user add success")
      setOpen(false);
    } else {
      handleNotification("user add error")
      setOpen(false);
    }
  };

  const resendLink = async (email) => {
    var resp = await resend_link(email, user_dispatch);
    if(!resp.error) {
      handleNotification("resend link success")
    }
  };

  return (
    <>
      <PageTitle title="Manage organization users" />
      <div className={classes.addBtn}>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          style={{textTransform: 'none'}}
          size="small"
          color="secondary">
          Add a new user
        </Button>

      </div>
      <div>
        {users &&
          <ManageTable
            users={users}
            isLoading={isLoading}
            handleRefreshUsers={getUsers}
            resend={(email) => resendLink(email)}/>}
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            Organization users will have access to all the treatments and studies in your organization. Please only invite people within your organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="name"
            fullWidth
            required
            onChange={e => setAddEmail(e.target.value)}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="firstname"
                label="First Name"
                type="name"
                required
                fullWidth
                onChange={e => setAddFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="lastname"
                label="Last Name"
                type="email"
                required
                fullWidth
                onChange={e => setAddLastname(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{textTransform: 'none'}}
            size="small"
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleAdd()}
            variant="contained"
            style={{textTransform: 'none'}}
            size="small"
            color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
