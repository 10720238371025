import React, {useState, useEffect, useCallback} from 'react';
import { Button, Switch, Divider, Box, Modal, Chip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from "@material-ui/icons/Close";
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircle';
import useStyles from "./styles";
import { withStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as UpChunk from '@mux/upchunk';
import MoreInfo from '../../components/Dialog';
import { get_upload_url, upload_video, get_video } from '../../api/video';
import { get_communities, get_community_categories } from '../../api/doctors';
import { useProductState } from "../../context/ProductContext";
import SurveyDesigner from "./SurveyDesigner";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import DialogContent from '@material-ui/core/DialogContent';

import mock from "./mock";

// const communities = [
//   { title: 'Hyperhidrosis' },
//   { title: 'GWI' },
//   { title: 'Carpal Tunnel'},
//   { title: 'The Dark Knight' }
// ]

const surveyTypes = [
    {
      name: 'Patient'
    },
    {
      name: 'Investigator'
    }
]

const strings = {
  yt: {
    title: 'YouTube Embed URL',
    body: 'If you are uploading a video for this cohort please upload the video on YouTube and use the embed link. You can make your video unlisted on YouTube.'
  },
  type: {
    title: 'Product Type',
    body: 'Free treatment videos are accessible to all users and Premium videos will be unlocked for 30 days once the user subscirbes to the treatment.'
  },
  desc: {
    title: 'Treatment Description',
    body: 'Brief description of this treatment. Whome it is intended for, How effective it can be. This information will show up in treatment "Description" section.'
  },
  hiw: {
    title: 'How it works',
    body: 'Explain how users should approach this treatment, recommended frequency and intensity in applicable. This information will show up in treatment "How it works" section.'
  },
}
const MAX_VIDEO_DURATION_MIN = 1; // video time limitation in minutes

export default function BasicInfo(props) {
  const [clinicalSurveys, setClinicalSurveys] = useState(props.clinicalGroups)
  const [questions, setQuestions] = useState(props.questions);
  const [surveyID, setSurveyID] = useState(props.surveySelected);
  const [surveyName, setSurveyName] = useState(props.surveyName);
  const [surveyName_init, setSurveyName_init] = useState(props.initSurveyName);
  const [questions_init, setQuestions_init] = useState(props.initQuestions);
  const [surveyID_init, setSurveyID_init] = useState(props.initSurveySelected);
  const [surveyName_end, setSurveyName_end] = useState(props.endSurveyName);
  const [questions_end, setQuestions_end] = useState(props.endQuestions);
  const [surveyID_end, setSurveyID_end] = useState(props.endSurveySelected);
  const [surveyOpen, setSurveyOpen] = useState(false);
  const [initSurveyOpen, setInitSurveyOpen] = useState(false);
  const [endSurveyOpen, setEndSurveyOpen] = useState(false);
  const [clinicalSurveyOpen, setClinicalSurveyOpen] = useState(-1);
  const [clinicalInitSurveyOpen, setClinicalInitSurveyOpen] = useState(-1);
  const [clinicalEndSurveyOpen, setClinicalEndSurveyOpen] = useState(-1);
  const classes = useStyles();

  var prodState = useProductState().state;

  function updateSurveyName (name) {
    setSurveyName(name)
    props.onSurveyNameUpdate(name)
  }
  function updateInitSurveyName (name) {
    setSurveyName_init(name)
    props.onInitSurveyNameUpdate(name)
  }
  function updateEndSurveyName (name) {
    setSurveyName_end(name)
    props.onEndSurveyNameUpdate(name)
  }
  function updateSurveyID (id) {
    setSurveyID(id)
    props.onSurveySelected(id)
  }
  function updateInitSurveyID (id) {
    setSurveyID_init(id)
    props.onInitSurveySelected(id)
  }
  function updateEndSurveyID (id) {
    setSurveyID_end(id)
    props.onEndSurveySelected(id)
  }
  function updateQuestions (qs) {
    setQuestions(qs)
    props.onQuestionUpdate(qs)
  }
  function updateInitQuestions (qs) {
    setQuestions_init(qs)
    props.onInitQuestionUpdate(qs)
  }
  function updateEndQuestions (qs) {
    setQuestions_end(qs)
    props.onEndQuestionUpdate(qs)
  }
  function closeSurvey() {
    setSurveyOpen(false);
    setInitSurveyOpen(false);
    setEndSurveyOpen(false);
  }

  function updateClinicalSurveyName (i, name) {
    let newsurveys = clinicalSurveys
    newsurveys[i].longitudinal_survey.name = name
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalInitSurveyName (i, name) {
    let newsurveys = clinicalSurveys
    newsurveys[i].initial_survey.name = name
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalEndSurveyName (i, name) {
    let newsurveys = clinicalSurveys
    newsurveys[i].final_survey.name = name
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalSurveyID (i, id) {
    let newsurveys = clinicalSurveys
    newsurveys[i].longitudinal_survey.id = id
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalInitSurveyID (i, id) {
    let newsurveys = clinicalSurveys
    newsurveys[i].initial_survey.id = id
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalEndSurveyID (i, id) {
    let newsurveys = clinicalSurveys
    newsurveys[i].final_survey.id = id
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalQuestions (i, qs) {
    let newsurveys = clinicalSurveys
    newsurveys[i].longitudinal_survey.questions = qs
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalInitQuestions (i, qs) {
    let newsurveys = clinicalSurveys
    newsurveys[i].initial_survey.questions = qs
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function updateClinicalEndQuestions (i, qs) {
    let newsurveys = clinicalSurveys
    newsurveys[i].final_survey.questions = qs
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }
  function closeClinicalSurvey() {
    setClinicalSurveyOpen(-1);
    setClinicalInitSurveyOpen(-1);
    setClinicalEndSurveyOpen(-1);
  }

  function addClinicalSurvey() {
    const newSurveys = [
      ...clinicalSurveys,
      {
      name: "",
      longitudinal_survey: {
        questions: []
      },
      initial_survey: {
        questions: []
      },
      final_survey: {
        questions: []
      }
    }]
    setClinicalSurveys(newSurveys)
  }

  function setSurveyGroup(i, value) {
    let newsurveys = clinicalSurveys
    newsurveys[i].name = value
    setClinicalSurveys(newsurveys)
    props.onClinicalGroups(newsurveys)
  }

  function removeClinicalSurvey(index) {
    let temp_surveys = clinicalSurveys;
    if (index > -1) {
      temp_surveys.splice(index, 1);
    }
    setClinicalSurveys([...temp_surveys])
    props.onClinicalGroups(temp_surveys)
  }

  useEffect(() => {

  }, [])


  return (
    <React.Fragment>
      <div className={classes.defineCohort}>
        <div className={classes.studyInfoRow}>
            Longitudinal survey allows study creators to ask the same survey questions multiple times to track outcomes over time.<br/>
            Initial survey enables study creators to ask a one-time survey at the beginning of the study. The initial survey is optional.<br/>
            End survey allows study creators to send a final questionnaire at the end. The end survey is optional.
        </div>

        {(props.studyType != 'RTM') &&
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ color: 'white', textTransform: 'none', margin: '10px'}}
            onClick={addClinicalSurvey}
            startIcon={<PostAddIcon />}
          >
            Clinical Team Survey
          </Button>
        }

        <div className={classes.surveySelectContainer}>

        {
          <Grid container className={classes.questionRow} spacing={2} xs={12} sm={6} md={6} lg={4}>
            <div style={{alignSelf: 'flex-end'}}>
              <Chip
                size="small"
                label="Patient Survey"
                color="default"
              />
            </div>
            <Grid item >
              <Button
                variant="outlined"
                color={surveyID || (questions && questions.length>0) ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setSurveyOpen(true)}
                startIcon={surveyID || (questions && questions.length>0)? <CheckCircleIcon/> : <PostAddIcon />}
              >
                Longitudinal Survey
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="outlined"
                color={surveyID_init || (questions_init && questions_init.length>0) ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setInitSurveyOpen(true)}
                startIcon={surveyID_init || (questions_init && questions_init.length>0) ? <CheckCircleIcon/> : <PostAddIcon />}
              >
                Initial Survey (optional)
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="outlined"
                color={surveyID_end || (questions_end && questions_end.length>0) ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setEndSurveyOpen(true)}
                startIcon={surveyID_end || (questions_end && questions_end.length>0) ? <CheckCircleIcon/> : <PostAddIcon />}
              >
                End Survey (optional)
              </Button>
            </Grid>
          </Grid>
        }
        {clinicalSurveys.map((s, i) => (
          <Grid container className={classes.questionRow} spacing={2} key={i} xs={12} sm={6} md={6} lg={4}>
            <div style={{alignSelf: 'flex-end'}}>
              <Chip
                size="small"
                label="Provider Survey"
                color="default"
              />
            </div>
            <Grid item xs={10} sm={10} md={8} lg={8}>
              <TextField
                required
                id="group"
                name="group"
                label="survey group"
                margin="dense"
                placeholder="e.g. Doctors"
                variant="outlined"
                onChange={e => setSurveyGroup(i, e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item >
              <Button
                variant="outlined"
                color={s.longitudinal_survey.questions.length ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setClinicalSurveyOpen(i)}
                startIcon={s.longitudinal_survey.questions.length ? <CheckCircleIcon/> : <PostAddIcon />}
              >
                Longitudinal Survey
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="outlined"
                color={s.initial_survey.questions.length ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setClinicalInitSurveyOpen(i)}
                startIcon={s.initial_survey.questions.length ? <CheckCircleIcon/> : <PostAddIcon />}
              >
                Initial Survey (optional)
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="outlined"
                color={s.final_survey.questions.length ? "primary" : "default"}
                style={{ textTransform: 'none'}}
                onClick={() => setClinicalEndSurveyOpen(i)}
                startIcon={s.final_survey.questions.length ? <CheckCircleIcon/> : <PostAddIcon />}
              >
                End Survey (optional)
              </Button>
            </Grid>
            <div className={classes.questionDelete} onClick={() => removeClinicalSurvey(i)}><RemoveCircleOutlineIcon /></div>
          </Grid>
        ))}



        </div>
      </div>


      <Modal
        className={classes.surveyModal}
        open={initSurveyOpen || surveyOpen || endSurveyOpen}
        onClose={() => closeSurvey()}>
        <DialogContent>
          <SurveyDesigner
            questions={surveyOpen ? questions : initSurveyOpen ? questions_init : endSurveyOpen ? questions_end : []}
            surveyName={surveyOpen ? surveyName : initSurveyOpen ? surveyName_init : surveyName_end}
            surveyID={surveyOpen ? surveyID : initSurveyOpen ? surveyID_init : surveyID_end}
            onQuestionUpdate={(qs) => surveyOpen ? updateQuestions(qs) : initSurveyOpen ? updateInitQuestions(qs) : updateEndQuestions(qs)}
            onSurveyNameUpdate={(name) => surveyOpen ? updateSurveyName(name) : initSurveyOpen ? updateInitSurveyName(name) : updateEndSurveyName(name)}
            onSurveySelected={(survey) => surveyOpen ? updateSurveyID(survey && survey.id) : initSurveyOpen ? updateInitSurveyID(survey && survey.id) : updateEndSurveyID(survey && survey.id)}
            onFinished={() => closeSurvey()}/>
        </DialogContent>
      </Modal>

      <Modal
        className={classes.surveyModal}
        open={clinicalEndSurveyOpen>=0 || clinicalInitSurveyOpen>=0 || clinicalSurveyOpen>=0}
        onClose={() => closeClinicalSurvey()}>
        <DialogContent>
          <SurveyDesigner
            questions={clinicalSurveyOpen>=0 ? clinicalSurveys[clinicalSurveyOpen].longitudinal_survey.questions : (clinicalInitSurveyOpen>=0) ? clinicalSurveys[clinicalInitSurveyOpen].initial_survey.questions : (clinicalEndSurveyOpen>=0) ? clinicalSurveys[clinicalEndSurveyOpen].final_survey.questions : []}
            surveyName={clinicalSurveyOpen>=0 ? clinicalSurveys[clinicalSurveyOpen].longitudinal_survey.name : (clinicalInitSurveyOpen>=0) ? clinicalSurveys[clinicalInitSurveyOpen].initial_survey.name : (clinicalEndSurveyOpen>=0) ? clinicalSurveys[clinicalEndSurveyOpen].final_survey.name : ""}
            surveyID={clinicalSurveyOpen>=0 ? clinicalSurveys[clinicalSurveyOpen].longitudinal_survey.id : (clinicalInitSurveyOpen>=0) ?  clinicalSurveys[clinicalInitSurveyOpen].initial_survey.id : (clinicalEndSurveyOpen>=0) ? clinicalSurveys[clinicalEndSurveyOpen].final_survey.id : ""}
            onQuestionUpdate={(qs) => clinicalSurveyOpen>=0 ? updateClinicalQuestions(clinicalSurveyOpen, qs) : (clinicalInitSurveyOpen>=0) ? updateClinicalInitQuestions(clinicalInitSurveyOpen, qs) : updateClinicalEndQuestions(clinicalEndSurveyOpen, qs)}
            onSurveyNameUpdate={(name) => clinicalSurveyOpen>=0 ? updateClinicalSurveyName(clinicalSurveyOpen, name) : (clinicalInitSurveyOpen>=0) ? updateClinicalInitSurveyName(clinicalInitSurveyOpen, name) : updateClinicalEndSurveyName(clinicalEndSurveyOpen, name)}
            onSurveySelected={(survey) => clinicalSurveyOpen>=0 ? updateClinicalSurveyID(clinicalSurveyOpen, survey && survey.id) : (clinicalInitSurveyOpen>=0) ? updateClinicalInitSurveyID(clinicalInitSurveyOpen, survey && survey.id) : updateClinicalEndSurveyID(clinicalEndSurveyOpen, survey && survey.id)}
            onFinished={() => closeClinicalSurvey()}/>
        </DialogContent>
      </Modal>
    </React.Fragment>
  );
}

// export default withStyles(useStyles)(BasicInfo);
