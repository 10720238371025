import React, {useState, useEffect} from 'react';
import ApexCharts from "react-apexcharts";
import { Grid, Tabs, Tab, Box, FormControl, Divider, TextField, Typography, InputAdornment, OutlinedInput, MenuItem, Select } from "@material-ui/core";
import useStyles from "../styles";
import InputLabel from '@material-ui/core/InputLabel';
import Widget from "../../../components/Widget";

export default function Histogram(props) {

  var classes = useStyles();

  // const series = [{
  //       name: 'Net Profit',
  //       data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
  //     }, {
  //       name: 'Revenue',
  //       data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
  //     }, {
  //       name: 'Free Cash Flow',
  //       data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
  // }]

  let options = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      title: {
        text: 'options'
      },
      categories: []
    },
    yaxis: {
      title: {
        text: 'count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        }
      }
    }
  }

  const [series, cats] = convertToSeries(props.data_a, props.data_b)
  options['xaxis'].categories = cats

  return (
    <div id="chart">
      <ApexCharts key={"area"} options={options} series={series} type="bar" height={350} />
    </div>
  )
}


function convertToSeries (data_a, data_b) {

  if (!data_a) return [[],[]]
  let series_data = []
  let series_data_b = []
  let cats = []
  for (let i=0; i<data_a.length ;i++) {
    series_data.push(data_a[i].selected)
    if (data_b && (data_b.length > 0)) {
      series_data_b.push(data_b[i].selected)
    }
    cats.push(data_a[i].value)
  }

  let series = [{
    name: 'A',
    data: series_data
  }]
  if (data_b) {
    series.push(
      {
        name: 'B',
        data: series_data_b
      }
    )
  }
  return [series,cats]
}
