import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip,
  FormControl,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import mock from "./mock";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleQuestion from './SimpleQuestion';
import CheckBoxQuestion from './CheckBox';
import RadioQuestion from './RadioQuestion';
import ImageQuestion from './ImageQuestion';
import NumberQuestion from './NumberQuestion';
import TextQuestion from './TextQuestion';
import { handleNotification } from '../notifications/NotificationsClient';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
import {
  create_survey,
  get_survey,
  get_patient_journeys,
  end_journey,
  add_patient_entry } from '../../api/study';
import { useUserState } from "../../context/UserContext";

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";



const support_options = [
  {name: 'treatment'},
  {name: 'reports'},
  {name: 'billing and payment'},
  {name: 'account'},
  {name: 'other'}
]

const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const ITEMS = [
    {
        id: "1",
        type: 'scale'
    },
    {
        id: "2",
        type: 'multiselect'
    },
    {
        id: "3",
        type: 'image'
    },
    {
        id: "4",
        type: 'radio'
    },
    {
      id: "5",
      type: 'number'
    },
    {
      id: "6",
      type: 'text'
    }
];



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, id: `questions-${new Date().getTime()}` });
    return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

class AddtoJourney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'ITEMS': ITEMS,
      survey_name: props.surveyName,
      selectedSurvey: null,
      journeys: [],
      new_journey: false,
      is_init: false,
      questions: props.questions
    };
  }

  async componentDidMount() {
    const journeys = await get_patient_journeys(this.props.patient)
    this.setState({journeys: journeys || []})

  }

  onChangeType() {

  }

  handleClickAdd() {
  }

  updateQuestionData(index, data, type, rank) {
    let questions = this.state.questions;
    if (questions && (questions.length > 0)) {
      let optional = questions[index].optional
      if (type=='scale' || type=='radio' || type=='multiselect') {
          questions[index].values = data.value
      } else {
          questions[index].values = [data.value]
      }
      questions[index].optional = optional
      questions[index].type = type
      questions[index].rank = rank

      this.setState({questions: questions})
    }
  }

  updateQuestionOptional(index, optional) {
    let questions = this.state.questions;
    if (questions && (questions.length > 0)) {
      questions[index].optional = optional
      this.setState({questions: questions})
    }
  }

  renderQuestion(props, index) {
    switch (props.type) {
      case "scale":
        return <SimpleQuestion
          title={props.title}
          min={parseFloat(props.options[0])}
          max={parseFloat(props.options[props.options.length - 1])}
          size={props.options.length}
          step={parseFloat(props.options[1])-parseFloat(props.options[0])}
          options={props.options}
          answer={true}
          minLabel={props.min_label}
          maxLabel={props.max_label}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "scale", props.rank)}/>
      case "multiselect":
        return <CheckBoxQuestion
          title={props.title}
          answer={true}
          options={props.options}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "multiselect", props.rank)}/>
      case "radio":
        return <RadioQuestion
          title={props.title}
          answer={true}
          options={props.options}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "radio", props.rank)}/>
      case "image":
        return <ImageQuestion
          title={props.title}
          answer={true}
          index={index}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "image", props.rank)}/>
      case "number":
        return <NumberQuestion
          title={props.title}
          answer={true}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "number", props.rank)}/>
      case "text":
        return <TextQuestion
          title={props.title}
          answer={true}
          optional={props.optional}
          onDataChange={(data) => this.updateQuestionData(index, data, "text", props.rank)}/>
      default:
        return null;
    }
  }

  handleGroupPicked(survey) {
    this.setState({selectedSurvey: survey})

    if (survey.is_patient) {
      const found_patient_journey =this.state.journeys.find(item => {
         return item.clinical_study_investigator_group == null
      })
      this.setState({questions: survey.questions, new_journey: !found_patient_journey, is_init: survey.initial_survey, is_final: survey.end_survey})
      return
    }
    const found = this.state.journeys.find(item => {
       return item.clinical_study_investigator_group && (item.clinical_study_investigator_group.id == survey.id)
    })

    if (found) {
      if (found.end_date) {
        if (!found.final_survey_result && found.clinical_study_investigator_group.final_survey) {
          this.setState({questions: survey.final_survey.questions, new_journey: false, is_init: false, is_final: true})
        } else {
          this.setState({questions: [], new_journey: false, is_init: false, is_final: false})
        }
      } else {
        this.setState({questions: survey.longitudinal_survey.questions, new_journey: false, is_init: false, is_final: false})
      }
    } else {
      if (survey.initial_survey) {
        this.setState({questions: survey.initial_survey.questions, is_init: true, new_journey: true, is_final: false})
      } else {
        this.setState({questions: survey.longitudinal_survey.questions, is_init: false, new_journey: true, is_final: false})
      }
    }
    // this.props.onSurveySelected(value);
    // if (value.questions) {
    //   this.setState({questions: value.questions})
    //   // this.props.onQuestionUpdate(value.questions);
    // } else if (value.name == 'create new +') {
    //   this.setState({questions: []})
    // }
  }

  onEdit() {
    this.props.onSurveySelected(null);
    this.setState({selectedSurvey: null})
  }

  async submitForm(event) {
    event.preventDefault();
    let resp = null
    var payload = {
      title: '',
      body: '',
      tags: [],
      body_raw: '',
      private: true,
      optional: false,
    }
    if (!this.state.selectedSurvey.is_patient) {
      payload['clinical_study_investigator_group_id'] = this.state.selectedSurvey.id
    }
    if (this.state.new_journey) {
      if (this.state.selectedSurvey.initial_survey) {
        payload['initial_survey_result'] = this.state.questions
      } else {
        payload['survey_result'] = this.state.questions
      }
      resp = await add_patient_entry(this.props.patient, payload);
    } else if (this.state.is_final){
      let found = null
      if (this.state.selectedSurvey.is_patient) {
        found = this.state.journeys.find(item => {
           return item.clinical_study_investigator_group == null
        })
      } else {
        found = this.state.journeys.find(item => {
           return item.clinical_study_investigator_group && (item.clinical_study_investigator_group.id == this.state.selectedSurvey.id)
        })
      }

      if (found) {
        let journey_payload = {}
        journey_payload.description = found.description
        journey_payload.day_offset = found.day_offset
        journey_payload.title = found.title
        journey_payload['final_survey_result'] = this.state.questions
        resp = await end_journey(journey_payload, found.id);
      }

    } else {
      payload['survey_result'] = this.state.questions
      resp = await add_patient_entry(this.props.patient, payload);
    }

    console.warn("aaaaaa",resp)
    if (resp.status == 200) {
      handleNotification("Response saved")
      this.props.onClose(true)
    } else {
      let details = resp.msg_detail && resp.msg_detail.survey_result
      if (Array.isArray(details)) {
        for (const detail of details) {
          if (Object.keys(detail).length) {
            handleNotification('Required Fields')
            break
          }
        }
      }
    }
    // this.props.onQuestionUpdate(this.state.questions);
    // setTimeout(function() {
    //   this.props.onFinished(true)
    // }.bind(this), 1000)
  }

  render(){
    const { classes, internal } = this.props;
    const { selectedSurvey, survey_name, journeys, is_init, is_final } = this.state;
    let survey_list = []

    const found =journeys.find(item => {
       return item.clinical_study_investigator_group == null
    })

    if (found) {
      if (found.end_date) {
        if (!found.final_survey_result && found.clinical_study.final_survey) {
          survey_list  = internal ? [...this.props.groups, Object.assign(this.props.study.final_survey, {'is_patient': true, 'name': 'Patient Journey', 'end_survey': true})] : this.props.groups
        } else {
          survey_list = this.props.groups
        }
      } else {
        survey_list  = internal ? [...this.props.groups, Object.assign(this.props.study.survey, {'is_patient': true, 'name': 'Patient Journey'})] : this.props.groups
      }
    } else {
      if (this.props.study.initial_survey) {
        survey_list  = internal ? [...this.props.groups, Object.assign(this.props.study.initial_survey, {'is_patient': true, 'name': 'Patient Journey', 'initial_survey': true})] : this.props.groups
      } else {
        survey_list  = internal ? [...this.props.groups, Object.assign(this.props.study.survey, {'is_patient': true, 'name': 'Patient Journey'})] : this.props.groups
      }
    }


    return (
      <form className={classes.fullScreen} onSubmit={(event) => this.submitForm(event)}>
      <div className={classes.designContainer}>
        <div className={classes.designHeader}>

          {(this.props.groups && !this.props.controls) &&
            <Grid item xs={6} sm={6} md={6}>
              <div>
                <Autocomplete
                  id="surveys-box"
                  fullWidth
                  value={this.state.selectedSurvey}
                  style={{maxWidth: '200px'}}
                  options={survey_list}
                  getOptionLabel={option => option && option.name}
                  onChange={(event, newValue) => {
                    if(newValue){
                      this.handleGroupPicked(newValue);
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} fullWidth label="Select Form" variant="outlined" margin="dense" />
                  )}
                />
              </div>
            </Grid>
          }

          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{color: 'white', textTransform: 'none'}}
            disabled={!(this.state.questions && (this.state.questions.length > 0))}
            >Submit</Button>

        </div>

        <div className={classes.dropAreaContainer}>
          <div className={classes.questionMenu}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {is_init && <Chip size="small" label="Initial Survey" />}
              {is_final && <Chip size="small" label="End Survey" />}
            </div>
          </div>

          <div className={classes.questionContainer}>
                  <div className={classes.questionDropArea}>
                      {this.state.questions && this.state.questions.map(
                            (item, index) => (
                              <div key={item.title}>
                              {this.renderQuestion(item, index)}
                              </div>
                      ))}
                  </div>
            </div>
          </div>


        {(this.props.controls) && <div className={classes.designFooter}>
          <Button
            variant="contained"
            size="small"
            style={{textTransform: 'none', marginRight: 10}}
            onClick={() => this.props.onFinished(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{color: 'white', textTransform: 'none'}}
            disabled={!survey_name || !(this.state.questions && (this.state.questions.length > 0))}
            >Create</Button>
        </div>}
      </div>
      </form>

    );
  }

}

export default withStyles(useStyles)(AddtoJourney);
