import * as urls from './urls';
import { apiClient } from './apiClient';


export async function get_users(page, limit, dispatch) {
  let url = urls.ORG_USER();
  let response = await apiClient(
    url,
    'GET',
    {},
    {
      page: page,
      limit: limit
    },
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function add_user(first_name, last_name, email, dispatch) {
  let url = urls.ORG_USER();
  let response = await apiClient(
    url,
    'POST',
    {
      first_name: first_name,
      last_name: last_name,
      email: email
    },
    {},
    dispatch
  )
  console.warn("ADD_USER (api):     ", response)
  return response
}

export async function delete_user(id, dispatch) {
  let url = urls.ORG_USER() + '/' + id;
  let response = await apiClient(
    url,
    'DELETE',
    {},
    {},
    dispatch
  )
  return response
}

export async function resend_link(email, dispatch) {
  let url = urls.ORG_USER();
  let response = await apiClient(
    url + '?email=' + encodeURIComponent(email),
    'PUT',
    {},
    {},
    dispatch
  )
  console.warn("RESEND_LINK (api):     ", response)
  return response
}


export async function get_orginfo(dispatch) {
  let url = urls.ORG_INFO();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function update_orginfo(dispatch, data) {
  let url = urls.ORG_INFO();
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function get_profile(dispatch) {
  let url = urls.PROFILE();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response
}
