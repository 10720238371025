export default {
  routePermissions:  {
    '/dashboard': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/study': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/rtm': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/newstudy': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/creatertm': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/orders': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/promotions': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/treatments': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/account': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/profile': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/insight': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/surveylibrary': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/manage': ['admin', 'demo', 'RESEARCH_ADMIN'],
    '/support': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/patient': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/survey_results': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/forms': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    '/notifications': ['demo']
  },
  layoutPermissions:  {
    'Dashboard': ['admin', 'demo' ,'RESEARCH_ADMIN'],
    'Orders': ['admin', 'demo'],
    'Promotions': ['admin', 'demo'],
    'Studies': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    'Survey Library': ['admin', 'demo', 'RESEARCH_ADMIN'],
    'Library': ['admin', 'demo', 'RESEARCH_ADMIN'],
    'Edenos Insight': ['admin', 'demo'],
    'Account': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    'Manage Users': ['admin', 'demo', 'RESEARCH_ADMIN'],
    'Support': ['admin', 'demo', 'RESEARCH_ADMIN', 'RESEARCH_COORDINATOR'],
    'Treatments': ['admin', 'demo', 'RESEARCH_ADMIN']
  },
  layoutOrgPermissions:  {
    'Dashboard': ['RESEARCH'],
    'Orders': ['RESEARCH'],
    'Promotions': ['RESEARCH'],
    'Studies': ['RESEARCH', 'DELEGATED'],
    'RTM': ['RESEARCH'],
    'Survey Library': ['RESEARCH'],
    'Library': ['RESEARCH'],
    'Edenos Insight': ['RESEARCH'],
    'Account': ['RESEARCH', 'DELEGATED'],
    'Manage Users': ['RESEARCH'],
    'Support': ['RESEARCH', 'DELEGATED'],
    'Treatments': ['RESEARCH']
  }
}
