import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { Page, Document, StyleSheet, Image, Text, View } from "@react-pdf/renderer";
import InvoiceTableHeader from './header';
import QuestionHeader from './questionHeader';
import DayHeader from './dayHeader';
// import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './footer';
import logo from "../../../images/Edenos Logo.jpg";

// import './vendor/normalize.css';
// import './global.css';
import './report.css';


const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 50,
        paddingBottom: 100,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 70,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    footerLogo: {
        width: 22,
        height: 28,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
      marginTop: 24,
      fontWeight: 500,
      marginBottom: 24
    },
    reportTitle: {
        color: 'black',
        fontSize: 12,
        fontWeight: 700,
    },
    subTitle: {
      color: 'black',
      fontSize: 12,
    },
    sectionContainer: {
      marginTop: 30,
      marginBottom: 20
    },
    sectionTitle: {
        fontSize: 12,
        fontWeight: 'semibold'
    },
    patientIDContainer: {
      flexDirection: 'row',
      marginTop: 36,
      justifyContent: 'flex-start'
    },
    reportDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 120
    },
    headerContainer: {
        marginTop: 36,
        justifyContent: 'flex-start',
        width: '50%'
    },
    row:{
        display: 'flex',
        flexDirection: 'row',
    },
    bullet:{
        height: '100%',
    },
    footerContainer: {
      left: 50,
      right: 50,
      position: 'absolute',
      bottom: 30
    },
    footerLogoContainer: {
      width: 50,
      marginRight: 30
    },
    footer: {
      width: '100%',
      flex: 1,
      borderTopWidth: 1,
      paddingTop: 10,
      borderTopColor: 'grey',
      flexDirection: 'row'
    },
    footerText: {
      fontSize: 8,
      color: 'grey'
    }
});




const Section = ({ title }) => (
    <View style={styles.sectionContainer}>
        <Text style={styles.sectionTitle}>{title}</Text>
    </View>
);

const Title = ({ title, sub }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
        {sub && <Text style={styles.subTitle}>{sub}</Text>}
    </View>
);

const ListItem = ({ style, children }) => {
    return (
        <View style={styles.row}>
            <View style={styles.bullet}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text style={{fontWeight: style=='bold' ? 'bold' : 'regular'}}>{children}</Text>
        </View>
    )
}

const Report = ({ patient, date }) => {
    var date = new Date(date).toISOString().split('T')[0]
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap >
                <Image style={styles.logo} src={logo} />
                <Title title={'REMOTE MONITORING SERVICES'} sub={'Patient Consent and Financial Authorization'}/>

                <Text>Remote Patient Monitoring/Remote Therapeutic Monitoring (“RPM/RTM”) is the use of a digital technology to collect your health data outside of a clinical setting (for example, while you are at home or at work) and transmit that data to your healthcare provider for evaluation. The type of health data collected may include weight and other physiological data that can help your physician or other healthcare provider monitor your health and make treatment recommendations. IF YOU DO NOT UNDERSTAND OR AGREE TO ANY OR ALL OF THE ITEMS BELOW, DO NOT SIGN THIS AGREEMENT.
                </Text>

                <Section title="Acknowledgement" />
                <Text style={{marginBottom: 10}}>By signing below, you acknowledge the following:</Text>

                <View>
                   <ListItem>Your physician or other healthcare provider has explained to you what RPM/RTM means, the type of health data that will be collected, and how it will be used in your care;</ListItem>
                   <ListItem>You are aware that your health data will be collected and transmitted digitally from an RPM/RTM technology to your healthcare provider in a safe and secure manner to maintain the confidentiality of your healthcare information; </ListItem>
                   <ListItem>You will not transmit or allow to be transmitted the health data of any individual other than your own;</ListItem>
                   <ListItem>You will not intentionally tamper with any RPM/RTM device used in connection with your RPM/RTM services;</ListItem>
                   <ListItem>Your physician or healthcare provider is not responsible for inaccuracies in the health data transmitted;</ListItem>
                   <ListItem>You consent to the use of RPM/RTM services as part of your care and treatment;</ListItem>
                   <ListItem>You are responsible for all applicable copay, coinsurance, deductible and non-covered amounts; and</ListItem>
                   <ListItem>RPM/RTM services will be billed following each month the services were provided in;</ListItem>
                   <ListItem>You have the right to withdraw this consent at any time;</ListItem>
                   <ListItem style="bold">RPM/RTM services are NOT emergency services and your data WILL NOT BE MONITORED 24/7. If you think you are experiencing a medical emergency, CALL 911 IMMEDIATELY.</ListItem>
                </View>


                <Section title="Assignment of Benefit" />
                <Text style={{marginBottom: 24}}>You hereby assign benefits payable for the eligible claims to the physician or other healthcare provider responsible for submitting your claims electronically to the group benefits plan and you authorize the insurer/plan administrator to issue payment directly to the physician or other healthcare provider. In the event your claim(s) are declined by the insurer/plan administrator, you understand that you remain responsible for payment to the physician or other healthcare provider for any services rendered and/or supplies provided. By signing below, you acknowledge that you have read and understand all of the above and you consent to receive RPM/RTM services from your healthcare provider. </Text>

                <Text>Patient Name/ Signature:  {patient}</Text>
                <Text>Date:  {date}</Text>
                <View style={styles.footerContainer} render={({ pageNumber, totalPages }) => (
                  <View style={styles.footer}>
                    <View style={styles.footerLogoContainer}>
                      <Text style={styles.footerText}>
                        {`page ${pageNumber} / ${totalPages}`}
                      </Text>
                    </View>
                    <Text style={styles.footerText}>
                      Generated from Edenos Clinical Platform. Copyright © 2024 Edenos.
                    </Text>
                  </View>
                )} fixed />
            </Page>
        </Document>
    );
}


export default Report;
