import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Box,
  Paper,
  Dialog,
  Divider,
  Chip
} from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import classnames from "classnames";
import Widget from "../../components/Widget";
import ViewIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddStudy from './AddStudy';
import RBAC from '../../components/RBAC';
// styles
import useStyles from "./styles";
import { withStyles } from '@material-ui/styles';
import { create_study, get_study, get_rtms } from '../../api/study';
import { useUserState } from "../../context/UserContext";

import "./study.css";
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";


const styles = theme => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});


export default function RTM(props) {
  var classes = useStyles();
  const user_dispatch = useUserState().dispatch;

  var [isLoading, setIsLoading] = useState(true);
  var [studies, setStudies] = useState([]);



  function onChangeType() {

  }

  function handleClickAdd() {
    props.history.push("/creatertm");
  }

  function handleClickStudy(id) {
    props.history.push(`/rtm/${id}/overview`);
  }

  async function init() {
    const rtms = await get_rtms(user_dispatch);
    setStudies(rtms);
    setIsLoading(false);
  }

  useEffect(() => {
    init()
  }, [])

  return (
        <div className={classes.root}>

          <div className={classes.addBtn}>
            <RBAC allowedRoles={['admin', 'demo', 'RESEARCH_ADMIN']}>
              <Button
                onClick={() => handleClickAdd()}
                variant="contained"
                style={{ textTransform: 'none', color: 'white'}}
                startIcon={<PostAddIcon />}
                color="secondary">
                New RTM
              </Button>
            </RBAC>
          </div>

          {(isLoading) ?
            <div className={classes.centerRow}>
              <CircularProgress color="primary"/>
            </div>
            :
            (studies) && <Grid container spacing={4} className={classes.studyContainer} >
                  {studies.map((item, index) => {
                    const created_date = new Date(item.first_entry_at);
                    const end_date = item.end_date ? new Date(item.end_date) : '';
                    const options = { year: 'numeric', month: 'short', day: 'numeric' };
                    return(
                    <Grid key={item.id} item lg={4} md={6} sm={6} xs={12}
                      onClick={() => {handleClickStudy(item.id)}}>
                      <Paper className={classes.cardContainer} classes={{ root: classes.cardRoot }}>
                        <div className={classes.cardHeader}>
                          <div>{item.organization && `${item.organization.name}  |  `}{item.treatment.name || "Treatment"}</div>
                          {item.end_date ?
                            <div className={classes.studyStatusEnd}>ended</div>
                            :
                            <div className={classes.premiumTextRTM}>active</div>
                          }
                        </div>
                        <Divider />
                        <div className={classes.cardBody}>
                          <Grid
                            container
                            direction="row"
                            justifycontent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item sm={7}>
                              <div className={classes.videoDesc}>
                                <div className={classes.videoTitle}>{item.name}</div>
                                <div className={classes.dateContent}>
                                  started: {created_date.toLocaleDateString('en-us', options)}
                                </div>
                                {item.end_date &&
                                  <div className={classes.dateContent}>
                                    ended: {end_date.toLocaleDateString('en-us', options)}
                                  </div>
                                }
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={classes.studyCardFooter}>
                          <div className={classes.bottom}>
                            <Grid
                              container
                              direction="row"
                              justifycontent="flex-end"
                              alignItems="flex-end"
                              spacing={2}
                            >
                              <Grid item xs={6}>
                                <div className={classes.viewsContainer}>
                                  <PeopleIcon style={{ fontSize: 14, color: "grey"}}/>
                                  <div className={classes.viewCount}>{item.cohorts.length}</div>
                                  {item.cohorts.length > 1 ? 'cohorts' : 'cohort'}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={classes.viewsContainer}>
                                  <PersonIcon style={{ fontSize: 14, color: "grey"}}/>
                                  <div className={classes.viewCount}>{item.patients_count}</div>
                                  enrolled {item.patients_count > 1 ? "patients" : "patient"}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  )
                })}
            </Grid>
          }

        </div>
    );


}

// export default withStyles(useStyles)(Study);
