import tinycolor from "tinycolor2";

const primary = "#2bc77d";
const secondary = "#2b74c7";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const chart_first = "#003f5c";
const chart_second = "#58508d";
const chart_third = "#bc5090";
const chart_fourth = "#ff6361";
const chart_fifth = "#ffa600";

const lightenRate = 7.5;
const darkenRate = 15;


const themeColors = {
  primary: {
    main: primary,
    light: tinycolor(primary)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(primary)
      .darken(darkenRate)
      .toHexString(),
  },
  secondary: {
    main: secondary,
    light: tinycolor(secondary)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(secondary)
      .darken(darkenRate)
      .toHexString(),
    contrastText: "#FFFFFF",
  },
  warning: {
    main: warning,
    light: tinycolor(warning)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(warning)
      .darken(darkenRate)
      .toHexString(),
  },
  success: {
    main: success,
    light: tinycolor(success)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(success)
      .darken(darkenRate)
      .toHexString(),
  },
  info: {
    main: info,
    light: tinycolor(info)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(info)
      .darken(darkenRate)
      .toHexString(),
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  chart_first: {
    main: chart_first,
    light: tinycolor(chart_first)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(chart_first)
      .darken(darkenRate)
      .toHexString(),
  },
  chart_second: {
    main: chart_second,
    light: tinycolor(chart_second)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(chart_second)
      .darken(darkenRate)
      .toHexString(),
  },
  chart_fourth: {
    main: chart_fourth,
    light: tinycolor(chart_fourth)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(chart_fourth)
      .darken(darkenRate)
      .toHexString(),
  },
  chart_third: {
    main: chart_third,
    light: tinycolor(chart_third)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(chart_third)
      .darken(darkenRate)
      .toHexString(),
  },
  chart_fifth: {
    main: chart_fifth,
    light: tinycolor(chart_fifth)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(chart_fifth)
      .darken(darkenRate)
      .toHexString(),
  },
};

export default themeColors;
