import React, {useState, useEffect} from 'react';
import ApexCharts from "react-apexcharts";
import { Grid, Tabs, Tab, Box, FormControl, Divider, TextField, Typography, InputAdornment, OutlinedInput, MenuItem, Select } from "@material-ui/core";
import useStyles from "../styles";
import InputLabel from '@material-ui/core/InputLabel';
import Widget from "../../../components/Widget";

export default function Series(props) {

  var classes = useStyles();

  // const series = [{
  //       name: 'Net Profit',
  //       data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
  //     }, {
  //       name: 'Revenue',
  //       data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
  //     }, {
  //       name: 'Free Cash Flow',
  //       data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
  // }]

  let options = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: props.study_type == 'RTM' ? '' : 'Average of cohort at each journey week',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      type: "numeric",
      title: {
        text: props.is_day ? 'Journey day' : 'Journey week'
      },
      labels: {
        formatter: function (value) {
          return `${(Number(value) + 1)}`;
        }
      }
    },
    yaxis: {
      title: {
        text: 'Average'
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }

  // const [series, cats] = convertToSeries(props.series_a, props.series_b)
  // options['xaxis'].categories = cats
  let series = [{
      name: 'A',
      data: props.series_a
    }
  ]
  if (props.series_b) {
    series.push({
      name: 'B',
      data: props.series_b
    })
  }
  if (props.question && props.question.options) {
    options['yaxis'].min = Number(props.question.options[0])
    options['yaxis'].max = Number(props.question.options[props.question.options.length - 1])

  }
  options['xaxis'].min = Number(props.series_a[0]["x"])
  options['xaxis'].tickAmount = Number(props.series_a[props.series_a.length - 1]["x"]) - Number(props.series_a[0]["x"])
  return (
    <div id="chart">
      <ApexCharts key={"area"} options={options} series={series} type="line" height={350} />
    </div>
  )
}


function convertToSeries (data_a, data_b) {

  if (!data_a) return [[],[]]
  let series_data = []
  let cats = []
  for (let i=0; i<data_a.length ;i++) {
    series_data.push(data_a[i].y)
    cats.push(data_a[i].x + 1)
  }

  let series = [{
    name: 'All Cohorts',
    data: series_data
  }]
  return [series,cats]
}
