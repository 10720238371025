import React, {useState, useEffect, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CloseIcon from "@material-ui/icons/Close";
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import useStyles from "./styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import * as UpChunk from '@mux/upchunk';
import MoreInfo from '../../components/Dialog';
import { get_upload_url, upload_video, get_video } from '../../api/video';
import { get_communities, get_community_categories } from '../../api/doctors';
import { upload_photo } from '../../api/treatment';
import { BootstrapInput } from '../../components/BootstrapInput';
import NativeSelect from '@material-ui/core/NativeSelect';
import fs from 'fs';

// const communities = [
//   { title: 'Hyperhidrosis' },
//   { title: 'GWI' },
//   { title: 'Carpal Tunnel'},
//   { title: 'The Dark Knight' }
// ]

const strings = {
  yt: {
    title: 'YouTube Embed URL',
    body: 'If you are uploading a free content please use a YouTube Embed URL. You can also make your video unlisted on YouTube.'
  },
  type: {
    title: 'Availability',
    body: `If your product is still in study phase and you don't want it to appear on public page set this to "study"`
  },
}
const MAX_VIDEO_DURATION_MIN = 1; // video time limitation in minutes

export default function BasicInfo(props) {
  const [prodType, setProdType] = useState(props.type);
  const [available, setAvailable] = useState(props.available);
  const [community, setCommunity] = useState(props.community);
  const [name, setName] = useState(props.name);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [photoURL, setPhotoURL] = useState(props.image);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
  const [videoPlaybackId, setVideoPlaybackId] = useState(null);
  const [youTubeURL, setYouTubeURL] = useState(props.yt_url);
  const [uploadId, setUploadId] = useState('');
  const [progress, setProgress] = useState(0);
  const [isPreparing, setIsPreparing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [communities, setCommunities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selCats, setSelCats] = useState(new Set(props.selectedCats));
  const [base64, setBase64] = useState(null);

  const selectedCats = new Set(props.selectedCats);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({maxFiles:1, accept: 'image/*'})

  var classes = useStyles();


  const handleTypeChanged = (type) => {
    props.onChangeProdType(type);
    setProdType(type);
  }
  const handleAvailChanged = (type) => {
    props.onChangeYTURL("");
    setYouTubeURL("");
    props.onChangeAvailable(type);
    setAvailable(type);
  }
  const handleNameChanged = (name) => {
    props.onChangeProdName(name);
    setName(name);
  }
  const handleYTChanged = (url) => {
    props.onChangeYTURL(url);
    setYouTubeURL(url);
  }
  const handleImageChanged = (url) => {
    props.onChangeProdImage(url);
  }
  const handleCommunityChanged = (comm) => {
    setCategories([]);
    selectedCats.clear();
    setSelCats(selectedCats);
    props.onChangeSelectedCats(selectedCats);

    props.onChangeCommunity(comm);
    getCommunityCategories(comm.id)
    setCommunity(comm);
  }

  const handleCatChanged = (cat, checked) => {
    if (checked) {
      selectedCats.add(cat.id);
    } else {
      selectedCats.delete(cat.id);
    }
    setSelCats(selectedCats);
    props.onChangeSelectedCats(selectedCats);
  }

  useEffect(() => {
      getCommunities();
      if (community.id) {
        getCommunityCategories(community.id)
      }
  }, [])

  useEffect(() => {
    if (isPreparing) {
      props.onChangeVideoURL(uploadId)
    }
  }, [isPreparing])

  async function getCommunities(){
    var comms = await get_communities();
    if (comms && comms.length) {
      setCommunities(comms)
    } else {
    }
  }

  async function getCommunityCategories(id){
    var cats = await get_community_categories(id);
    if (cats && cats.length) {
      setCategories(cats)
    } else {
    }
  }

  async function getVideo() {
    const resp = await get_video(uploadId);
    if (resp) {
      setVideoPlaybackId(resp.playback_id);
    }
  }

  const createUpload = async () => {
    try {
      return fetch('http://127.0.0.1:4242/create-upload-url', {
        method: 'POST',
      })
        .then((res) => res.json())
        .then(({ id, url }) => {
          setUploadId(id);
          return url;
        });
    } catch (e) {
      console.error('Error in createUpload', e); // eslint-disable-line no-console
      setErrorMessage('Error creating upload');
      return Promise.reject(e);
    }
  };

  const startFileValidation = (file) => {
    return new Promise((resolve, reject) => {
      // Attempt to load the file as a video element and inspect its duration
      // metadata. This is not an authoritative check of video duration, but
      // rather intended to serve as just a simple and fast sanity check.
      if (!file.type.includes("video")) {
        console.warn(`file type (${file.type}) does not look like video!`);
        resolve();
      }

      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        URL.revokeObjectURL(video.src);
        if (video.duration !== Infinity && video.duration > MAX_VIDEO_DURATION_MIN*60) {
          const dur = Math.round(video.duration * 100) / 100;
          reject(`file duration (${dur.toString()}s) exceeds allowed maximum (${MAX_VIDEO_DURATION_MIN}min)!`);
        }
        resolve();
      };
      video.onerror = function() {
        // The file has a video MIME type, but we were unable to load its
        // metadata for some reason.
        console.warn("failed to load video file metadata for validation!");
        URL.revokeObjectURL(video.src);
        resolve();
      };
      video.src = URL.createObjectURL(file);
    });
  };

  async function startUpload(file) {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    setFile(file);

    try {
      const upChunk = UpChunk.createUpload({
        endpoint: createUpload,
        maxFileSize: 2**21, // 2GB
        chunkSize: 5120, // Uploads the file in ~5mb chunks
        file: file,
      });

      upChunk.on('error', (err) => {
        console.warn(err.detail);
      });

      upChunk.on('progress', (progressEvt) => {
        setProgress(Math.floor(progressEvt.detail));
      });

      upChunk.on('success', async () => {
        setIsPreparing(true);
      });
    } catch (err) {
      console.warn(err.toString());
    }
  };

  async function startUploadThumbnail(file) {
    if (isUploadingThumbnail) {
      return;
    }
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const data = await upload_photo(bodyFormData);
    setPhotoURL(data && data.photo_url);
    handleImageChanged(data && data.photo_url);
    setIsUploadingThumbnail(true);
  };


  function handleUpload(files) {
    const file = files.length ? files[0] : null;
    startFileValidation(file).then(() => {
      startUpload(file);
    }).catch((error => {
      console.warn(error);
    }));
  }

  async function handleUploadThumbnail(files) {
    const file = files.length ? files[0] : null;
    const base_64 = await getBase64(file)
    setBase64(base_64)
    setThumbnailImage(file);
    props.onChangeProdImage(file);  // TODO:
    startUploadThumbnail(file);
  }

  function handleRemoveImage() {
    setThumbnailImage(null);
    setIsUploadingThumbnail(false);
    props.onChangeProdImage(null);
  }

  function getBase64(file) {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  return (
    <React.Fragment>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="community-box"
            options={communities}
            value={community}
            getOptionLabel={option => option.name}
            onChange={(event, newValue) => {
              if(newValue){
                handleCommunityChanged(newValue);
              }
            }}
            renderInput={params => (
              <TextField {...params} required label="Community" variant="outlined" margin="dense" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/*
          <FormControl required margin="dense" variant="outlined" fullWidth>
            <InputLabel id="product-type">
              Type
            </InputLabel>
            <Select
              value={prodType}
              margin="dense"
              label="Availability"
              onChange={e => handleTypeChanged(e.target.value)}
            >
              <MenuItem value={"Supplement"}>Supplement</MenuItem>
              <MenuItem value={"Drug"}>Drug</MenuItem>
              <MenuItem value={"Medical Device"}>Medical Device</MenuItem>
            </Select>
          </FormControl>*/}
          {/*
          <MoreInfo title={strings['type'].title} body={strings['type'].body}/>
          <FormControl required margin="dense" variant="outlined" fullWidth>
            <InputLabel id="avail-type">
              Availability
            </InputLabel>
            <Select
              value={available}
              margin="dense"
              label="Availability"
              onChange={e => handleAvailChanged(e.target.value)}
            >
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Study"}>Study</MenuItem>
            </Select>
          </FormControl>*/}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            value={name}
            label="title"
            margin="dense"
            fullWidth
            multiline
            variant="outlined"
            maxRows={4}
            rows={3}
            onChange={e => handleNameChanged(e.target.value)}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          {!thumbnailImage ?
            <Dropzone accept="image/*" onDrop={acceptedFiles => handleUploadThumbnail(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} className={classes.dropBox}>
                    <div className={classes.dropBoxInner}>
                      <input {...getInputProps()} />
                      <AddPhotoAlternateIcon size="large"/>
                      <div>{thumbnailImage ? 'Change photo' : 'Add treatment photo'}</div>
                    </div>
                  </div>

                </section>
              )}
            </Dropzone>
            :
            <div className={classes.thumbnailContainer}>
              <img width="100%" src={base64}/>
              <div className={classes.imageCloseIcon} onClick={() => handleRemoveImage()}>
                <CloseIcon fontSize="small" style={{ borderRadius: 20, backgroundColor: 'rgba(0, 0, 0, 0.2)', margin: 0 }}/>
              </div>
            </div>
          }
          {/*<TextField
            id="image"
            name="image"
            label="thumbnail url"
            margin="dense"
            fullWidth
            value={image}
            variant="outlined"
            onChange={e => handleImageChanged(e.target.value)}
          />*/}
        </Grid>



        {(categories.length > 0) &&
          <Grid item xs={12}>
            <InputLabel id="product-cat">
              Categories
            </InputLabel>
            {categories.map(cat => (
              <FormControlLabel
                control={<Checkbox color="primary" name={cat.name} checked={selCats && selCats.has(cat.id)} onChange={(e) => handleCatChanged(cat, e.target.checked)}/>}
                label={cat.name}
                key={cat.id}
              />
            ))}
          </Grid>
        }

      </Grid>
    </React.Fragment>
  );
}
