import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Divider,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Radio,
  Slider,
  Link,
  FormControlLabel,
  IconButton,
  Popover
 } from "@material-ui/core";
 import {
   Lock as LockIcon,
   ArrowRight as ArrowRightIcon,
   ArrowLeft as ArrowLeftIcon
 } from "@material-ui/icons";
// components
import { Typography } from "../../../components/Wrappers";
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PDFDownloadLink, BlobProvider, PDFViewer, Image, Page, Canvas, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Report from './report';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler
} from 'react-day-picker';
import useStyles from "./styles";
import { get_reports, get_patient_journeys, get_journey_result } from "../../../api/study";
import { makeStyles } from '@material-ui/styles';
import 'react-day-picker/dist/style.css';
import CalendarHeatmap from './calendarHeatMap';
import Consent from './consent';

const InvoiceData = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    balance: "$2,283.74",
    fullname: "MANTRIX",
    email: "susanafuentes@mantrix.com",
    phone: "+1 (872) 588-3809",
    address: "922 Campus Road, Drytown, Wisconsin, 1986",
    trans_date: "26-11-2021",
    due_date: "26-11-2021",
    companyID: "10001",
    companyName: "xyz company",
    items: [
        {
            sno: 1,
            desc: "FinePix Pro2 3D Camera",
            qty: 2,
            rate: 1600.00,
        },
        {
            sno: 2,
            desc: "Luxury Ultra thin Wrist Watch",
            qty: 1,
            rate: 300.99,
        },
        {
            sno: 3,
            desc: "Duracell Ultra Alkaline Battery AA, 4 pcs",
            qty: 1,
            rate: 145.99,
        }
    ]
}

const questions = {
			"id": "public_id",
			"results": [{
				"rank": 1,
				"type": "scale",
				"values": [2,7,10]
			}, {
				"rank": 2,
				"type": "multiselect",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 3,
				"type": "radio",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 4,
				"type": "image",
				"values": ["https://s3-us-west-2.amazonaws.com/eden-qa-milkyway/users/0e84073d-a858-4c3a-a652-79f80ce4bd86.jpe"]
			}]
		}

const rowStyles = makeStyles(theme => ({
  rowContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingRight: '20px',
    margin: '5px'
  },
  rowContainerDense: {
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '5px',
    overflow: 'hidden',
  },
  title: {
    fontWeight: '600',
    fontSize: 16,
    color: 'grey',
    marginTop: '10px'
  },
  value: {
    alignSelf: 'flex-end',
    padding: '5px 25px 5px 25px',
    borderRadius: '5px',
    border: '1px solid lightgrey',
    color: 'grey',
    backgroundColor: 'white'
  }
}));

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  const arr = [];
  for (let idx = 0; idx < count; idx += 1) {
    arr.push(idx);
  }
  return arr;
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomValues(count, date = new Date()) {
  return getRange(count).map((index) => {
    return {
      date: shiftDate(date, -index),
      count: getRandomInt(1, 10),
    };
  });
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ScaleRow = props => {
  const classes = rowStyles();
  const marks = [
    {
      value: props.data.values[0],
      label: props.data.labels ? props.data.values[0] + ' (' + props.data.labels[0] + ')' : props.data.values[0],
    },
    {
      value: props.data.values[2],
      label: props.data.labels ? props.data.values[2] + ' (' + props.data.labels[1] + ')' : props.data.values[2],
    }
  ]
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  <Slider
                    disabled
                    defaultValue={props.data.values[0]}
                    value={props.data.values[1]}
                    valueLabelDisplay="on"
                    min={props.data.values[0]}
                    max={props.data.values[2]}
                    aria-labelledby="disabled-slider"
                    marks={marks}/>
                    :
                  "No Response"
                }
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const ImageRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            Question {props.data.rank}:
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  <img width="100%" src={props.data.values[0]} />
                  :
                  "No Response"
                }
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const MultiselectRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  props.data.values.map((c, i) => (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox color="default" disabled name={c.option} checked={c.selected} />}
                      label={c.option}
                    />
                  ))
                  :
                  "No Response"
                }

              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const RadioRow = props => {
  const classes = rowStyles();
  const selected =  props.data.values.find(item => {
     return item.selected == true
  })
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {(props.data.values.length > 0) ?
                <RadioGroup row disabled aria-label="question" name="options" value={selected && selected.option}>
                  {props.data.values.map((q,i) => (
                    <div key={i} className={classes.checkboxRow}>
                      <FormControlLabel
                        value={q.option}
                        control={<Radio disabled value={q.option}/>}
                        label={q.option
                        }
                      />
                    </div>
                  ))}
                </RadioGroup>
                :
                "No Response"
              }
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const NumberRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {(props.data.values.length > 0) ? props.data.values[0] : "No Response"}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const Row = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainerDense}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {props.value}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

function TabPanel(props) {
  var classes = useStyles();

  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      className={classes.tab}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function Forms(props) {

  var classes = useStyles();

  const [value, setValue] = useState(0);
  const [values, setValues] = useState(generateRandomValues(20));
  const [report, setReport] = useState(null);
  const [selectedRange, setSelectedRange] = useState();
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [entries, setEntries] = useState([]);
  const fromdateRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
      async function getResults(){
        const id = props.match.params.patient_id;
        if (id) {
          const data = await get_reports(id)
          const journeys = await get_patient_journeys(id)
          const patient_journey = journeys.find(j => {
            return j.clinical_study_investigator_group_id == null
          })
          setReport(data)
          if (patient_journey) {
            const entries = await get_journey_result(patient_journey.id)
            if (entries && entries.data) {
              setEntries(entries.data)
            }
          }
        }
      }
      // getCommunities();

      getResults()
  }, [])



  function handleTOS(e) {
    e.preventDefault();
  }

  const handleClickDate = (event) => {
    setAnchorEl(anchorEl ? null : fromdateRef && fromdateRef.current);
  };


  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  function handleRangeSelect(range) {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, 'y-MM-dd'));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'y-MM-dd'));
    } else {
      setToValue('');
    }
  };

  const open = Boolean(anchorEl);

  console.warn("fromdateRef",fromdateRef)
  return (
    <div>
      <Link href="#" onClick={(e) => handleTOS(e)}>
        Terms of Service
      </Link>

      <Divider/>


      <h3>RTM Report</h3>

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ textTransform: 'none', color: 'white', marginRight: 10}}
          aria-label="Pick a date"
          onClick={handleClickDate}
        >
          Select dates
        </Button>
        <form className="ma2">
          <TextField
            size="small"
            variant="outlined"
            ref={fromdateRef}
            placeholder="From Date"
            value={fromValue}
            onClick={handleClickDate}
            onChange={handleFromChange}
          />
          <TextField
            size="small"
            variant="outlined"
            placeholder="To Date"
            value={toValue}
            onClick={handleClickDate}
            onChange={handleToChange}
          />
        </form>
      </div>
      <Popover open={open} anchorEl={anchorEl} onClose={() => {setAnchorEl(null)}}>
        <DayPicker
              initialFocus={open}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}/>
      </Popover>
      <Link href="#" onClick={(e) => handleTOS(e)}>
        RTM Report
      </Link>

      {/*<PDFDownloadLink
        document={<QRPage qr_uri={qrUri} treatment={treatment} profile={profile}/>}
        fileName={(treatment ? treatment.name : 'treatment') + ".pdf"}
      >
        {({ blob, url, loading, error }) => (
          <Button
            className={classes.dlBtn}
            disabled={loading}
          >
            {loading ? "Preparing Document ..." : <GetApp fontSize="small"/>}
          </Button>
        )}
      </PDFDownloadLink>*/}
      {entries &&
        <div style={{width: "50%"}}>
          <CalendarHeatmap
            startDate={new Date('2023-05-01')}
            values={entries}
            showWeekdayLabels={true}
            classForValue={(value) => {
              if (!value) {
                return 'color-empty';
              }
              return `color-gitlab-1`;
            }}
          />
        </div>
      }
      <div>
        <PDFViewer style={{height: '2000px', width: '100%', alignItems: 'center'}} showToolbar={false}>
          <Consent invoicedata={InvoiceData} analytics={report} entries={entries} patient_id={props.match.params.patient_id} />
        </PDFViewer>
      </div>

    </div>
  );
}
