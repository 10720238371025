import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  tabs: {
    backgroundColor: 'white',
    textTransform: 'none',
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  selectBtn: {
    margin: theme.spacing(1),
    maxWidth: 170
  },
  productImage: {
    height: 225,
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 400,
    overflow: "hidden",
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.customShadows.widget,
  },
  questionModuleContainer: {
    display: 'block',
    flexDirection: 'column',
    width: "100%"
  },
  dayNavRow: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dayContainer: {
    border: '2px solid',
    flexShrink: 1,
    borderRadius: '5px',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  dayValue: {
    fontSize: 16
  }
}));
