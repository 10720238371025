import React, {useState, useEffect} from 'react';
import ApexCharts from "react-apexcharts";
import { Grid, Tabs, Tab, Box, FormControl, Divider, TextField, Typography, InputAdornment, OutlinedInput, MenuItem, Select } from "@material-ui/core";
import useStyles from "../styles";
import InputLabel from '@material-ui/core/InputLabel';
import Widget from "../../../components/Widget";
import Histogram from "./Histogram";
import Series from "./Series";
import Numbers from "./Number";

import { get_study_analytics } from '../../../api/study';

export default function MainChart(props) {

      const mock = {

      series: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
      }
      ],
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#008FFB"],
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      }


    };


  const box_mock = {
      series: [
        {
          name: 'Cohort A',
          type: 'boxPlot',
          stroke: {
                colors: ['#6c757d']
              },
          data: [
            {
              x: new Date('2017-01-01').getTime(),
              y: [54, 66, 69, 75, 88],
              stroke: {
                colors: ['#6c757d']
              }
            },
            {
              x: new Date('2018-01-01').getTime(),
              y: [43, 65, 69, 76, 81]
            },
            {
              x: new Date('2019-01-01').getTime(),
              y: [31, 39, 45, 51, 59]
            },
            {
              x: new Date('2020-01-01').getTime(),
              y: [39, 46, 55, 65, 71]
            },
            {
              x: new Date('2021-01-01').getTime(),
              y: [29, 31, 35, 39, 44]
            }
          ]
        },
        {
          name: 'Cohort B',
          type: 'boxPlot',
          data: [
            {
              x: new Date('2017-01-01').getTime(),
              y: [24, 25, 42, 46, 71]
            },
            {
              x: new Date('2018-01-01').getTime(),
              y: [21, 25, 43, 56, 75]
            },
            {
              x: new Date('2019-01-01').getTime(),
              y: [31, 39, 45, 51, 59]
            },
            {
              x: new Date('2020-01-01').getTime(),
              y: [21, 39, 46, 55, 65]
            },
            {
              x: new Date('2021-01-01').getTime(),
              y: [19, 21, 29, 35, 44]
            }
          ]
        }
      ],
      options: {
        chart: {
          type: 'boxPlot',
          height: 350
        },
        colors: ['#008FFB', '#FEB019'],
        title: {
          align: 'left'
        },

        xaxis: {
          type: 'datetime',
          tooltip: {
            formatter: function(val) {
              return new Date(val).getFullYear()
            }
          }
        },
        tooltip: {
          shared: false,
          intersect: true
        },
        stroke: {
          colors: ['#008FFB', '#FEB019']
        }
      },

  }


  const chart_types = ['area', 'box']


  const [question, setQuestion] = useState("");
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [results, setResults] = useState(null);
  const [dataA, setDataA] = useState(null);
  const [dataB, setDataB] = useState(null);

  var classes = useStyles();

  function selectChartType(index) {
    if (index < 0) {
      return
    }
    setType(index)
    props.update()
  }

  function changeQuestion(value) {
    setQuestion(value)
    setType("")
    setResults(value.results)
    props.update()
    // props.update({
    //   question: {
    //     type: value.type,
    //     rank: value.rank
    //   },
    //   type: type
    // })
  }

  return (
    <div id="chart">
      <div>
        <FormControl variant="outlined">
          <InputLabel id="question-select-label">Survey Question</InputLabel>
          <Select
            style={{minWidth: '200px'}}
            value={question}
            label="Question"
            fullWidth
            onChange={e => changeQuestion(e.target.value)}
          >
            {props.summary.map((c, i) => {
              return(
                <MenuItem key={c.rank} value={c}>{c.title}</MenuItem>)
            })}
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <FormControl variant="outlined">
          <InputLabel id="question-select-label2">Chart Type</InputLabel>
          <Select
            style={{minWidth: '200px'}}
            value={type}
            label="Chart Type"
            autoWidth
            onChange={e => selectChartType(e.target.value)}
          >
            {results ? results.map((c, i) => {
              return(
                <MenuItem key={i} value={i}>{c.type}</MenuItem>)
            }):<MenuItem key={0} value={-1}>No Charts Found</MenuItem>}

          </Select>
        </FormControl>
      </div>
      {results && results[type] && results[type].type == "area" ?
        <ApexCharts key={"area"} options={mock.options} series={mock.series} type="area" height={350} />
        :
        results && results[type] && results[type].type == "box" ?
        <ApexCharts key={"box"} options={box_mock.options} series={box_mock.series} type="boxPlot" height={350} />
        :
        results && results[type] && results[type].type == "histogram" ?
        <Histogram key={"histogram"} data_a={results[type].result} />
        :
        results && results[type] && results[type].type == "timeseries" ?
        <Series key={"series"} is_day={true} study_type={'RTM'} series_a={results[type].result} question={question}/>
        :
        results && results[type] && results[type].type == "number" ?
        <Numbers key={"numbers"} data_a={results[type].result} />
        :
        null
      }
    </div>
  )
}
