import React from "react";
import { Grid, Chip, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Button } from "../../components/Wrappers";
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import mock from "../dashboard/mock";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Consent from '../study/forms/consent';
import useStyles from "./styles";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import { handleNotification } from '../notifications/NotificationsClient';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Tooltip from '@material-ui/core/Tooltip';
import { get_reports, get_patient_journeys, get_journey_result } from "../../api/study";
import { saveAs } from 'file-saver';
import Report from '../study/forms/report';
import { pdf } from '@react-pdf/renderer';

// data

const states = {
  completed: "success",
  accepted: "secondary",
  generated: "default",
  rejected: "defualt",
};




const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function JourneyMenu(props) {
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleEndConfirm = (event) => {
    handleClose(event);
    props.onClickEndJourney()
  };

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <div style={{display: 'inline'}}>
      <IconButton color="default" aria-label="create" component="label" onClick={handleClick} disabled={props.journey.end_date}>
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!props.journey.end_date &&
          <MenuItem style={{color: '#d9534f'}} onClick={(e) => handleEndConfirm(e)}>End Journey</MenuItem>}
      </Menu>
    </div>
  )
}

function TableMenu(props) {
  var classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null);
  };

  const handleAddtoJourney = (event) => {
    handleClose(event);
    props.onClickAddtoJourney(props.data && props.data.rowData[4])
  };

  const handleForms = (event) => {
    handleClose(event);
    props.onClickAForms(props.data && props.data.rowData[4])
  };

  async function handleClipboard (e,text) {
    handleClose(e)
    handleNotification("reminder copied")
    await navigator.clipboard.writeText(text)

  }

  const found = props.data.rowData[3].find((item) => !item.clinical_study_investigator_group)
  var link = null
  if (found) {
    link = process.env.REACT_APP_PATIENT_URL + 'newpost/' + found.clinical_study.treatment.id + '/' + found.id
  }
  const show_menu = (props.studyType!="REMOTE_THERAPEUTIC_MONITORING") || (props.data && props.data.rowData[5].length>0)
  return (
    <div>
      <IconButton color="default" aria-label="create" component="label" onClick={handleClick} disabled={!show_menu}>
        <CreateIcon/>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {(props.studyType!="REMOTE_THERAPEUTIC_MONITORING") &&
          <MenuItem onClick={handleAddtoJourney}>Add Survey Response</MenuItem>}
        {(props.studyType=="REMOTE_THERAPEUTIC_MONITORING") && (props.data && props.data.rowData[5].length>0) &&
          <MenuItem><PDFDownloadLink style={{'color': '#212529', textDecoration: 'none'}} document={<Consent patient={props.data.rowData[1] + ' ' + props.data.rowData[2]} date={props.data.rowData[5][0].created_date}/>} fileName="RTM_consent.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading consent...' : <div style={{display: 'flex', alignItems: 'center'}}><GetAppIcon style={{marginRight: 5}} fontSize="small"/>Download RTM consent</div>
      }
    </PDFDownloadLink></MenuItem>
        }
        {link && props.patientType != 'INTERNAL_PATIENT' &&
          <MenuItem onClick={(e) => handleClipboard(e,link)}><div style={{display: 'flex', alignItems: 'center'}}><FileCopyIcon style={{marginRight: 5}} fontSize="small"/>Copy reminder link to clipboard</div></MenuItem>}
      </Menu>
    </div>
  )
}



export default function PatientTable(props) {
  var classes = useStyles();
  const tableClasses = useRowStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [journeyPayload, setJourneyPayload] = React.useState(null);


  async function downloadReport(journey) {
    var end_date = new Date(journey.first_entry_at);
    end_date.setDate(end_date.getDate() + 30);
    const entries = await get_journey_result(journey.id, journey.first_entry_at, end_date.toISOString())
    if (entries && entries.data.length>0) {
      const report = await get_reports(journey.patient.id, journey.first_entry_at, end_date.toISOString())
      const questions = journey.clinical_study.survey.questions
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      console.warn("journey",journey)
      const blob = await pdf(<Report invoicedata={null} questions={questions} analytics={report} entries={entries.data} patient={journey.patient} start={journey.first_entry_at.slice(0, 10)} end={end_date.toISOString().slice(0, 10)} images={[]}/>).toBlob()
      saveAs(blob, `RTM_report_${journey.patient.id}_${timestamp}.pdf`)
    }
  }

  const getMuiTheme = () => createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            '& > *': {
              borderBottom: 'unset',
            },
          },
        }
      }
    })

  const columns = [
      {
        "name": "status",
        "label": "Status",
        "options": {
          filter: false,
          sort: false,
          display: props.studyType == "REMOTE_THERAPEUTIC_MONITORING",
          customBodyRender: (value, tableMeta, updateValue) => {
            const found = tableMeta.rowData[3].find((item) => !item.clinical_study_investigator_group)
            if (found) {
              const diffTime = Math.abs(new Date() - new Date(found.first_entry_at));
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              const count = found.journey_entries_count
              if (!found.first_entry_at) {
                return (
                  <>
                  <Tooltip title="No post">
                    <IconButton aria-label="warning">
                      <ErrorIcon fontSize="small" style={{color: 'orange'}}/>
                    </IconButton>
                  </Tooltip>
                  {found.end_date && <Chip
                    size="small"
                    className="px-2"
                    style={{textTransform: 'none'}}
                    label="ended"
                  />}
                  </>
                )
              } else if (count > 15 && diffDays > 29) {
                return (
                  <>
                  <Tooltip title="Report ready, goal met">
                    <IconButton aria-label="success" onClick={() => downloadReport(found)}>
                      <AssignmentTurnedInIcon fontSize="small" color="primary"/>
                    </IconButton>
                  </Tooltip>
                  {found.end_date && <Chip
                    size="small"
                    className="px-2"
                    style={{textTransform: 'none'}}
                    label="ended"
                  />}
                  </>
                )
              } else if (diffDays > 30) {
                return (
                  <>
                  <Tooltip title="Report ready, < 16 days">
                  {/*<IconButton aria-label="missed">
                    <WarningIcon fontSize="small" style={{color: 'orange'}}/>
                  </IconButton>*/}
                  <IconButton aria-label="download" onClick={() => downloadReport(found)}>
                    <AssignmentReturnedIcon fontSize="small" style={{color: 'orange'}}/>
                  </IconButton>
                  </Tooltip>
                  {found.end_date && <Chip
                    size="small"
                    className="px-2"
                    style={{textTransform: 'none'}}
                    label="ended"
                  />}
                  </>
                )
              } else if ( diffDays && (count/diffDays < 16/30)) {
                return (
                  <>
                  <Tooltip title="Not meeting expected progress">
                  <IconButton aria-label="warning">
                    <WarningIcon fontSize="small" style={{color: 'orange'}}/>
                  </IconButton>
                  </Tooltip>
                  {found.end_date && <Chip
                    size="small"
                    className="px-2"
                    style={{textTransform: 'none'}}
                    label="ended"
                  />}
                  </>
                )
              }
            } else {
            return(
              <Tooltip title="No journey">
                <IconButton aria-label="warning">
                  <ErrorIcon fontSize="small" style={{color: 'red'}}/>
                </IconButton>
              </Tooltip>
            )}
          }
        }
      },
      {
        "name": "first_name",
        "label": "Name",
        "options": {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <div>
                <div>{value} {tableMeta.rowData[2]}</div>
                <div style={{color: 'grey'}}>{tableMeta.rowData[10]}</div>
              </div>
          )}
        }
      },
      {
        "name": "last_name",
        "label": "Last Name",
        "options": {
          filter: false,
          sort: false,
          display: false
        }
      },
      {
        "name": "journeys",
        "label": "journeys",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "id",
        "label": "id",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "consents",
        "label": "Patient Consent",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "started_at",
        "label": "Started",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "journeys.last_entry_at",
        "label": "Latest Post",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "journeys.journey_entries_count",
        "label": "Posts",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "type",
        "label": "Patient Platform",
        "options": {
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <div>{value == 'INTERNAL_PATIENT' ? 'No' : 'Yes'}</div>
          )}
        }
      },
      {
        "name": "email",
        "label": "email",
        "options": {
         filter: false,
         display: false
        }
      },
      {
        "name": "actions",
        "label": "actions",
        "options": {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <div>
                <TableMenu
                  studyType={props.studyType}
                  patientType={tableMeta.rowData[9]}
                  data={tableMeta}
                  onClickAForms={(id) => props.gotoForms(id)}
                  onClickAddtoJourney={(id) => props.addtoJourney(id)}/>
              </div>
          )}
        }
      }
  ]


  const handleConfirmEnd = (journey) => {
    setJourneyPayload(journey)
  }

  const handleEndJourney = () => {
    setJourneyPayload(null)
    props.endJourney(journeyPayload)
  }

  return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {props.isLoading ?
            <div className={classes.center}>
              <CircularProgress color="primary" />
            </div>
            :
            <MUIDataTable
            title="Patients"
            data={props.patients}
            columns={columns}
            options={{
              filter: false,
              print: false,
              download: false,
              setTableProps: () => {
                return {
                  // material ui v4 only
                  size: 'small',
                };
              },
              filterType: "checkbox",
              selectableRows: "none",
              enableNestedDataAccess: '.',
              viewColumns: false,
              expandableRows: true,
              expandableRowsHeader: true,
              expandableRowsOnClick: true,
              renderExpandableRow: (rowData, rowMeta) => {
                 const colSpan = rowData.length + 1;
                 console.warn("rowData[3]",rowData[3])
                 return (
                   <>
                     {rowData[3].map((journey, index) => (
                     <TableRow key={index}>
                         {(props.studyType == "REMOTE_THERAPEUTIC_MONITORING") && <TableCell colSpan={1}>
                         </TableCell>}
                          <TableCell colSpan={1}>
                            <div>{journey.clinical_study_investigator_group ? journey.title + ' Journey' : 'Patient Journey'}</div>
                            <div style={{color: 'grey'}}>{journey.first_entry_at && new Date(journey.first_entry_at.includes('Z') ? journey.first_entry_at : journey.first_entry_at+'Z').toLocaleDateString("en-US")}</div>
                          </TableCell>
                          <TableCell colSpan={1}>
                            Last entry {journey.last_entry_at ? new Date(journey.last_entry_at.includes('Z') ? journey.last_entry_at : journey.last_entry_at+'Z').toLocaleDateString("en-US"): "-"}
                          </TableCell>
                          <TableCell colSpan={1}>
                            {journey.journey_entries_count} submissions
                          </TableCell>

                          <TableCell colSpan={1}>
                            <>
                            {journey.journey_entries_count > 0 || journey.initial_survey_result ?
                              <Button
                                color="success"
                                size="small"
                                className="px-2"
                                variant="contained"
                                style={{textTransform: 'none'}}
                                href={"/survey_results/" + journey.id}>
                                view data
                              </Button>
                              :
                              null
                            }
                            <JourneyMenu
                              journey={journey}
                              onClickEndJourney={() => handleConfirmEnd(journey)}/>
                            </>
                          </TableCell>
                     </TableRow>
                   ))}
                   </>
                 );
              },

            }}>
          </MUIDataTable>
          }
        </Grid>


        <Dialog
          open={journeyPayload != null}
          onClose={() => setJourneyPayload(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to end this journey? You won't be able to revert this action."}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setJourneyPayload(null)} style={{textTransform: 'none'}}>
              No
            </Button>
            <Button onClick={handleEndJourney} style={{textTransform: 'none', color: 'red'}}  autoFocus>
              End
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
  );
}
