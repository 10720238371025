import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    height: '100vh'
  },
  selectBtn: {
    margin: theme.spacing(1),
    maxWidth: 170
  },
  productImage: {
    height: 225,
  },
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  sharelinkContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clipboardContainer: {
    display: 'flex',
    borderColor: 'red',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableContainer: {
    marginTop: theme.spacing(5),
  },

  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  qrpage: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrModal: {
    margin: theme.spacing(20,10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrDiv: {
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  qrDiv2: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  TreatmentQrcode: {
    display: 'none'
  },
  dlBtn: {
    textTransform: 'none'
  }

}));
