import React, {useState, useEffect} from 'react';
import ApexCharts from "react-apexcharts";
import { Grid, Button, Tabs, Tab, Box, FormControl, Divider, TextField, Typography, InputAdornment, OutlinedInput, MenuItem, Select } from "@material-ui/core";
import useStyles from "../styles";
import InputLabel from '@material-ui/core/InputLabel';
import Widget from "../../../components/Widget";
import MainChart from "../StudyCharts/MainChart";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Tooltip from '@material-ui/core/Tooltip';

export default function StudyChart(props) {

    const mock = {

      series: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
      }
      ],
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#008FFB"],
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      }


    };



  const ALL_COHORTS = [
    {
      id: 0,
      name: "All Cohorts"
    }
  ]


  const [questions, setQuestions] = useState([]);
  const [selectedCohortA, setSelectedCohortA] = useState("")
  const [selectedCohortB, setSelectedCohortB] = useState("")
  const [charts, setCharts] = useState([])
  const [secondCohort, addSecondCohort] = useState(false)

  var classes = useStyles();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let loadCharts = []
    for(var k of urlParams.keys()) {
      let value = urlParams.get(k);
      if (k == 'cohortA') {
        setSelectedCohortA(value)
      } else if (k == 'cohortB') {
        setSelectedCohortB(value)
        addSecondCohort(true)
      } else if (k.startsWith('chart_')) {
        try {
          let decoded = decodeURIComponent(value)
          loadCharts.push(JSON.parse(decoded))
        } catch (e) {
          console.warn("Error parsing chart data", e)
        }
      }
    }

    setCharts(loadCharts)
    // let cohA = urlParams.get('cohortA')
    // if (cohA) {
    // }
    // let cohB = urlParams.get('cohortB')
    // if (cohB) {
    //   setSelectedCohortB(cohB)
    // }
  }, [])

  function addChart(){
    setCharts(charts.concat({
      id: Date.now()
    }))
  }

  function removeQuestion(index){
    let new_charts = [...charts];
    new_charts.splice(index, 1);
    setCharts(new_charts)
  }

  function changeCohortA(id) {
    setSelectedCohortA(id)
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('cohortA', id);
    props.history.push({
        search: "?" + urlParams.toString()
    })
  }

  function changeCohortB(id) {
    setSelectedCohortB(id)
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('cohortB', id);
    props.history.push({
        search: "?" + urlParams.toString()
    })
  }

  function updateChart(i, data) {
    let new_charts = charts
    new_charts[i].question = data && data.question
    new_charts[i].type = data && data.type
    setCharts(new_charts)
    var json = JSON.stringify(data);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("chart_" + i, encodeURIComponent(json));
    props.history.push({
        search: "?" + urlParams.toString()
    })
  }

  function removeCohortB(){
    addSecondCohort(false)
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('cohortB')
    props.history.push({
        search: "?" + urlParams.toString()
    })
  }
  return (
    <div id="chart">
        <Grid container spacing={4}>
          <div className={classes.compareHeader}>
            {(props.study && props.study.cohorts && (props.study.cohorts.length > 0))&&
              <Box sx={{ m: 1, minWidth: 220 }}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-label">cohort A</InputLabel>
                  <Select
                    style={{minWidth: '100px'}}
                    value={selectedCohortA}
                    label="cohort A"
                    autoWidth
                    onChange={e => changeCohortA(e.target.value)}
                  >
                    {(ALL_COHORTS.concat(props.study.cohorts)).map((c, i) => {
                      return(
                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Box>
            }
            <div style={{display: 'flex', marginLeft: '10px', marginRight: '10px', alignItems: 'center'}}>
              <Tooltip title="compare cohort">
                <AddCircleIcon
                  disabled={secondCohort}
                  onClick={() => addSecondCohort(true)}
                  variant="contained"
                />
              </Tooltip>
            </div>
            {secondCohort && (props.study && (props.study.cohorts.length > 0))&&
              <div style={{display: 'flex', flexDirection: 'row', alignItems :'center'}}>
                <Box sx={{ m: 1, minWidth: 220 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-label">cohort B</InputLabel>
                    <Select
                      style={{minWidth: '100px'}}
                      value={selectedCohortB}
                      label="cohort B"
                      autoWidth
                      onChange={e => changeCohortB(e.target.value)}
                    >
                      {props.study.cohorts.map((c, i) => {
                        return(
                          <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Button disableRipple style={{backgroundColor: 'transparent', textTransform: 'none', color: '#b30d00', fontSize: 12, fontWeight: '600'}} onClick={() => removeCohortB()}>remove</Button>
              </div>
            }
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              onClick={() => addChart(true)}
              variant="contained"
              style={{ textTransform: 'none', color: 'white'}}
              startIcon={<InsertChartIcon />}
              color="secondary">
              Add Chart
            </Button>
            </div>
          </div>
          {charts && (charts.length > 0) && charts.map((c,i) =>
            <Grid key={c.id} item lg={12} md={12} sm={12} xs={12}>
              <Widget disableWidgetMenu noHeader>
                <div className={classes.chartDelete} onClick={() =>  removeQuestion(i)}><RemoveCircleOutlineIcon /></div>
                <MainChart study={props.study} cohortA={selectedCohortA} cohortB={secondCohort ? selectedCohortB : null} update={(data) => updateChart(i, data)} question={charts[i].question} type={charts[i].type}/>
              </Widget>
            </Grid>
          )}

          {/*mock.bigStat.map(stat => (
            <Grid item md={4} sm={6} xs={12} key={stat.product}>
              <CohortStat {...stat} />
            </Grid>
          ))*/}
        </Grid>
    </div>
  )
}
