import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Checkbox,
  Box
} from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classnames from "classnames";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
// styles
import useStyles from "./designer-styles";
import { withStyles } from '@material-ui/styles';
// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";
import { upload_photo } from '../../api/treatment';

//
// function SimpleQuestion(props) {
//   var classes = useStyles();
//   const { children, value, index, ...other } = props;
//
//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       className={classes.tab}
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }
//
// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

class ImageQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      file: null
    };
  }
  //
  // var classes = useStyles();
  //
  // const [title, setTitle] = useState(0);
  // const [options, setOptions] = useState(null);
  //
  //
  // const handleChange = (event, newValue) => {
  //   // setValue(newValue);
  // };



  updateQuestionData() {
    const {title} = this.state;
    this.props.onDataChange({
      'title': title,
    })
  }

  changeTitle(val) {
    this.setState({title: val}, () => {
      this.updateQuestionData()
    })
  }


  async handleFileInput(file) {
    if (file) {
      // if (file.size > 1024)
      var bodyFormData = new FormData();
      bodyFormData.append('file', file);
      const data = await upload_photo(bodyFormData);
      // this.setState({file: file})
      // var image = document.getElementById(`question_${this.props.index}`);
      // image.src=URL.createObjectURL(file);
      this.props.onDataChange({
        'value': data.photo_url,
      })

      try {
        // this.setState({file: URL.createObjectURL(file)})
        var reader = new FileReader();
        reader.addEventListener('load', async e => {
          let results = reader.result;
          results = results.split(',')[1];
          this.setState({file: results})
        })
        reader.readAsDataURL(file);
      } catch (e) {
        console.warn("failed to select image")
      }
    }
  }
  //
  // async uploadImage(photoBase64) {
  //   const params = {
  //     photo_base64: photoBase64
  //   };
  //   const resp = await goFetch(
  //     'POST',
  //     URLS.UPLOADPHOTO,
  //     { 'Content-Type': 'application/json', 'File-Format': 'image/jpeg' },
  //     JSON.stringify(params)
  //   );
  //   if (resp.success) {
  //     const image_json = resp.data;
  //     return image_json.photo_url;
  //   } else {
  //     return false;
  //   }
  // }

  render () {
    const { classes, answer } = this.props;
    return (
      <div className={classes.questionModuleContainer}>
      <fieldset disabled={this.props.disabled} style={{border: 0}}>
        <TextField
          required={!answer}
          disabled={answer}
          id="name"
          name="name"
          value={this.state.title}
          label="title"
          margin="dense"
          fullWidth
          multiline
          variant="outlined"
          maxrows={4}
          onChange={e => this.changeTitle(e.target.value)}
        />

        <Grid item xs={12} md={6} lg={3} style={{marginTop: 20}}>
          <Dropzone accept="image/*">
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} className={classes.dropBox}>
                  <div className={classes.dropBoxInner}>
                    <input {...getInputProps()} disabled={!answer} type="file" accept="image/*" onChange={(e) => this.handleFileInput(e.target.files[0])}/>
                      {this.state.file ?
                        <img src={`data:image/png;base64,${this.state.file}`} id={"question_"+this.props.index} style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                        :
                        <>
                          <AddPhotoAlternateIcon size="large"/>
                          <div>Add Photo</div>
                        </>
                      }
                  </div>
                </div>

              </section>
            )}
          </Dropzone>
        </Grid>
        </fieldset>
      </div>
    );
  }


}

export default withStyles(useStyles)(ImageQuestion);
