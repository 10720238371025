import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BasicInfo from './BasicInfo';
import Description from './Description';
import SurveyDesigner from './SurveyDesigner';

import { create_product } from '../../api/stripe';

// import FDAinfo from './FDAinfo';
// import SEInfo from './SEInfo';
// import useStyles from "./styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  designerDiv: {
    height: '60vh',
    maxHeight: '60vh',
    border: "3px solid"
  },
  layout: {
    width: '100%',
    position: 'relative'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function getSteps() {
  return ['General information', 'Description', 'Review'];
}


export default function AddStudy(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  // for step 1:
  const [community, setCommunity] = React.useState('');
  const [prodType, setProdType] = React.useState('');
  const [prodName, setProdName] = React.useState('');
  const [prodImage, setProdImage] = React.useState('');
  const [YTURL, setYTURL] = React.useState('');
  const [videoURL, setVideoURL] = React.useState('');
  const [hiw, setHiw] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedCats, setSelectedCats] = React.useState(null);
  // for step 2:

  const steps = getSteps();

  const isStepOptional = step => {
    return step === 5;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const changeCommunity = (community) => {
    setCommunity(community);
  }
  const changeProdType = (type) => {
    setProdType(type);
  }
  const changeProdName = (name) => {
    setProdName(name);
  }
  const changeProdImage = (image) => {
    setProdImage(image);
  }
  const changeYTURL = (url) => {
    setYTURL(url);
  }
  const changeVideoURL = (url) => {
    setVideoURL(url);
  }
  const changeDescription = (text) => {
    setDescription(text);
  }
  const changeHIW = (text) => {
    setHiw(text);
  }
  const changeSelectedCats = (cats) => {
    setSelectedCats(cats)
  }

  async function submitTreatment() {
    const resp = await create_product(prodName, description, "123456");
    if(resp) {
      // let temp = JSON.parse(resp)
      console.warn("CREATED", resp)
    } else {
      console.warn("not success");
    }
    props.onFinished();
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <div className={classes.designerDiv}><SurveyDesigner community={community}/></div>;
      case 1:
        return <Description
          hiw={hiw}
          description={description}
          onChangeDescription={text => changeDescription(text)}
          onChangeHIW={text => changeHIW(text)}
        />;
      default:
        return 'Unknown step';
    }
  }

  const isDisabled = (step) => {
    switch (step) {
      case 0:
        return (!community || !prodType || !prodName || !prodImage || (prodType=='Free' && !YTURL) || (prodType=='Premium' && !videoURL))
      case 1:
        return (!hiw || !description);
      case 2:
        return false;
      default:
        return false;
    }
  }

  return (
    <React.Fragment>
    <div className={classes.layout}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Your product is under review now! Once approved it will be added to the community.
            </Typography>
            <Button color="info" onClick={() => submitTreatment()} className={classes.button}>
              Ok
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={isDisabled(activeStep)}
                style={{ color: 'white'}}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
    </React.Fragment>
  );
}
