import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import DemoSidebar from "../Sidebar/DemoSidebar";

// pages
import DemoDashboard from "../../pages/dashboard/DemoDashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Promotions from "../../pages/promotions";
import Orders from "../../pages/orders";
import Manage from "../../pages/manage";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Account from "../../pages/account";
import PaymentSuccess from "../../pages/account/Success";
import PaymentCancel from "../../pages/account/Cancel";
import Insight from "../../pages/insight";
import Support from "../../pages/support";
import Curate from "../../pages/curate";
import Study from "../../pages/study";
import Survey from "../../pages/study/SurveyLibrary";
import StudyAnalysis from "../../pages/study/StudyAnalysis";
import SurveyView from "../../pages/study/SurveyView";
import StudyDesign from "../../pages/study/StudyDesign";
import Profile from "../../pages/profile";
import Treatments from "../../pages/treatments";
import TreatmentPage from "../../pages/treatments/TreatmentPage";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { StatsProvider } from "../../context/StatsContext";
import permissions from '../../configs/rbac.config';
import RouterRBAC from '../RouterRBAC';
import SubscriptionMW from '../SubMiddleware';

function DemoLayout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <StatsProvider>
      <div className={classes.root}>
          <>
            <Header history={props.history} />
            <DemoSidebar />
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />
              <Switch>
                <Route exact path="/dashboard/:prod_id?" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/dashboard']}>
                    <DemoDashboard {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/promotions" render={() =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/promotions']}>
                    <Promotions {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/orders" render={() =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/orders']}>
                    <Orders {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/manage" render={() =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/manage']}>
                    <Manage {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/account" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/account']}>
                    <Account {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/stripe/checkout_success" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/account']}>
                    <PaymentSuccess {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/stripe/checkout_cancel" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/account']}>
                    <PaymentCancel {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/notifications" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/notifications']}>
                    <Notifications {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/insight" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/insight']}>
                    <Insight {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/support" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/support']}>
                    <Support {...props}/>
                  </RouterRBAC>
                } />
                {/*<Route path="/library" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/library']}>
                    <Curate {...props}/>
                  </RouterRBAC>
                } />*/}
                <Route exact path="/study" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/study']}>
                    <SubscriptionMW {...props}>
                      <Study {...props}/>
                    </SubscriptionMW>
                  </RouterRBAC>
                } />
                <Route path="/study/:study_id" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/study']}>
                    <SubscriptionMW {...props}>
                      <StudyAnalysis {...props}/>
                    </SubscriptionMW>
                  </RouterRBAC>
                } />
                <Route exact path="/surveylibrary" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/surveylibrary']}>
                    <SubscriptionMW {...props}>
                      <Survey {...props}/>
                    </SubscriptionMW>
                  </RouterRBAC>
                } />
                <Route path="/surveylibrary/:survey_id" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/surveylibrary']}>
                    <SurveyView {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/newstudy" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/newstudy']}>
                    <StudyDesign {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/profile" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/profile']}>
                    <Profile {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/treatments" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/treatments']}>
                    <Curate {...props}/>
                  </RouterRBAC>
                } />
                <Route path="/treatments/:treatment_id" render={(props) =>
                  <RouterRBAC allowedRoles={permissions.routePermissions['/treatments']}>
                    <TreatmentPage {...props}/>
                  </RouterRBAC>
                } />

              </Switch>
            </div>
          </>
      </div>
    </StatsProvider>
  );
}

export default withRouter(DemoLayout);
