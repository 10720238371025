import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from "../../components/Wrappers";

// data
import mock from "../dashboard/mock";

const states = {
  completed: "success",
  processing: "warning",
  sent: "secondary",
};


const columns = [
  {
    "name": "username",
    "label": "Username"
  },
  {
    "name": "city",
    "label": "City"
  },
  {
    "name": "date_accepted",
    "label": "Date Accepted"
  },
  {
    "name": "status",
    "label": "Status"
  }
]
const mock2 = [
  {id: 13, username: "mayaaaa", city: 'Los Angeles', date_accepted: "2019-11-02", status: "SENT"},
  {id: 13, username: "ericwadjwia", city: 'Dallas', date_accepted: "2019-11-18", status: "COMPLETED"},
  {id: 13, username: "babakm", city: 'San Francisco', date_accepted: "2020-06-12", status: "COMPLETED"},
  {id: 13, username: "peter072", city: 'Los Angeles', date_accepted: "2019-07-25", status: "DECLINED"},
  {id: 13, username: "dr.jay", city: 'New York', date_accepted: "2019-10-10", status: "SENT"},
  {id: 13, username: "royass", city: 'Seattle', date_accepted: "2020-01-09", status: "COMPLETED"},
  {id: 13, username: "smith_stone", city: 'San Diego', date_accepted: "2019-11-02", status: "SENT"},
  {id: 13, username: "kim22", city: 'Los Angeles', date_accepted: "2020-06-02", status: "COMPLETED"},


]
export default function PromoTable(props) {
  console.warn(props.promotions)
  return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Users List"
            data={mock2}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              customRowRender: data => {
                const [ username, city, date_accepted, status ] = data;
                return (
                  <TableRow key={username}>
                    <TableCell className="pl-3 fw-normal">{username}</TableCell>
                    <TableCell>{city}</TableCell>
                    <TableCell>{date_accepted}</TableCell>
                    <TableCell>
                      <Button
                        color={states[status.toLowerCase()]}
                        size="small"
                        className="px-2"
                        variant="contained"
                      >
                        {status}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              }
            }}
          />
        </Grid>
      </Grid>
  );
}
