import * as urls from './urls';
import { useUserState } from "../context/UserContext";
import { apiClient } from './apiClient';

// var {dispatch} = useUserState();

export function login(email, pass, ouid) {
  let response = apiClient(
    urls.LOGIN(),
    'POST',
    ouid ?
    {
      email: email,
      password: pass,
      ouid: ouid
    }
    :
    {
      email: email,
      password: pass
    }
  )
  return response;
}


export function register(token, email, pass, firstname, lastname, organization) {
  let response = apiClient(
    urls.REGISTER(),
    'POST',
    {
      email: email,
      first_name: firstname,
      last_name: lastname,
      password: pass,
      organization: organization
    },
    {
      'Invitation-Token': token
    }
  )
  return response;
}

export function confirm(token) {
  let response = apiClient(
    urls.REGISTER(),
    'PUT',
    {},
    {
      'Confirmation-Token': token
    }
  )
  return response;
}

export function forgot_password(email) {
  let params = {
    email: email
  };
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  let response = apiClient(
    urls.FORGOT_PASSWORD() + '?' + query,
    'POST',
    {}
  )
  return response;
}

export function reset_password(token, pass, confirm) {
  let params = {
    token: token
  };
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  let response = apiClient(
    urls.FORGOT_PASSWORD() + '?' + query,
    'PATCH',
    {
      password: pass,
      password_confirm: confirm
    }
  )
  return response;
}
