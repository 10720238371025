import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  sefbutton: {
    marginLeft: theme.spacing(2),
  },
  sideEffect: {
    backgroundColor: theme.palette.background.default
  }
}));

const communities = [
  { title: 'Hyperhidrosis' },
  { title: 'GWI' },
  { title: 'Carpal Tunnel'},
  { title: 'The Dark Knight' }
]
export default function FDAinfo() {
  const classes = useStyles();

  return (
    <React.Fragment>

      <Grid container spacing={3}>

        <Grid item xs={6} sm={4}>
         <FormControl margin="dense" fullWidth>
            <InputLabel id="product-nda">
              application Type
            </InputLabel>
            <Select
              labelId="fda-nda"
              id="fda-nda-input"
              margin="dense"
            >
              <MenuItem value={"NDA"}>NDA</MenuItem>
              <MenuItem value={"ANDA"}>ANDA</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            placeholder="premarket number"
            id="premarket-input"
            name="premarket"
            label="510(k)"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            required
            id="name"
            name="name"
            label="product name"
            margin="dense"
            fullWidth
          />
        </Grid>

        {/* <Divider style={{width:'100%'}} variant="middle"/> */}
      </Grid>
    </React.Fragment>
  );
}
