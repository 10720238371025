import React, { useState, useEffect } from "react";
import './plan.css';

export default function Success(props) {

  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("checkout_session_url")

  // if (sessionId) {
  //   fetch("http://127.0.0.1:4242/checkout-session?sessionId=" + sessionId)
  //     .then(function(result){
  //       return result.json()
  //     })
  //     .then(function(session){
  //       var sessionJSON = JSON.stringify(session, null, 2);
  //       document.querySelector("pre").textContent = sessionJSON;
  //     })
  //     .catch(function(err){
  //       console.log('Error when fetching Checkout session', err);
  //     });
  // }

  function gotoAccount(e) {
    // In production, this should check CSRF, and not pass the session ID.
    // The customer ID for the portal should be pulled from the
    // authenticated user on the server.
      e.preventDefault();
      props.history.push(`/account`);
      // const resp = await fetch('http://127.0.0.1:4242/customer-portal', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     sessionId: sessionId
      //   }),
      // })
      //
      // if (resp) {
      //   console.warn(resp)
      //   const data = await resp.json();
      //   console.warn(data)
      //   if(data) {
      //     window.location.href = data.url;
      //   }
      // }
        // .then((response) => response.json())
        // .then((data) => {
        //   window.location.href = data.url;
        // })
        // .catch((error) => {
        //   console.error('Error:', error);
        // });
  }

  return (
    <div class="payment-success">
      <div class="togethere-background"></div>
      <div class="sr-root">
        <div class="sr-main">
          <header class="sr-header">
            <div class="sr-header__logo"></div>
          </header>
          <div class="sr-payment-summary completed-view">
              <h1>Payment success</h1>

            </div>
            <div class="completed-view-section">
                <button onClick={(e) => gotoAccount(e)}>Go back to account</button>
            </div>
          </div>
          <div class="sr-content">
          </div>
      </div>
    </div>
  )
}
