import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Divider,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Radio,
  Slider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Chip
 } from "@material-ui/core";
 import {
   Lock as LockIcon,
   ArrowRight as ArrowRightIcon,
   ArrowLeft as ArrowLeftIcon
 } from "@material-ui/icons";
// components
import { Typography } from "../../../components/Wrappers";
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import useStyles from "./styles";
import { get_survey_result, get_journey_result, get_survey, get_journey } from "../../../api/study";
import { makeStyles } from '@material-ui/styles';
import RTMReport from './rtmReport';
import { generatePath } from 'react-router';

const ACTIVE_TAB = {
  0: "results",
  1: "init_results",
  2: "final_results",
  3: "rtm"
};

const questions = {
			"id": "public_id",
			"results": [{
				"rank": 1,
				"type": "scale",
				"values": [2,7,10]
			}, {
				"rank": 2,
				"type": "multiselect",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 3,
				"type": "radio",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 4,
				"type": "image",
				"values": ["https://s3-us-west-2.amazonaws.com/eden-qa-milkyway/users/0e84073d-a858-4c3a-a652-79f80ce4bd86.jpe"]
			}]
		}

const rowStyles = makeStyles(theme => ({
  rowContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingRight: '20px',
    margin: '5px'
  },
  rowContainerDense: {
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '5px',
    overflow: 'hidden',
  },
  title: {
    fontWeight: '600',
    fontSize: 16,
    color: 'grey',
    marginTop: '10px'
  },
  value: {
    alignSelf: 'flex-end',
    padding: '5px 25px 5px 25px',
    borderRadius: '5px',
    border: '1px solid lightgrey',
    color: 'grey',
    backgroundColor: 'white'
  }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ScaleRow = props => {
  const classes = rowStyles();
  const marks = [
    {
      value: props.data.values[0],
      label: props.data.min_label
    },
    {
      value: props.data.values[2],
      label: props.data.max_label
    }
  ]
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  <Slider
                    disabled
                    defaultValue={props.data.values[0]}
                    value={props.data.values[1]}
                    valueLabelDisplay="on"
                    min={props.data.values[0]}
                    max={props.data.values[2]}
                    aria-labelledby="disabled-slider"
                    marks={marks}/>
                    :
                  "No Response"
                }
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const ImageRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            Question {props.data.rank}:
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  <img width="100%" src={props.data.values[0]} />
                  :
                  "No Response"
                }
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const MultiselectRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                {(props.data.values.length > 0) ?
                  props.data.values.map((c, i) => (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox color="default" disabled name={c.option} checked={c.selected} />}
                      label={c.option}
                    />
                  ))
                  :
                  "No Response"
                }

              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const RadioRow = props => {
  const classes = rowStyles();
  const selected =  props.data.values.find(item => {
     return item.selected == true
  })
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {(props.data.values.length > 0) ?
                <RadioGroup row disabled aria-label="question" name="options" value={selected && selected.option}>
                  {props.data.values.map((q,i) => (
                    <div key={i} className={classes.checkboxRow}>
                      <FormControlLabel
                        value={q.option}
                        control={<Radio disabled value={q.option}/>}
                        label={q.option
                        }
                      />
                    </div>
                  ))}
                </RadioGroup>
                :
                "No Response"
              }
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const NumberRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
              Question {props.data.rank}:
              <div className={classes.title}>
                {props.name}
              </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {(props.data.values.length > 0) ? props.data.values[0] : "No Response"}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const Row = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainerDense}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {props.value}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

function TabPanel(props) {
  var classes = useStyles();

  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      className={classes.tab}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function SurveyResults(props) {

  var classes = useStyles();

  const [value, setValue] = useState(0);
  const [results, setResults] = useState(null);
  const [longResults, setLongResults] = useState([]);
  const [entry, setEntry] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [initSurvey, setInitSurvey] = useState(null);
  const [current, setCurrent] = useState(0);
  const [rtm, setRTM] = useState(false);
  const [hasInit, setHasInit] = useState(false);
  const [hasFinal, setHasFinal] = useState(false);
  const [journey, setJourney] = useState(null)

  const handleChange = (event, newValue) => {
    const path = generatePath(props.match.path, {journey_id: props.match.params.journey_id, "active_tab": ACTIVE_TAB[newValue]})
    props.history.push(path)
    setValue(newValue);
  };

  useEffect(() => {
    const active_tab = props.match.params.active_tab;
    if (active_tab) {
      for (let k in Object.keys(ACTIVE_TAB)) {
        if (active_tab == ACTIVE_TAB[k]) {
          setValue(parseInt(k))
          break
        }
      }
    }
  }, [props.match.params])

  useEffect(() => {
      async function getResults(){
        const id = props.match.params.journey_id;
        if (id) {
          const resp = await get_journey_result(id)
          const journey = await get_journey(id)
          setJourney(journey)
          const has_init_survey = journey && journey.initial_survey_result && (journey.initial_survey_result.length > 0)
          setHasInit(has_init_survey)
          setResults(resp && resp.data)
          setHasFinal(journey && journey.final_survey_result)
          var long_results = resp.data.filter(function (r) {
            return r.clinical_survey_result && (r.clinical_survey_result.length > 0);
          });
          setLongResults(long_results)
          if (long_results.length > 0) {
            setEntry(long_results[0])
          }
          if (journey && journey.clinical_study.study_type == 'REMOTE_THERAPEUTIC_MONITORING') {
            setRTM(true)
          }
        }
      }
      // getCommunities();

      getResults()
  }, [])

  function onPressNext() {
    if(current < longResults.length - 1) {
      setEntry(longResults[current+1])
      setCurrent(current+1)
    }
  }

  function onPressPrev() {
    if(current > 0) {
      setEntry(longResults[current-1])
      setCurrent(current-1)
    }
  }

  return (
    <>
    <Tabs
      variant="scrollable"
      value={value}
      indicatorColor="primary"
      aria-label="Vertical tabs example"
      onChange={handleChange}
      className={classes.tabs}
    >
      <Tab label="Survey Results" value={0}/>
      {hasInit && <Tab label="Initial Survey Results" value={1}/>}
      {hasFinal && <Tab label="Final Survey Results" value={2}/>}
      {rtm && <Tab label="RTM" value={3}/>}
    </Tabs>
    <TabPanel {...props} value={value} index={0}>
              <div className={classes.questionModuleContainer}>

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4>Patient:</h4>
                  <span style={{marginLeft: 20}}>{journey && journey.patient.first_name + ' ' + journey.patient.last_name}</span>
                </div>

                <div className={classes.dayNavRow}>


                {entry &&
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className={classes.dayContainer}>
                      <IconButton disabled={!longResults || longResults.length - 1 <= current} size="small" aria-label="prev day" onClick={onPressNext}>
                        <ArrowLeftIcon />
                      </IconButton>
                      <div className={classes.dayValue}>Day {entry && (entry.day_index + 1)}</div>
                      <IconButton disabled={current < 1} size="small" aria-label="next day" onClick={onPressPrev}>
                        <ArrowRightIcon />
                      </IconButton>
                    </div>
                  </div>
                }

                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    {longResults && longResults.length>0 && <div  style={{color: 'grey'}}>{longResults.length - (current)}/{longResults.length} responses</div>}
                    {longResults && longResults.length>0 &&<div style={{width: '100%'}}><LinearProgress variant="determinate" value={((longResults.length - current)/longResults.length)*100} /></div>}
                    <div style={{color: 'grey'}}>{entry && new Date(entry.created_date.includes('Z') ? entry.created_date : entry.created_date+'Z').toLocaleDateString("en-US")}</div>
                  </div>

                </div>
                <h4>Survey results:</h4>
                {
                  entry && entry.clinical_survey_result && entry.clinical_survey_result.map((c, i) => {
                    return (
                      c.type == "scale" ?
                      <ScaleRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      c.type == "multiselect" ?
                      <MultiselectRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      c.type == "radio" ?
                      <RadioRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      c.type == "image" ?
                      <ImageRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      c.type == "number" ?
                      <NumberRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      c.type == "text" ?
                      <NumberRow name={entry.journey.clinical_study.survey.questions[i].title} data={c} key={c.rank}/>
                      :
                      null
                    )})
                }
                {!entry && <>No survey responses yet</>}
                {entry && entry.body &&
                  <>
                    <h4>Patient Notes:</h4>
                      <p>{entry.title}</p>
                      <div dangerouslySetInnerHTML={{ __html: entry.body }} />
                  </>
                }
                {entry && entry.tags.length>0 &&
                  <>
                    <h4>Symptoms and side effects:</h4>
                      {entry.tags.map((t, i) => (
                        <Chip label={t} style={{margin: 5}}/>
                      ))}
                  </>
                }
              </div>
    </TabPanel>
    {hasInit && <TabPanel {...props} value={value} index={1}>
              <div className={classes.questionModuleContainer}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4>Patient:</h4>
                  <span style={{marginLeft: 20}}>{journey && journey.patient.first_name + ' ' + journey.patient.last_name}</span>
                </div>
                <div className={classes.dayNavRow}>
                </div>

                <h4>Initial survey results:</h4>
                {
                  journey.initial_survey_result.map((c, i) => {
                    let question = journey.clinical_study.initial_survey.questions[i].title
                    return (
                      c.type == "scale" ?
                      <ScaleRow name={question} data={c} key={i}/>
                      :
                      c.type == "multiselect" ?
                      <MultiselectRow name={question} data={c} key={i}/>
                      :
                      c.type == "radio" ?
                      <RadioRow name={question} data={c} key={i}/>
                      :
                      c.type == "image" ?
                      <ImageRow name={question} data={c} key={i}/>
                      :
                      c.type == "number" ?
                      <NumberRow name={question} data={c} key={i}/>
                      :
                      c.type == "text" ?
                      <NumberRow name={question} data={c} key={i}/>
                      :
                      null
                    )})
                }

              </div>
    </TabPanel>}
    {hasFinal && <TabPanel {...props} value={value} index={2}>
              <div className={classes.questionModuleContainer}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4>Patient:</h4>
                  <span style={{marginLeft: 20}}>{journey && journey.patient.first_name + ' ' + journey.patient.last_name}</span>
                </div>
                <div className={classes.dayNavRow}>
                </div>
                <h4>Final survey results:</h4>
                {
                  journey.final_survey_result.map((c, i) => {
                    let question = journey.clinical_study.final_survey.questions[i].title
                    return (
                      c.type == "scale" ?
                      <ScaleRow name={question} data={c} key={i}/>
                      :
                      c.type == "multiselect" ?
                      <MultiselectRow name={question} data={c} key={i}/>
                      :
                      c.type == "radio" ?
                      <RadioRow name={question} data={c} key={i}/>
                      :
                      c.type == "image" ?
                      <ImageRow name={question} data={c} key={i}/>
                      :
                      c.type == "number" ?
                      <NumberRow name={question} data={c} key={i}/>
                      :
                      c.type == "text" ?
                      <NumberRow name={question} data={c} key={i}/>
                      :
                      null
                    )})
                }

              </div>
    </TabPanel>}
    {rtm && <TabPanel {...props} value={value} index={3}>
      <RTMReport results={results} journey_id={props.match.params.journey_id} start={journey.first_entry_at} end={journey.end_date}/>
    </TabPanel>}

    </>
  );
}
