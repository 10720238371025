import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Checkbox
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Switch from '@material-ui/core/Switch';
import { handleNotification } from '../notifications/NotificationsClient';

import anime from 'animejs/lib/anime.es.js';

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

// context
import { useUserState, requestUser, confirmAccount } from "../../context/UserContext";
import { forgot_password, reset_password } from "../../api/auth";

const queryString = require('query-string');


function fitElementToParent(el, padding) {
  var timeout = null;
  function resize() {
    if (timeout) clearTimeout(timeout);
    anime.set(el, {scale: 1});
    var pad = padding || 0;
    var parentEl = el.parentNode;
    var elOffsetWidth = el.offsetWidth - pad;
    var parentOffsetWidth = parentEl.offsetWidth;
    var ratio = parentOffsetWidth / elOffsetWidth;
    timeout = setTimeout(anime.set(el, {scale: ratio}), 10);
  }
  resize();
  window.addEventListener('resize', resize);
}

function sphereAnimation() {
  var sphereEl = document.querySelector('#sphere-animation');
  if (!sphereEl) return
  var spherePathEls = sphereEl.querySelectorAll('#path');
  var pathLength = spherePathEls.length;
  var hasStarted = false;
  var aimations = [];

  fitElementToParent(sphereEl);

  var breathAnimation = anime({
    begin: function() {
      for (var i = 0; i < pathLength; i++) {
        aimations.push(anime({
          targets: spherePathEls[i],
          stroke: {value: ['rgba(75,255,75,1)', 'rgba(80,80,80,.15)'], duration: 500},
          // translateX: [1, -1],
          // translateY: [-1, 1],
          easing: 'easeOutQuad',
          autoplay: false
        }));
      }
    },
    update: function(ins) {
      aimations.forEach(function(animation, i) {
        var percent = (1 - Math.sin((i * .35) + (.0022 * ins.currentTime))) / 2;
        animation.seek(animation.duration * percent);
      });
    },
    duration: Infinity,
    autoplay: false
  });

  var introAnimation = anime.timeline({
    autoplay: false
  })
  .add({
    targets: spherePathEls,
    strokeDashoffset: {
      value: [anime.setDashoffset, 0],
      duration: 3900,
      easing: 'easeInOutCirc',
      delay: anime.stagger(190, {direction: 'reverse'})
    },
    duration: 2000,
    delay: anime.stagger(60, {direction: 'reverse'}),
    easing: 'linear'
  }, 0);

  var shadowAnimation = anime({
      targets: '#sphereGradient',
      x1: '05%',
      x2: '55%',
      y1: '0%',
      y2: '70%',
      duration: 30000,
      easing: 'easeOutExpo',
      autoplay: false
    }, 0);

  function init() {
    introAnimation.play();
    breathAnimation.play();
    shadowAnimation.play();
  }

  init();

};

function Forgot(props) {
  var classes = useStyles();
  // global
  var {dispatch, state} = useUserState();

  // local

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValueRepeat, setPasswordValueRepeat] = useState("");
  var [conditionValue, setConditionValue] = useState("");
  var [linkValue, setLinkValue] = useState("");
  var [isFDA, setIsFDA] = useState(false);
  var [emailValid, setEmailValid] = useState(true);
  var [affiliation, setAffiliation] = useState('affiliation');
  var [hostpialID, setHostpialID] = useState("");
  var [token, setToken] = useState(null);
  var [confirmed, setConfirmed] = useState(false);
  var [confirmLoading, setConfirmLoading] = useState(false);
  var [admin, setAdmin] = useState(false);
  var [code, setCode] = useState('');

  useEffect(() => {

    getToken();
    sphereAnimation();
  }, [])

  async function getToken() {
    var parsed = queryString.parse(props.location.search);
    if (parsed && parsed.token) {
      setToken(parsed.token)
    }
  }

  const handleAffiliationChange = (val) => {
    setAffiliation(val);
  };

  const handleAdminChange = (e) => {
    setAdmin(e.target.checked)
  }

  function handleGotoLogin() {
    props.history.push("/login")
  }

  function handleSubmit() {
    if (token) {
      handleReset()
    } else {
      handleForgot()
    }
  }

  async function handleForgot() {
    const resp = await forgot_password(loginValue);
    if (resp && !resp.error) {
      handleNotification("forgot success")
    }
  }

  async function handleReset() {
    const resp = await reset_password(token, passwordValue, passwordValueRepeat);
    if (resp && !resp.error) {
      handleNotification("reset success")
      props.history.push("/login")
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <div className={classes.rowContainer}>
          <div className={classes.animationWrapper}>
            <div className={classes.sphereAnimation} id="sphere-animation">
              <svg className={classes.spherePath} viewBox="110 83 120 120" stroke="rgba(80,80,80,.35)">
                <defs>
                  <linearGradient id="sphereGradient" x1="05%" x2="05%" y1="0%" y2="10%">
                    <stop stopColor="#21C87A" offset="0%"/>
                    <stop stopColor="#0D9F5B" offset="50%"/>
                    <stop stopColor="#016637" offset="100%"/>
                  </linearGradient>
                </defs>
                <path  fill="url('#sphereGradient')" id="path" d="M177.9,187.03v-24.77a1.257,1.257,0,0,1,1.2-1.23h21.7a4.945,4.945,0,0,0,4.9-4.98h0a4.942,4.942,0,0,0-4.9-4.97h-21.7a1.257,1.257,0,0,1-1.2-1.23v-18.71a27.912,27.912,0,0,0,0-4.09v-0.33h24.6a4.976,4.976,0,0,0,0-9.95h-26a1.188,1.188,0,0,1-1.1-.76,38.148,38.148,0,0,0-5.5-9.22c-10.6-13.38-27.6-21.02-32.5-22.99a1.219,1.219,0,0,0-1.6.87c-1.3,6.43-6.3,33.61,2.7,47.69,5.8,8.87,15.3,13.54,22.6,15.93q0.75,0.255,1.5.48a6.611,6.611,0,0,1,4.7,5.57,19.978,19.978,0,0,1,.2,2.54c0,1.28-.1,2.47-0.2,3.56a0.948,0.948,0,0,0,1.8.53v-0.06a18.757,18.757,0,0,0,1.3-7.06c0-.77,0-2.43-0.2-4.73a56.324,56.324,0,0,0-1.7-8.04,61.753,61.753,0,0,0-3.3-7.73c-1.4-3.03-2.7-5.31-4.1-7.72-1.8-3.21-3.3-5.65-4-6.74-2.3-3.73-5.5-8.35-9.4-13.45,1.7,1.92,15.4,17.16,21,28.4a59.3,59.3,0,0,1,3,7.57,45.629,45.629,0,0,1,1.8,7.7,21.267,21.267,0,0,1,.1,5.08,22.1,22.1,0,0,1-1.3,7.08,30.979,30.979,0,0,1-5.5,9.68,0.148,0.148,0,0,0-.1.08v-0.05c-0.1.21-.3,0.41-0.4,0.6v20.66a4.945,4.945,0,0,0,4.9,4.98h32.1a4.945,4.945,0,0,0,4.1-4.98h0a4.945,4.945,0,0,0-5.9-4.98h-24.2A1.257,1.257"/>
              </svg>
            </div>
          </div>
          {/*<img src={logo} alt="logo" className={classes.logotypeImage} />*/}
          <DashboardIcon className={classes.dashicon}/>
        </div>
        <Typography className={classes.logotypeText}>Edenos Partner Dashboard</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.opacityOverlay}>
          <div className={classes.form}>
          <React.Fragment>

            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>
            {!token ?
              <div>
                <Typography color="initial" className={classes.errorMessage}>
                  Please enter your email address. We will send you password reset instructions.
                </Typography>
                <TextField
                  error={!emailValid && loginValue.length > 0}
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                />
              </div>

              :
              <div>
                <Typography color="initial" className={classes.errorMessage}>
                  Please enter your new password.
                </Typography>
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="new password"
                  type="password"
                  fullWidth
                />
                <TextField
                  id="password2"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValueRepeat}
                  onChange={e => setPasswordValueRepeat(e.target.value)}
                  margin="normal"
                  placeholder="repeat new password"
                  type="password"
                  fullWidth
                />
              </div>
            }
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={(!token && (!emailValid || loginValue.length == 0)) || token && (passwordValue!=passwordValueRepeat || passwordValue.length == 0)}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{'textTransform': 'none'}}
                >
                  Submit
                </Button>
              )}
              <Button
                color="primary"
                size="small"
                className={classes.forgetButton}
                onClick={handleGotoLogin}
              >
                Back to login
              </Button>
            </div>
          </React.Fragment>
          </div>
          <Typography color="primary" className={classes.copyright}>
            Copyright © 2021 Edenos. All rights reserved.
          </Typography>
        </div>
      </div>
    </Grid>
  );


  function setEmail(email){
    setLoginValue(email);
    const valid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    setEmailValid(!!valid);
  }
}

export default withRouter(Forgot);
