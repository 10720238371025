import * as urls from './urls';
import { apiClient } from './apiClient';


export async function get_communities(dispatch) {
  let url = urls.COMMUNITIES();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_community_categories(id, dispatch) {
  let url = urls.COMMUNITY_CATEGORIES(id);
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_treatments(comm_id, dispatch) {
  let url = urls.TREATMENTS(comm_id);
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_treatment(treatment_id, dispatch=null) {
  let url = urls.TREATMENT(treatment_id);
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response.data
}

export async function get_patients(page=0, limit=20, dispatch=null) {
  let url = urls.GET_PATIENTS();
  let response = await apiClient(
    url,
    'GET',
    {},
    {
      page: page,
      limit: limit
    },
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function get_patient_analysis(id, dispatch=null) {
  let url = urls.PATIENT_ANALYSIS(id);
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function invite_patient(treatment_id, privated=false, first_name=null, last_name=null, email=null, dispatch) {
  let url = urls.INVITE_PATIENT(treatment_id);
  let response = await apiClient(
    url,
    'POST',
    {
      private: privated
    },
    {},
    dispatch
  )
  console.warn("INVITE_PATIENT (api):     ", response)
  return response
}

export async function resend_link(email, dispatch) {
  // let url = urls.ORG_USER();
  // let response = await apiClient(
  //   url + '?email=' + encodeURIComponent(email),
  //   'PUT',
  //   {},
  //   {},
  //   dispatch
  // )
  // console.warn("RESEND_LINK (api):     ", response)
  // return response
}

export async function get_invites(dispatch) {
  let url = urls.GET_INVITES();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  console.warn("GET INVITE (api):     ", response)
  return response
}

export async function get_orginfo(dispatch) {
  let url = urls.ORG_INFO();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function update_orginfo(dispatch, data) {
  let url = urls.ORG_INFO();
  let response = await apiClient(
    url,
    'PUT',
    data,
    {},
    dispatch
  )
  if (response.error) {
    return null
  } else {
    return response.data;
  }
}

export async function get_profile(dispatch) {
  let url = urls.PROFILE();
  let response = await apiClient(
    url,
    'GET',
    {},
    {},
    dispatch
  )
  return response
}
