import React from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from "../../components/Wrappers";
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledMenu from '../../components/StyledMenu';
import { delete_user } from '../../api/manage';
import { handleNotification } from '../notifications/NotificationsClient';

import useStyles from "./styles";

// data
import mock from "../dashboard/mock";

const states = {
  confirmed: "success",
  invited: "warning",
  sent: "secondary",
};


const columns = [
  {
    "name": "first_name",
    "label": "First Name",
    "options": {
     filter: false,
    }
  },
  {
    "name": "last_name",
    "label": "Last Name",
    "options": {
     filter: false,
    }
  },
  {
    "name": "email",
    "label": "Email",
    "options": {
     filter: false,
    }
  },
  {
    "name": "registration_status",
    "label": "Status"
  },
  {
    "name": "registration_status",
    "label": "Actions",
    "options": {
     filter: false,
    }
  }
]

export default function ManageTable(props) {
  var classes = useStyles();

  async function handleRemove(index) {
    try {
      let user = props.users[index]
      const resp = await delete_user(user && user.id)
      if (resp && !resp.error) {
        handleNotification("user remove success")
        props.handleRefreshUsers()
      }
      // props.removeUser(user)
    } catch (e) {

    }
  }

  return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {(props.isLoading) ?
            <div className={classes.center}>
              <CircularProgress color="primary" />
            </div>
            :
            <MUIDataTable
            title="Users List"
            data={props.users}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              customRowRender: (data, index) => {
                const [ firstname, lastname, email, status ] = data;
                return (
                  <TableRow key={email}>
                    <TableCell className="pl-3 fw-normal">{firstname}</TableCell>
                    <TableCell>{lastname}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>
                      <Button
                        disabled={status.toLowerCase() == 'confirmed'}
                        color={states[status.toLowerCase()]}
                        style={{textTransform: 'none'}}
                        size="small"
                        className="px-2"
                        variant="contained"
                        onClick={() => props.resend(email)}
                      >
                        {status.toLowerCase()}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <StyledMenu onRemove={() => handleRemove(index)}/>
                    </TableCell>
                  </TableRow>
                );
              }
            }}
          />
          }
        </Grid>
      </Grid>
  );
}
