import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ApexCharts from "react-apexcharts";

const data = [
  {
    name: 'Month 1',
    'recommended': 20,
    'your study': 5,
    amt: 2400,
  },
  {
    name: 'Month 2',
    'recommended': 60,
    'your study': 45,
    amt: 2210,
  },
  {
    name: 'Month 3',
    'recommended': 100,
    'your study': 88,
    amt: 2290,
  },
  {
    name: 'Month 4',
    'recommended': 140,
    'your study': 130,
    amt: 2000,
  },
  {
    name: 'Month 5',
    'recommended': 180,
    'your study': 192,
    amt: 2181,
  },
  {
    name: 'Month 6',
    'recommended': 220,
    'your study': 230,
    amt: 2500,
  },
  {
    name: 'Month 7',
    'recommended': 260,
    'your study': 245,
    amt: 2500,
  }
];

let options = {
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: [5, 5, 2],
    curve: 'straight',
    dashArray: [0, 5, 0]
  },
  title: {
    text: '',
    align: 'left'
  },
  legend: {
    tooltipHoverFormatter: function(val, opts) {
      return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
    }
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6
    }
  },
  xaxis: {
    categories: [
    ],
    type: 'datetime',
    label: 'aaa',
    title: 'xxx'
  },
  tooltip: {
    y: [
      {
        title: {
          formatter: function (val) {
            return val
          }
        }
      },
      {
        title: {
          formatter: function (val) {
            return val
          }
        }
      },
      {
        title: {
          formatter: function (val) {
            return val;
          }
        }
      }
    ]
  },
  grid: {
    borderColor: '#f1f1f1',
  }
}
export default class OnboardingChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

              series: [{
                  name: "onboarded",
                  data: [5, 22, 43, null,77, 105, 118, 132 ]
                },
                {
                  name: "recommended",
                  data: [20, 40, 60, 80, 100, 120 ,140, 160]
                },
                {
                  name: "goal",
                  data: [160, 160, 160, 160, 160, 160, 160, 160]
                }
              ]
          }
  }

  render() {
    const {onboarding, expected, size, init} = this.props;
    const [series, cats] = convertToSeries(onboarding, expected, size, init)
    options['xaxis'].categories = cats
    return (
      <div id="chart">
        <ApexCharts options={options} series={series} type="line" height={350} />
      </div>
    );
  }
}


function convertToSeries(actual, expected, size, init) {
  try {
    let series = [{
        name: "onboarded",
        data: []
      },
      {
        name: "recommended",
        data: []
      },
      {
        name: "goal",
        data: []
      }
    ]

    let categories = []

    if (size < 1 || !(actual && expected && size && init) || actual.length==0) {
      return [[], []]
    }

    let last_day = expected.slice(-1)[0].day
    let last_val = expected.slice(-1)[0].value

    let first_day = expected[0].day
    let first_val = expected[0].value

    if (first_day < actual[0].day) {
      categories.push(first_day)
      series[0].data.push(first_val)
    }

    for (let i=0; i < actual.length; i++) {
      categories.push(actual[i].day)
      let prev = (series[0].data.length>0) ? series[0].data[series[0].data.length - 1] : 0
      series[0].data.push(actual[i].value + prev)
      if (last_day > actual[i].day) {
        if ( i < (actual.length - 1)) {
          if ( last_day < actual[i+1].day) {
            categories.push(last_day)
            let interpolate = Math.floor(actual[i].value + (last_day-actual[i].day)/(actual[i+1].day-actual[i].day)*(actual[i+1].value-actual[i].value))
            prev = (series[0].data.length>0) ? series[0].data[series[0].data.length - 1] : 0
            series[0].data.push(interpolate+ prev)
          }
        } else {
          categories.push(last_day)
          series[0].data.push(null)
        }
      }
    }

    let dates = []
    for (let i=0; i < categories.length; i++) {
      let init_date = new Date(init);
      init_date.setDate(init_date.getDate() + categories[i]);
      dates[i] = init_date.toString()
      if (categories[i] <= last_day) {
        series[1].data.push(Math.floor((categories[i]/last_day)*size))
      }
      series[2].data.push(size)
    }

    return [series, dates];
  } catch (e) {
    console.warn("Error in onboardin graph ", e)
    return [[], []]
  }
}
