import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Divider,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Radio,
  Slider,
  FormControlLabel
 } from "@material-ui/core";
 import {
   Lock as LockIcon,
 } from "@material-ui/icons";
// components
import { Typography } from "../../components/Wrappers";
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import useStyles from "./styles";
import { get_survey_result } from "../../api/study";
import { makeStyles } from '@material-ui/styles';

const questions = {
			"id": "public_id",
			"results": [{
				"rank": 1,
				"type": "scale",
				"values": [2,7,10]
			}, {
				"rank": 2,
				"type": "multiselect",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 3,
				"type": "radio",
				"values": [{
					"option": "a",
					"selected": true
				}, {
					"option": "b",
					"selected": false
				}]
			}, {
				"rank": 4,
				"type": "image",
				"values": ["https://s3-us-west-2.amazonaws.com/eden-qa-milkyway/users/0e84073d-a858-4c3a-a652-79f80ce4bd86.jpe"]
			}]
		}

const rowStyles = makeStyles(theme => ({
  rowContainer: {
    paddingTop: '40px',
    paddingRight: '10px',
    margin: '5px',
    overflow: 'hidden',
  },
  title: {
    fontWeight: '600',
    color: 'grey'
  },
  value: {
    alignSelf: 'flex-end'
  }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ScaleRow = props => {
  const classes = rowStyles();
  const marks = [
    {
      value: props.data.values[0],
      label: props.data.values[0],
    },
    {
      value: props.data.values[2],
      label: props.data.values[2],
    }
  ]
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                <Slider
                  disabled
                  defaultValue={props.data.values[1]}
                  valueLabelDisplay="on"
                  min={props.data.values[0]}
                  max={props.data.values[2]}
                  aria-labelledby="disabled-slider"
                  marks={marks}/>
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const ImageRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
              <div className={classes.value}>
                <img width="100%" src={props.data.values.length > 0 ? props.data.values[0] : ''} />
              </div>
            </Grid>
        </Grid>
      </div>
  )
}

const MultiselectRow = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {props.data.values.map((c, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="default" disabled name={c.option} checked={c.selected} />}
                  label={c.option}
                />
              ))}

            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const RadioRow = props => {
  const classes = rowStyles();
  const selected =  props.data.values.find(item => {
     return item.selected == true
  })
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              <RadioGroup row disabled aria-label="question" name="options" value={selected.option}>
                {props.data.values.map((q,i) => (
                  <div key={i} className={classes.checkboxRow}>
                    <FormControlLabel
                      value={q.option}
                      control={<Radio disabled/>}
                      label={q.option
                      }
                    />
                  </div>
                ))}
              </RadioGroup>
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

const Row = props => {
  const classes = rowStyles();
  return (
      <div className={classes.rowContainer}>
        <Grid
          container
          direction="row"
          justifycontent="space-between"
          spacing={2}>
            <Grid item sm={6} md={4} lg={4}>
            <div className={classes.title}>
              {props.name}
            </div>
            </Grid>
            <Grid item sm={6} md={8} lg={8}>
            <div className={classes.value}>
              {props.value}
            </div>
            </Grid>
        </Grid>
      </div>
  )
}

function TabPanel(props) {
  var classes = useStyles();

  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      className={classes.tab}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function SurveyResults(props) {

  var classes = useStyles();

  const [value, setValue] = useState(0);
  const [results, setResults] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
      async function getResults(){
        const id = props.match.params.id;
        const resp = await get_survey_result(id)
        if (id) {
          console.warn("data",resp)
          setResults(resp && resp.data)
        }
      }
      // getCommunities();

      getResults()
  }, [])

  const has_init_survey = results && results.initial_survey_result && (results.initial_survey_result.length > 0)
  console.warn("has_init_survey",has_init_survey, results)
  return (
    <>
    <Tabs
      variant="scrollable"
      value={value}
      indicatorColor="primary"
      aria-label="Vertical tabs example"
      className={classes.tabs}
    >
      {has_init_survey && <Tab label="Initial Survey Results"  {...a11yProps(1)}/>}
      <Tab label="Survey Results"  {...a11yProps(2)}/>
    </Tabs>
    <TabPanel {...props} value={value} index={0}>
              <div className={classes.questionModuleContainer}>
                <h4>Study Info:</h4>
                <Row name={"Study"} value={"studyName"}/>
                <Row name={"Treatment"} value={"treatment && treatment.name"}/>
                <Divider/>
                <h4>Initial Survey results:</h4>
                <Row name={"Number of Questions"} value={questions && questions.results.length}/>
                {
                  questions && questions.results.map((c, i) => (
                    c.type == "scale" ?
                    <ScaleRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "multiselect" ?
                    <MultiselectRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "radio" ?
                    <RadioRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "image" ?
                    <ImageRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    null
                  ))
                }

              </div>
    </TabPanel>
    <TabPanel {...props} value={value} index={1}>
              <div className={classes.questionModuleContainer}>
                <h4>Study Info:</h4>
                <Row name={"Study"} value={"studyName"}/>
                <Row name={"Treatment"} value={"treatment && treatment.name"}/>
                <Divider/>
                <h4>Survey results:</h4>
                <Row name={"Number of Questions"} value={questions && questions.results.length}/>
                {
                  questions && questions.results.map((c, i) => (
                    c.type == "scale" ?
                    <ScaleRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "multiselect" ?
                    <MultiselectRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "radio" ?
                    <RadioRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    c.type == "image" ?
                    <ImageRow name={`Question ${i+1}`} data={c} key={i}/>
                    :
                    null
                  ))
                }

              </div>
    </TabPanel>


    </>
  );
}
