import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#2bc77d',
        backgroundColor: '#2bc77d',
        color: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        fontStyle: 'bold',
        paddingLeft: 10,
        flexGrow: 1,
    },
    date: {
        width: '100%'
    },
});

const DayHeader = ({date}) => (
    <View style={styles.container}>
        <Text style={styles.date}>{format(parseISO(date), 'MM/dd/yyyy')}</Text>
    </View>
);

export default DayHeader;
