import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  Link,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import DashboardIcon from '@material-ui/icons/Dashboard';
// styles
import useStyles from "./styles";

// logo
import logo from "../../images/Edenos_logo_secondary.svg";
import bkgnd from "../../images/bkgnd.png";
import google from "../../images/google.svg";

import anime from 'animejs/lib/anime.es.js';

// context
import { useUserState, requestUser, completeSignUp } from "../../context/UserContext";

var jwtDecode = require('jwt-decode');
const queryString = require('query-string');

function fitElementToParent(el, padding) {
  var timeout = null;
  function resize() {
    if (timeout) clearTimeout(timeout);
    anime.set(el, {scale: 1});
    var pad = padding || 0;
    var parentEl = el.parentNode;
    var elOffsetWidth = el.offsetWidth - pad;
    var parentOffsetWidth = parentEl.offsetWidth;
    var ratio = parentOffsetWidth / elOffsetWidth;
    timeout = setTimeout(anime.set(el, {scale: ratio}), 10);
  }
  resize();
  window.addEventListener('resize', resize);
}

function sphereAnimation() {
  var sphereEl = document.querySelector('#sphere-animation');
  if (!sphereEl) return
  var spherePathEls = sphereEl.querySelectorAll('#path');
  var pathLength = spherePathEls.length;
  var hasStarted = false;
  var aimations = [];

  fitElementToParent(sphereEl);

  var breathAnimation = anime({
    begin: function() {
      for (var i = 0; i < pathLength; i++) {
        aimations.push(anime({
          targets: spherePathEls[i],
          stroke: {value: ['rgba(75,255,75,1)', 'rgba(80,80,80,.15)'], duration: 500},
          // translateX: [1, -1],
          // translateY: [-1, 1],
          easing: 'easeOutQuad',
          autoplay: false
        }));
      }
    },
    update: function(ins) {
      aimations.forEach(function(animation, i) {
        var percent = (1 - Math.sin((i * .35) + (.0022 * ins.currentTime))) / 2;
        animation.seek(animation.duration * percent);
      });
    },
    duration: Infinity,
    autoplay: false
  });

  var introAnimation = anime.timeline({
    autoplay: false
  })
  .add({
    targets: spherePathEls,
    strokeDashoffset: {
      value: [anime.setDashoffset, 0],
      duration: 3900,
      easing: 'easeInOutCirc',
      delay: anime.stagger(190, {direction: 'reverse'})
    },
    duration: 2000,
    delay: anime.stagger(60, {direction: 'reverse'}),
    easing: 'linear'
  }, 0);

  var shadowAnimation = anime({
      targets: '#sphereGradient',
      x1: '05%',
      x2: '55%',
      y1: '0%',
      y2: '70%',
      duration: 30000,
      easing: 'easeOutExpo',
      autoplay: false
    }, 0);

  function init() {
    introAnimation.play();
    breathAnimation.play();
    shadowAnimation.play();
  }

  init();

};

function Register(props) {
  var classes = useStyles();

  // global
  var {dispatch} = useUserState();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [firstName, setFirstName] = useState("");
  var [familyName, setFamilyName] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [password, setPassword] = useState("");
  var [emailValid, setEmailValid] = useState(true);
  var [token, setToken] = useState(null);
  var [tokenInfo, setTokenInfo] = useState(null);
  var [orgName, setOrgName] = useState(null);
  var [org, setOrg] = useState({
    name: ""
  });

  useEffect(() => {
    function getToken() {
      var parsed = queryString.parse(props.location.search);
      if (parsed && parsed.k) {
        setToken(parsed.k)
        let decoded = jwtDecode(parsed.k)
        if (decoded) {
          if (decoded.status == "CONFIRMED") {
            props.history.push("/login?inv="+parsed.k)
          }
          setTokenInfo(decoded)
          if (decoded.organization && decoded.organization.confirmed) {
            setOrg({
              name: decoded.organization.name
            })
          }
        }
        console.warn("parsed",decoded)
      }
    }
    getToken();
    sphereAnimation();
  }, [])

  function addOrgName(name) {
    let new_info = org
    new_info.name = name
    setOrg(new_info)
    setOrgName(name)
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <div className={classes.rowContainer}>
          <div className={classes.animationWrapper}>
            <div className={classes.sphereAnimation} id="sphere-animation">
              <svg className={classes.spherePath} viewBox="110 83 120 120" stroke="rgba(80,80,80,.35)">
                <defs>
                  <linearGradient id="sphereGradient" x1="05%" x2="05%" y1="0%" y2="10%">
                    <stop stopColor="#21C87A" offset="0%"/>
                    <stop stopColor="#0D9F5B" offset="50%"/>
                    <stop stopColor="#016637" offset="100%"/>
                  </linearGradient>
                </defs>
                <path  fill="url('#sphereGradient')" id="path" d="M177.9,187.03v-24.77a1.257,1.257,0,0,1,1.2-1.23h21.7a4.945,4.945,0,0,0,4.9-4.98h0a4.942,4.942,0,0,0-4.9-4.97h-21.7a1.257,1.257,0,0,1-1.2-1.23v-18.71a27.912,27.912,0,0,0,0-4.09v-0.33h24.6a4.976,4.976,0,0,0,0-9.95h-26a1.188,1.188,0,0,1-1.1-.76,38.148,38.148,0,0,0-5.5-9.22c-10.6-13.38-27.6-21.02-32.5-22.99a1.219,1.219,0,0,0-1.6.87c-1.3,6.43-6.3,33.61,2.7,47.69,5.8,8.87,15.3,13.54,22.6,15.93q0.75,0.255,1.5.48a6.611,6.611,0,0,1,4.7,5.57,19.978,19.978,0,0,1,.2,2.54c0,1.28-.1,2.47-0.2,3.56a0.948,0.948,0,0,0,1.8.53v-0.06a18.757,18.757,0,0,0,1.3-7.06c0-.77,0-2.43-0.2-4.73a56.324,56.324,0,0,0-1.7-8.04,61.753,61.753,0,0,0-3.3-7.73c-1.4-3.03-2.7-5.31-4.1-7.72-1.8-3.21-3.3-5.65-4-6.74-2.3-3.73-5.5-8.35-9.4-13.45,1.7,1.92,15.4,17.16,21,28.4a59.3,59.3,0,0,1,3,7.57,45.629,45.629,0,0,1,1.8,7.7,21.267,21.267,0,0,1,.1,5.08,22.1,22.1,0,0,1-1.3,7.08,30.979,30.979,0,0,1-5.5,9.68,0.148,0.148,0,0,0-.1.08v-0.05c-0.1.21-.3,0.41-0.4,0.6v20.66a4.945,4.945,0,0,0,4.9,4.98h32.1a4.945,4.945,0,0,0,4.1-4.98h0a4.945,4.945,0,0,0-5.9-4.98h-24.2A1.257,1.257"/>
              </svg>
            </div>
          </div>
          {/*<img src={logo} alt="logo" className={classes.logotypeImage} />*/}
          <DashboardIcon className={classes.dashicon}/>
        </div>
        <Typography className={classes.logotypeText}>Edenos Partner Dashboard</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.opacityOverlay}>
          <div className={classes.form}>

            {(
              <React.Fragment>
                {(tokenInfo && tokenInfo.organization) &&
                  tokenInfo.organization.confirmed ?
                  <Typography variant="h6" className={classes.subGreeting}>
                      <Typography variant="p" style={{'fontWeight': 600}}>{tokenInfo.organization.name}</Typography> has invited you to join!
                  </Typography>
                  :
                  <Typography variant="h6" className={classes.subGreeting}>
                      {(tokenInfo && tokenInfo.study) ? 'You have been invited you to join and help with a clinical study!' : 'You have been invited you to join!'}
                  </Typography>
                }
                <Typography variant="p" className={classes.subGreeting}>
                  Please complete your registration.
                </Typography>
                <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                  <div className={classes.formDivider} />
                </div>
                <TextField
                  error={!emailValid && loginValue.length > 0}
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                />
                <TextField
                  id="first name"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  margin="normal"
                  placeholder="First Name"
                  type="email"
                  fullWidth
                />
                <TextField
                  required
                  id="family name"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={familyName}
                  onChange={e => setFamilyName(e.target.value)}
                  margin="normal"
                  placeholder="Family Name"
                  type="email"
                  fullWidth
                />
                <TextField
                  required
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  margin="normal"
                  placeholder="password (minimum 8 characters)"
                  type="password"
                  fullWidth
                />
                {(tokenInfo && tokenInfo.organization && !tokenInfo.organization.confirmed) &&
                  <TextField
                    required
                    id="org"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={orgName}
                    onChange={e => addOrgName(e.target.value)}
                    margin="normal"
                    placeholder="Name of your organization"
                    type="text"
                    fullWidth
                  />
                }
                <div className={classes.creatingButtonContainer}>
                  {isLoading ? (
                    <CircularProgress size={26} />
                  ) : (
                    <div>
                      <Button
                        onClick={() =>
                          completeSignUp(
                            dispatch,
                            token,
                            loginValue,
                            password,
                            firstName,
                            familyName,
                            org,
                            props.history,
                            setIsLoading,
                            setError,
                          )
                        }
                        disabled={
                          loginValue.length === 0 ||
                          firstName.length === 0 ||
                          familyName.length === 0 ||
                          password.length === 0 ||
                          !emailValid
                        }
                        size="medium"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.createAccountButton}
                      >
                        Sign Up
                      </Button>

                      <div className={classes.footer}>
                        <Typography variant="p">
                          {`By signing up you are agreeing to `}
                          <Link
                            variant="body3"
                            href={"/tos"}>
                            terms of service
                          </Link>
                        </Typography>
                      </div>

                      <div className={classes.footer}>
                        <Typography variant="p">
                          Already have an account?
                        </Typography>
                        <div>
                          <Link
                            variant="body2"
                            color="primary"
                            href={"/login"}>
                            Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
          <Typography color="primary" className={classes.copyright}>
            Copyright © 2021 Edenos. All rights reserved.
          </Typography>
        </div>
      </div>
    </Grid>
  );



  function setEmail(email){
    setLoginValue(email);
    emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    setEmailValid(!!emailValid);
  }
}

export default withRouter(Register);
