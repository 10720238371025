import React, { useState, useEffect } from "react";
import {CardElement} from '@stripe/react-stripe-js';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import useStyles from "./styles";
import MaskedInput from 'react-text-mask';
import MoreInfo from '../../components/Dialog';
import { BootstrapInput } from '../../components/BootstrapInput';

// import { Button } from "@material-ui/core";

// class CardSection extends React.Component {
//   render() {
//     return (
//       <label>
//         Card details
//         <CardElement style={{base: {fontSize: '18px'}}} />
//       </label>
//     );
//   }
// }

const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

export default function InfoForm(props) {
  // handleSubmit = (ev) => {
  //   ev.preventDefault();
  //   if (this.props.stripe) {
  //     this.props.stripe
  //       .createToken()
  //       .then((payload) => console.log('[token]', payload));
  //   } else {
  //     console.log("Stripe.js hasn't loaded yet.");
  //   }
  // };

  var classes = useStyles();
  const info = props.org

  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberRaw, setPhoneNumberRaw] = useState('');
  const [website, setWebsite] = useState('');
  const [fdaStatus, setFdaStatus] = useState(false);
  const [feiValue, setFeiValue] = useState('');

  const handleChangeStatus = event => {
    setFdaStatus(event.target.checked);
  };

  useEffect(() => {
      if(info) {
        setName(info.name)
        setWebsite(info.website)
        setEmail(info.primary_email)
        setPhoneNumberRaw(info.phone_number)
        setPhoneNumber(formatTel(info.phone_number))
        setIsLoading(false)
      }
  }, [info])

  function formatTel(num) {
    if (!num)
      return ''
    var match = num.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
  }

  function changePhoneNumber(num) {
    var raw = num ? num.replace(/[^0-9]/g,'') : ''
    setPhoneNumber(num)
    setPhoneNumberRaw(raw)
  }

  function updateInfo() {
    props.update({
      "website": website,
      "phone_number": phoneNumberRaw
    })
  }

  const disabled = (info) && (info.name == name) && (info.website == website) && (info.admin == email) && (info.phone_number == phoneNumberRaw)
  return (
    <form className={classes.infoForm}>

      <FormGroup row>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="phone-input">
            organization name
          </InputLabel>
          <BootstrapInput
            disabled={true}
            label="company name"
            placeholder="company name"
            id="company-name"
            className={classes.textField}
            margin="dense"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="org-id">
            organization ID
          </InputLabel>
          <BootstrapInput
            disabled={true}
            label="org ID"
            placeholder="org ID"
            id="org-id"
            className={classes.textField}
            margin="dense"
            value={info && info.id}
          />
        </FormControl>
      </FormGroup>

      <FormGroup row>
        {/*<FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="phone-input">
            phone number
          </InputLabel>
          <BootstrapInput
            label="Phone number"
            id="company-num"
            className={classes.textField}
            value={phoneNumber}
            onChange={e => changePhoneNumber(e.target.value)}
            inputComponent={TextMaskCustom}
          />
        </FormControl>*/}
      </FormGroup>

      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="email">
          admin email
        </InputLabel>
        <BootstrapInput
          disabled={true}
          label="email"
          id="company-email"
          className={classes.textField}
          margin="dense"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormControl>

      {/*<FormGroup row>
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              checked={fdaStatus}
              onChange={handleChangeStatus}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="FDA Established"
        />
        <div className={classes.test}>
        <FormControl className={classes.formControl}>
          <BootstrapInput
            label="FEI number"
            id="fda-reg"
            placeholder="Establishment Identifier"
            disabled={!fdaStatus}
            className={classes.textField}
            margin="dense"
            required={fdaStatus}
            value={feiValue}
            onChange={e => setFeiValue(e.target.value)}
          />
        </FormControl>
        <MoreInfo title="Establishment Identifier" body="An FDA Establishment Identification (FEI) number is a unique identifier issued by FDA to track inspections of the regulated establishment or facility. FEI numbers are also used to track GDUFA facility fee payments. Please note that an FEI number is different from a Central File Number and Federal Tax Identification Number. If you have questions about your assigned FEI numbers, please contact the appropriate FDA district office (for domestic firms) or email FDAGDUFAFEIRequest@fda.hhs.gov (for foreign firms)." />
        </div>
      </FormGroup>*/}

      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="website">
          website
        </InputLabel>
        <BootstrapInput
          label="website"
          id="website"
          placeholder="www.example.com"
          className={classes.textField}
          margin="dense"
          value={website}
          onChange={e => setWebsite(e.target.value)}
        />
      </FormControl>

      <div className={classes.saveBtn} >
        <Button
          variant="contained"
          color="primary"
          disabled={true}
          onClick={() => updateInfo()}>
          Update
        </Button>
      </div>
    </form>
  );
}
