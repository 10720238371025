const componentStyles = (theme) => ({
  wrapperBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundImage: "/img/tier2.jpg",
  },
  overlayBox: {
    transition: "all .15s ease",
    opacity: ".5",
    background:
      "linear-gradient(87deg," +
      theme.palette.secondary.main +
      ",#1a174d)!important",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
  typographyRootH1: {
    color: theme.palette.background.light,
    fontSize: "2.75rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  buttonRoot: {
    color: theme.palette.background.light,
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  coverPhoto: {
    height: "100%",
    width: "100%",
    alignSelf: "center",
    objectFit: 'cover',
  }
});

export default componentStyles;
