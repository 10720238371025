import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { Page, Document, StyleSheet, Image, Text, View } from "@react-pdf/renderer";
import InvoiceTableHeader from './header';
import QuestionHeader from './questionHeader';
import SummaryHeader from './summaryHeader';
import DayHeader from './dayHeader';
import CalendarHeatmapPDF from './calendarHeatMapPDF';
// import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './footer';
import { toPng } from 'html-to-image';
import logo from "../../../images/Edenos Logo.jpg";

// import './vendor/normalize.css';
// import './global.css';
import './report.css';


const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingBottom: 100,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 70,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    image: {
      width: 400,
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    imageContainer: {
      marginTop: 20
    },
    footerLogo: {
        width: 22,
        height: 28,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
      marginTop: 24,
    },
    reportTitle: {
        color: '#2bc77d',
        letterSpacing: 1,
        fontSize: 22,
        textAlign: 'center',
    },
    sectionContainer: {
      marginTop: 30,
      marginBottom: 20
    },
    sectionTitle: {
        color: '#aaaaaa',
        fontSize: 16,
    },
    patientInfoContainer: {
      marginTop: 36,
    },
    patientIDContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    reportDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 120
    },
    headerContainer: {
        marginTop: 36,
        justifyContent: 'flex-start',
        width: '50%'
    },
    tableContainer: {
      marginBottom: 40
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    daysrow: {
        padding: 5,
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
    },
    description: {
        width: '80%',
        textAlign: 'left',
        borderRightColor: '#FFF',
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    type: {
        width: '20%',
        textAlign: 'center',
    },
    dayQuestion: {
        fontStyle: 'bold',
    },
    dayAnswer: {
        color: 'grey'
    },
    footerContainer: {
      left: 50,
      right: 50,
      position: 'absolute',
      bottom: 30
    },
    footerLogoContainer: {
      width: 50,
      marginRight: 30
    },
    footer: {
      width: '100%',
      flex: 1,
      borderTopWidth: 1,
      paddingTop: 10,
      borderTopColor: 'grey',
      flexDirection: 'row'
    },
    footerText: {
      fontSize: 8,
      color: 'grey'
    }
});

function surveyjsonToValue (item) {
  switch (item.type) {
    case "scale":
      return `${item.values[1]}   (range: ${item.values[0]} - ${item.values[2]})`
    case "radio":
      let selected = item.values.find(i => i.selected)
      if (!selected) return '-'
      return `${selected.option}`
    case "number":
      return `${item.values.length ? item.values[0] : '-'}`
    case "text":
      return `${item.values.length ? item.values[0] : '-'}`
      break;
    case "multiselect":
      let selectedList = item.values.filter(i => i.selected)
      let selectedOptions = selectedList.map(i => i.option)
      if (!selectedOptions) return '-'
      return `${selectedOptions.join(', ')}`
    default:
      return "-"
  }
}

const BillTo = ({ report }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>{report.fullname}</Text>
        <Text>{report.address}</Text>
        <Text>{report.phone}</Text>
        <Text>{report.email}</Text>
    </View>
);

const InvoiceTitle = ({ title }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

const Section = ({ title }) => (
    <View style={styles.sectionContainer}>
        <Text style={styles.sectionTitle}>{title}</Text>
    </View>
);

const PatientInfo = ({ report, data, patient, start, end }) => (
    <View style={styles.patientInfoContainer}>
        <View style={styles.patientIDContainer}>
            <Text style={styles.label}>Patient ID:</Text>
            <Text style={styles.invoiceDate}>{patient.id}</Text>
        </View >
        <View style={styles.patientIDContainer}>
            <Text style={styles.label}>Patient Name:</Text>
            <Text style={styles.invoiceDate}>{patient.first_name + ' ' + patient.last_name}</Text>
        </View >
        <View style={styles.reportDateContainer}>
            <Text style={styles.label}>Period: </Text>
            <Text >{start} </Text>
            <Text > to </Text>
            <Text >{end} </Text>
        </View >
    </View>
);

const InvoiceItemsTable = ({ report }) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        {/*<InvoiceTableRow items={report.items} />*/}
        <InvoiceTableFooter items={report.items} />
    </View>
);

const QuestionTableRow = ({ items }) => {
    const rows = items && items.map(item =>
        <View style={styles.row} key={item.rank}>
            <Text style={styles.description}>{item.title}</Text>
            <Text style={styles.type}>{item.type}</Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

const QuestionTable = ({ questions, survey_summary }) => (
    <View style={styles.tableContainer}>
        <QuestionHeader />
        <QuestionTableRow items={questions} />
    </View>
);

const SummaryTable = ({ questions, survey_summary, duration }) => {
  return (
    <View style={styles.tableContainer}>
        <SummaryHeader />
        <SummaryTableRow vari={'Number of Entries'} val={survey_summary.journey_entry_count} />
        <SummaryTableRow vari={'Total Days'} val={duration} />
        <SummaryTableRow vari={'Compliance'} val={`${Math.round((survey_summary.journey_entry_count/(duration + 0.0000001)) * 100)} %`}/>
        <SummaryTableRow vari={'Number of Survey Questions'} val={questions.length} />

    </View>
)};

const SummaryTableRow = ({ vari, val }) => (
    <View style={styles.row}>
        <Text style={styles.description}>{vari}</Text>
        <Text style={styles.type}>{val}</Text>
    </View>
);

const DaysTableRow = ({ questions, items }) => {
    const rows = items && items.map((item, index) =>
        <View style={styles.daysrow} key={item.rank}>
            <Text style={styles.dayQuestion}>{questions[index].title}</Text>
            <Text style={styles.dayAnswer}>{surveyjsonToValue(item)}</Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};


const DaysTable = ({ questions, entries }) => (
    <Fragment>
        {(questions.length>0) && entries && entries.map(item =>
          <View style={styles.tableContainer} key={item.id}>
            <DayHeader date={item.journey.first_entry_at.includes('Z') ? item.journey.first_entry_at : item.journey.first_entry_at+'Z'}/>
            <DaysTableRow questions={questions} items={item.clinical_survey_result}/>
          </View>
        )}
    </Fragment>
);
const Report = ({ invoicedata, questions, analytics, entries, patient, values, start, end, images }) => {
    const date1 = new Date(start.includes('Z') ? start : start+'Z');
    const date2 = new Date(end.includes('Z') ? end : end+'Z');
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap >
                <Image style={styles.logo} src={logo} />
                <InvoiceTitle title={'RTM Report'} />
                <PatientInfo report={invoicedata} data={analytics} patient={patient} start={start} end={end}/>
                <Section title={"Summary"} />
                <SummaryTable questions={questions} survey_summary={analytics} duration={diffDays}/>
                <Section title={"Questions"} />
                <QuestionTable questions={questions} survey_summary={analytics.survey_summary}/>
                <Section title={"Compliance Graph"} />
                <View style={{width: (diffDays > 60) ? '100%' : '50%'}}>
                <CalendarHeatmapPDF
                  startDate={new Date(start)}
                  endDate={new Date(end)}
                  values={entries}
                  showWeekdayLabels={true}
                  showMonthLabels={false}
                  horizontal={false}
                />
                </View>
                <Section title={"Survey Responses"} />
                <DaysTable questions={questions} entries={entries}/>

                {(images.length > 0) && <Section title={"Charts"} />}
                {images.map((im,i) => (
                  <View key={i} style={styles.imageContainer}>
                    <Image style={styles.image} src={im} />
                  </View>
                ))}

                <View style={styles.footerContainer} render={({ pageNumber, totalPages }) => (
                  <View style={styles.footer}>
                    <View style={styles.footerLogoContainer}>
                      <Text style={styles.footerText}>
                        {`page ${pageNumber} / ${totalPages}`}
                      </Text>
                    </View>
                    <Text style={styles.footerText}>
                      Generated from Edenos Clinical Platform. Copyright © 2024 Edenos.
                    </Text>
                  </View>
                )} fixed />
            </Page>
        </Document>
    );
}


export default Report;
