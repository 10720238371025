import  React from 'react';
import { useUserState } from "../context/UserContext";
// import { allowedUserChecker } from 'utils';
import PropTypes from 'prop-types';

const RBAC = ({ allowedRoles, secondaryAccess=false, allowedOrgs, allowedPermissions=[], children, ...rest }) => {
    var { state } = useUserState();
    var { isAuthenticated, is_admin, user_type, org, roles, permissions, profile } = state;
    let access = secondaryAccess || allowedUserChecker(roles, permissions, allowedRoles, allowedPermissions, org, allowedOrgs);
    return access && (children!=null) && React.cloneElement(children, {...rest})
};

RBAC.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element
};

export default RBAC;

function allowedUserChecker(roles, permissions, allowedRoles, allowedPermissions, org, allowedOrgs) {
  let role_ok = false;
  let org_ok = false;
  let perm_ok = false;
  if(!allowedRoles || allowedRoles.length==0) {
    role_ok = true
  } else if (!roles || roles.length==0) {
    role_ok = false
  } else {
    const filteredArray = roles.filter(value => allowedRoles.includes(value));
    role_ok = (filteredArray.length > 0)
  }

  if(!allowedOrgs || allowedOrgs.length==0) {
    org_ok = true
  } else if (!org) {
    org_ok = false
  } else {
    org_ok = allowedOrgs.includes(org.type)
  }

  if(!allowedPermissions || allowedPermissions.length==0) {
    perm_ok = true
  } else if (!permissions || permissions.length==0) {
    perm_ok = false
  } else {
    perm_ok =  permissions.every(val => allowedPermissions.includes(val));
  }

  return role_ok && org_ok && perm_ok
}
