import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import { CircularProgress } from "@material-ui/core";
// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import CreatorSidebar from "../Sidebar/CreatorSidebar";

// pages
import Dashboard from "../../pages/creatordashboard/Dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Promotions from "../../pages/promotions";
import Manage from "../../pages/manage";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Account from "../../pages/account/creatorAccount/CreatorAccount";
import Support from "../../pages/support";
import Curate from "../../pages/curate";

// context
import { useUserState } from "../../context/UserContext";
import { useLayoutState } from "../../context/LayoutContext";
import { StatsProvider } from "../../context/StatsContext";

function CreatorLayout(props) {
  var classes = useStyles();

  var { state } = useUserState();
  var { isAuthenticated, is_admin, user_type, profile } = state;
  // global
  var layoutState = useLayoutState();

  return (
    <StatsProvider>
      <div className={classes.root}>
          {profile ?
          <>
            <Header history={props.history} />
            <CreatorSidebar />
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />
              <Switch>
                <Route exact path="/dashboard/:prod_id?" component={Dashboard} />
                <Route path="/typography" component={Typography} />
                <Route path="/promotions" component={Promotions} />
                <Route path="/account" component={Account} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/support" component={Support} />
                <Route path="/library" component={Curate} />

              </Switch>
            </div>
          </>
          :
          <CircularProgress />
        }
      </div>
    </StatsProvider>
  );
}

export default withRouter(CreatorLayout);
