import  React, {useState} from 'react';
import { useUserState } from "../context/UserContext";
import {
  Modal
} from "@material-ui/core";
// import { allowedUserChecker } from 'utils';
import PropTypes from 'prop-types';
import PickPlans from  "../pages/account/PickPlans";
import useStyles from "../pages/account/styles";
import LockedFeature from "../pages/insight/LockedFeature";
import { handleNotification } from '../pages/notifications/NotificationsClient';


const SubscriptionMW = ({ children, ...rest }) => {
    var { state } = useUserState();
    var classes = useStyles();
    var { isAuthenticated, is_admin, user_type, org, roles, permissions, profile } = state;

    const [plansOpen, setPlansOpen] = useState(true);

    let admin = roles && (roles.length>0) && (roles[0] == 'RESEARCH_ADMIN')
    let active = org && org.subscription && (org.subscription.status=='ACTIVE')
    let has_rtm = false
    let has_research = false
    if (org && org.subscription && org.subscription.plans) {
      has_rtm = org.subscription.plans.find((p) => p.study_type == 'REMOTE_THERAPEUTIC_MONITORING')
      has_research = org.subscription.plans.find((p) => p.study_type == 'RESEARCH')
    }

    function refreshPage() {
      setPlansOpen(false)
      handleNotification("subscription success")
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }

    let access = ((org && org.type == 'DELEGATED') || rest.type == 'REMOTE_THERAPEUTIC_MONITORING' && has_rtm && active) || (rest.type == 'RESEARCH' && has_research && active)
    if (access) {
      return children
    } else {
      return (
        <div>
          <LockedFeature/>
          <Modal
                open={plansOpen && admin && org}
                onClose={() => cancelSub(rest.history)}
                className={classes.planModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
              <PickPlans
                onSuccessRefresh={() => refreshPage()}
                onClose={() => cancelSub(rest.history)} type={rest.type} inactive={!active && (rest.type == 'RESEARCH' ? has_research : has_rtm )}/>
          </Modal>
        </div>
      )
    }
};



function cancelSub(history) {
  history.push("/")
}

function allowedUserChecker(roles, permissions, allowedRoles) {
  if(!allowedRoles || allowedRoles.length==0) {
    return true
  } else if (!roles || roles==[]) {
    return false
  }
  const filteredArray = roles.filter(value => allowedRoles.includes(value));
  return (filteredArray.length > 0)
}

SubscriptionMW.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element
};

export default SubscriptionMW;
